import React, { Fragment, useEffect, useState } from "react";

import { axiosAdaptor } from "../../../utils/axios-adaptor.util";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../../store/theme-app.store";

import toast from "react-hot-toast";

import loadingCardLength from "../../components/loading/loadingCardLength";
import { LandscapeLoadingCard } from "../../components/loading/LoadingCard";

import EmptyIcon from "../../components/icons/EmptyIcon";

import Header from "../../components/Header";

const CustomerServicePage: React.FC = (props): JSX.Element => {
	interface CustomerSerciveListSchemaType {
		contact: string;
		icon: string;
		kode_merchant: string;
		link: string;
		title: string;
		_id: string;
	}

	const customerServiceListSchema = [
		{
			contact: "",
			icon: "",
			kode_merchant: "",
			link: "",
			title: "",
			_id: "",
		},
	];

	const [customerServiceListState, setCustomerServiceListState] = useState<Array<CustomerSerciveListSchemaType>>(customerServiceListSchema);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

	function getCustomerServiceList(setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			setLoading(true);
			axiosAdaptor({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/cs/list`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then(async (result: any) => {
				// console.log('[CUSTOMER SERVICE LIST]', result)

				// const cacheStore = await cache.store

				// console.log('Cache store:', cacheStore)

				if (result.status === 200) {
					setLoading(false);
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		getCustomerServiceList(setIsLoadingState).then((result: any): void => {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) setCustomerServiceListState(data);
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	}, []);

	const themeState = useRecoilValue<string | null>(themeAppStore);

	const customerServiceListTheme: string = themeState === "dark" ? "bg-dark-menu lg:text-gray-200" : "bg-white text-gray-800";

	return (
		<div>
			<Header title="Pusat Bantuan" />

			{isLoadingState ? (
				<div className="flex flex-col gap-4">
					{loadingCardLength.map((loading, index) => (
						<Fragment key={index}>
							<LandscapeLoadingCard line={3} />
						</Fragment>
					))}
				</div>
			) : (
				<Fragment>
					{/* CS EMPTY LIST  */}
					{!customerServiceListState.length ? (
						<div className="mt-20 flex flex-col justify-center items-center gap-4">
							<EmptyIcon title="Bantuan" />
						</div>
					) : (
						<Fragment>
							{/* CS LIST */}
							<a
								href={process.env.REACT_APP_CS_LIVE_CHAT}
								target="_blank"
								rel="noreferrer"
								className={`${customerServiceListTheme} flex items-center px-5 py-3 lg:py-4 lg:px-6 mb-6 rounded-md lg:rounded-lg shadow hover:shadow-xl duration-500 lg:ease-out lg:gap-4 cursor-pointer lg:transition noselect`}>
								<div className="w-full flex items-center gap-3 lg:gap-4">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										className="w-9 h-9 sm:w-14 sm:h-14 rounded-sm"
										fill={process.env.REACT_APP_PRIMARY_COLOR}>
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M19.938 8H21a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-1.062A8.001 8.001 0 0 1 12 23v-2a6 6 0 0 0 6-6V9A6 6 0 1 0 6 9v7H3a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h1.062a8.001 8.001 0 0 1 15.876 0zM3 10v4h1v-4H3zm17 0v4h1v-4h-1zM7.76 15.785l1.06-1.696A5.972 5.972 0 0 0 12 15a5.972 5.972 0 0 0 3.18-.911l1.06 1.696A7.963 7.963 0 0 1 12 17a7.963 7.963 0 0 1-4.24-1.215z" />
									</svg>
									<div className="w-full flex justify-between">
										{/* CS NAME && DESCRIPTION */}
										<div className="flex flex-col w-44 sm:w-auto lg:w-80 xl:w-auto">
											<span className="mb-1 text-xs sm:text-lg font-medium tracking-wide">Live Chat</span>
											<span className="text-xs sm:text-base tracking-wide">Live Chat dengan Customer Service kami</span>
										</div>
									</div>
								</div>
							</a>

							{customerServiceListState.map(({ contact, icon, link, title, _id: id }, index): JSX.Element => (
								<Fragment key={index}>
									<a
										href={link}
										key={id}
										target="_blank"
										rel="noreferrer"
										className={`${customerServiceListTheme} flex items-center px-5 py-3 lg:py-4 lg:px-6 mb-6 rounded-md lg:rounded-lg shadow hover:shadow-xl duration-500 lg:ease-out lg:gap-4 cursor-pointer lg:transition noselect`}>
										<div className="w-full flex items-center gap-3 lg:gap-4">
											{/* CS LIST ICON */}
											{icon ? (
												<img src={icon} className="w-9 h-9 sm:w-14 sm:h-14 rounded-sm" alt="icon" />
											) : (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													className="w-9 h-9 sm:w-14 sm:h-14 rounded-sm"
													fill={process.env.REACT_APP_PRIMARY_COLOR}>
													<path fill="none" d="M0 0h24v24H0z" />
													<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm2-1.645A3.502 3.502 0 0 0 12 6.5a3.501 3.501 0 0 0-3.433 2.813l1.962.393A1.5 1.5 0 1 1 12 11.5a1 1 0 0 0-1 1V14h2v-.645z" />
												</svg>
											)}
											<div className="w-full flex justify-between">
												{/* CS NAME && DESCRIPTION */}
												<div className="flex flex-col w-44 sm:w-auto lg:w-80 xl:w-auto">
													<span className="mb-1 text-xs sm:text-lg font-medium tracking-wide truncate">{contact}</span>
													<span className="text-xs sm:text-base tracking-wide">{title}</span>
												</div>
											</div>
										</div>
									</a>
								</Fragment>
							)
							)}
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default CustomerServicePage;
