import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";

import { useRecoilValue } from "recoil";
import favoriteNumberStore from "../../../../store/favorite-number.store";

import { withRouter } from "react-router-dom";

import toast from "react-hot-toast";

import NumberFormat from "react-number-format";

import loadingCardLength from "../../../components/loading/loadingCardLength";
import { LandscapeLoadingCard } from "../../../components/loading/LoadingCard";

import Header from "../../../components/Header";
import ContentList from "../../../components/ContentList";

import DestinationInput from "../../../components/DestinationInput";

import InquiryPrepaidDetails from "./InquiryPrepaidDetails";

import PinTrxModalValidation from "../../profile/PinTrxModalValidation";

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);


const PrepaidDenomPage: React.FC = (props: any): JSX.Element => {
	if (!props.location.state) window.location.replace("/dashboard");

	const { name, categoryId, productType, inputMenu } = props.location.state;

	const menuIcon: string | null = localStorage.getItem("menu_icon");

	const favoriteNumberState = useRecoilValue(favoriteNumberStore);

	const [destinationState, setDestinationState] = useState<number>(0);

	interface PrepaidDenomMenuListSchemaType {
		admin: number;
		bebas_nominal: boolean;
		description: string;
		harga_jual: number;
		harga_promo: number;
		harga_tetap: boolean;
		kategori_id?: string;
		kode_produk: string;
		nama: string;
		poin: number;
		_id: string;
	}

	const prepaidDenomMenuListSchema = [
		{
			admin: 0,
			bebas_nominal: false,
			description: "",
			harga_jual: 0,
			harga_promo: 0,
			harga_tetap: false,
			kategori_id: "",
			kode_produk: "",
			nama: "",
			poin: 0,
			_id: "",
		},
	];

	const [prepaidDenomMenuListState, setPrepaidDenomMenuListState] = useState<Array<PrepaidDenomMenuListSchemaType>>(prepaidDenomMenuListSchema);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

	function setDestinationByFavoriteNumber() {
		if (favoriteNumberState) setDestinationState(favoriteNumberState);
	}

	async function getPrepaidDenomMenuList(categoryId: string, setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			setLoading(true);
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/product/${categoryId}`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log("[PRODUCT BY CATEGORY]", result);

				if (result.status === 200) {
					setLoading(false);
					return resolve(result);
				} else {
					return reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		setDestinationByFavoriteNumber();

		getPrepaidDenomMenuList(categoryId, setIsLoadingState).then((result: any): void => {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) setPrepaidDenomMenuListState(data);
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	}, [categoryId, favoriteNumberState]);

	const ScrollToTopOnMount = (): null => {
		useEffect((): void => {
			window.scrollTo(0, 0);
		}, []);

		return null;
	};

	return (
		<div>
			<Header title={`Topup ${name}`} />

			<ScrollToTopOnMount />

			<div className="mb-12">
				<DestinationInput type={`${inputMenu}`} onChange={(e) => setDestinationState(e.target.value)} value={destinationState} productType={productType} />
			</div>

			{isLoadingState ? (
				<div className="flex flex-col gap-4">
					{loadingCardLength.map((loading, index) => (
						<Fragment key={index}>
							<LandscapeLoadingCard line={3} />
						</Fragment>
					))}
				</div>
			) : (
				prepaidDenomMenuListState.map(
					({
						bebas_nominal: nominalFree,
						description,
						harga_jual: sellingPrice,
						harga_promo: promoPrice,
						kode_produk: productCode,
						nama: name,
					}: {
						bebas_nominal: boolean;
						description: string;
						harga_jual: number;
						harga_promo: number;
						kode_produk: string;
						nama: string;
						_id: string;
					}, index): JSX.Element => (
						<Fragment key={index}>
							<ContentList onClick={async () => {
								if (destinationState.toString().length < 1) {
									return toast.error("Nomor Tujuan Tidak Boleh Kosong!", {
										duration: 4000,
										style: {
											fontWeight: "bolder",
										},
									});
								}

								if (destinationState.toString().length < 4) {
									return toast.error("Nomor Tujuan Minimal 4 digit", {
										duration: 4000,
										style: {
											fontWeight: "bolder",
										},
									});
								}

								if (nominalFree) {
									const nominalInputModal = async (): Promise<void> => {
										const { value: swalNominalFree } = await Swal.fire({
											title: "Masukan Nominal",
											padding: "1rem 0rem",
											heightAuto: false,
											allowOutsideClick: false,
											confirmButtonText: "Lanjutkan",
											showCancelButton: true,
											cancelButtonText: "Batal",
											customClass: {
												title: "font-inter font-semibold text-lg sm:text-2xl text-gray-800",
												confirmButton: "text-sm sm:text-base font-inter",
												cancelButton: "text-sm sm:text-base font-inter",
											},
											html: <div>
												<span className="text-sm sm:text-base font-medium font-inter">Silahkan Masukan Nominal Terlebih Dahulu.</span>
												<input
													type="number"
													onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
														if (!/[0-9]/.test(e.key)) e.preventDefault();
													}}
													id="swal-input1"
													className="swal2-input text-base sm:text-lg font-medium font-inter text-center"
													pattern="[0-9]*"
													autoFocus
												/>
											</div>,
											focusConfirm: true,
											preConfirm: (): Array<string> => [
												(document.getElementById("swal-input1") as HTMLInputElement).value,
											],
										});

										if (swalNominalFree) {
											const swalNominalFreeValue = swalNominalFree[0];
											if (!swalNominalFreeValue) {
												toast.error("Maaf Anda Harus Memasukan Nominal", {
													style: { fontWeight: "bolder", },
												});

												nominalInputModal();
											} else {
												await Swal.fire({
													title: "Konfirmasi Pembelian",
													padding: "1rem 0rem",
													showConfirmButton: false,
													focusConfirm: true,
													heightAuto: false,
													allowOutsideClick: false,
													customClass: {
														title: "font-inter font-semibold text-lg sm:text-2xl text-gray-800",
													},
													html: (
														<InquiryPrepaidDetails
															nominal={parseInt(swalNominalFreeValue)}
															purchaseConfirm={(counter: number, nominal: number = 0): any => PinTrxModalValidation("prepaid", "", productCode, destinationState, counter, nominal)}
															productCode={productCode}
															productType={productType}
															sellingPrice={sellingPrice}
															destination={destinationState}
														/>
													),
												});
											}
										}
									};

									nominalInputModal();
								} else {
									await Swal.fire({
										title: "Konfirmasi Pembelian",
										padding: "1rem 0rem",
										showConfirmButton: false,
										focusConfirm: true,
										heightAuto: false,
										allowOutsideClick: false,
										customClass: {
											title: "font-inter font-semibold text-lg sm:text-2xl text-gray-800",
										},
										html: (
											<InquiryPrepaidDetails
												purchaseConfirm={(counter: number, nominal: number = 0): any => PinTrxModalValidation("prepaid", "", productCode, destinationState, counter, nominal)}
												productCode={productCode}
												productType={productType}
												destination={destinationState}
												sellingPrice={sellingPrice}
											/>
										),
									});
								}
							}}
								line={3}
								icon={<img className="w-9 h-9 sm:w-16 sm:h-16 rounded-sm" src={`${menuIcon}`} alt="icon" />}
								firstLine={<span className="mb-1 text-sm sm:text-base font-medium tracking-wide">{name}</span>}
								secondLine={<span className="text-xs sm:text-sm font-base tracking-wide">{description}</span>}
								thirdLine={
									<Fragment>
										{promoPrice ? (
											<Fragment>
												<div>
													<NumberFormat
														className="text-red-500 text-xs sm:text-base font-medium tracking-wide line-through mb-2"
														value={sellingPrice}
														displayType={"text"}
														thousandSeparator={"."}
														decimalSeparator={","}
														prefix={"Rp. "}
													/>
												</div>
												<div>
													<NumberFormat
														className="text-xs sm:text-base font-medium tracking-wide"
														value={promoPrice}
														displayType={"text"}
														thousandSeparator={"."}
														decimalSeparator={","}
														prefix={"Rp. "}
													/>
												</div>
											</Fragment>
										) : (
											<NumberFormat
												className="text-xs sm:text-base font-medium tracking-wide"
												value={sellingPrice}
												displayType={"text"}
												thousandSeparator={"."}
												decimalSeparator={","}
												prefix={"Rp. "}
											/>
										)}
									</Fragment>
								}
							/>
						</Fragment>
					)
				)
			)}
		</div>
	);
};

export default withRouter(PrepaidDenomPage);
