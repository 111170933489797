import React, { Fragment, useCallback, useEffect, useState } from "react";

import axios from "axios";

import moment from "moment";

import NumberFormat from "react-number-format";

import toast from "react-hot-toast";

import Header from "../../../components/Header";

import ContentList from "../../../components/ContentList";

import EmptyIcon from "../../../components/icons/EmptyIcon";

import loadingCardLength from "../../../components/loading/loadingCardLength";
import { LandscapeLoadingCard } from "../../../components/loading/LoadingCard";

import MoreDataList from "../../../components/MoreDataList";

import { useHistory } from "react-router-dom";

const idLocale = require('moment/locale/id')
moment.updateLocale('id', idLocale);

const TrxListPage: React.FC = (props): JSX.Element => {
	interface TrxListSchemaType {
		admin: number;
		counter: number;
		created_at: string;
		fee_ppob: number;
		harga_jual: number;
		outbox_id?: string;
		payment_by: string;
		poin: number;
		produk_id?: {
			description: string;
			kode_produk: string;
			nama: string;
			_id: string;
		};
		status: number;
		tujuan: string;
		updated_at: string;
		user_id?: string;
		_id: string;
	}

	const trxListSchema = [
		{
			admin: 0,
			counter: 0,
			created_at: "",
			fee_ppob: 0,
			harga_jual: 0,
			outbox_id: "",
			payment_by: "",
			poin: 0,
			produk_id: {
				description: "",
				kode_produk: "",
				nama: "",
				_id: "",
			},
			status: 0,
			tujuan: "",
			updated_at: "",
			user_id: "",
			_id: "",
		},
	]

	const [trxListState, setTrxListState] = useState<Array<TrxListSchemaType>>(trxListSchema);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
	const [paginationLoadingState, setPaginationLoadingState] = useState<boolean>(false);
	const [isMaxPaginationState, setIsMaxPaginationState] = useState<boolean>(false);

	let [pageState, setPageState] = useState<number>(0);

	function getTrxList(trxPage: number, setLoading: React.Dispatch<React.SetStateAction<boolean>>, setPaginationLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		return new Promise((resolve, reject) => {
			if (trxPage <= 0) setLoading(true);

			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/trx/list?page=${trxPage}`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log(`[TRX LIST PAGE ${trxPage}]`, result);

				if (result.status === 200) {
					setLoading(false);
					setPaginationLoading(false);
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	const getTrxListCallback = useCallback((trxPage: number, setLoading: React.Dispatch<React.SetStateAction<boolean>>, setPaginationLoading: React.Dispatch<React.SetStateAction<boolean>>): void => {
		getTrxList(trxPage, setLoading, setPaginationLoading).then((result: any): void => {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) {
				if (pageState > 0) {
					if (!data.length) {
						setIsMaxPaginationState(true);
						toast.error(`Data telah mencapai batas maximum`, {
							style: {
								fontWeight: "bolder",
							},
						});
					}
					setTrxListState([...trxListState, ...data]);
					return;
				}

				setTrxListState(data);
			}
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	},
		[pageState]
	);

	useEffect((): void => {
		getTrxListCallback(pageState, setIsLoadingState, setPaginationLoadingState);
	}, [getTrxListCallback, pageState]);

	const handleRefresh = (): void => {
		setPageState(0);
		getTrxListCallback(pageState, setIsLoadingState, setPaginationLoadingState);
	};

	const history = useHistory();
	function handleClickTrxShowDetails(id: string, sellingPrice: number, admin: number) {
		history.push({
			pathname: '/dashboard/profile/history/trx/show',
			state: { refId: id, sellingPrice, admin }
		});
	}

	return (
		<div>
			<Header title="Riwayat Transaksi" btnRefresh handleRefresh={handleRefresh} />

			{isLoadingState ? (
				<div className="flex flex-col gap-4">
					{loadingCardLength.slice(0, 10).map((loading, index) => (
						<Fragment key={index}>
							<LandscapeLoadingCard line={4} />
						</Fragment>
					))}
				</div>
			) : (
				<Fragment>
					{!trxListState.length ? (
						<div className="mt-24 lg:mt-20 2xl:mt-40">
							<EmptyIcon title="Anda Belum Memiliki Riwayat Transaksi" />
						</div>
					) : (
						trxListState.map(({
							admin,
							harga_jual: sellingPrice,
							produk_id,
							status,
							tujuan: destination,
							_id: id,
						}, index): JSX.Element => (
							<Fragment key={index}>
								<label htmlFor="trx-modal-details">

									<ContentList
										onClick={(): void => handleClickTrxShowDetails(id, sellingPrice, admin)}
										line={4}
										icon={
											<Fragment>
												{/**
												 * TYPE 2 === SUCCESS
												 * TYPE 3 === FAILED
												 * TYPE 4 === PENDING
												 */}
												<div className="lg:w-16">
													{status === 2 ? (
														<svg
															className="w-9 h-9 sm:w-16 sm:h-16 rounded-sm"
															fill="#2DB981"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="40"
															height="40">
															<path fill="none" d="M0 0h24v24H0z" />
															<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
														</svg>
													) : status === 3 ? (
														<svg
															className="w-9 h-9 sm:w-16 sm:h-16 rounded-sm"
															fill="#EF4449"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="40"
															height="40">
															<path fill="none" d="M0 0h24v24H0z" />
															<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z" />
														</svg>
													) : (
														<svg
															className="w-9 h-9 sm:w-16 sm:h-16 rounded-sm"
															fill="#F9B012"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="40"
															height="40">
															<path fill="none" d="M0 0h24v24H0z" />
															<path d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm10-5a1 1 0 0 1-1 1h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zM7 12a1 1 0 0 1-1 1H3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zm12.071 7.071a1 1 0 0 1-1.414 0l-2.121-2.121a1 1 0 0 1 1.414-1.414l2.121 2.12a1 1 0 0 1 0 1.415zM8.464 8.464a1 1 0 0 1-1.414 0L4.93 6.344a1 1 0 0 1 1.414-1.415L8.464 7.05a1 1 0 0 1 0 1.414zM4.93 19.071a1 1 0 0 1 0-1.414l2.121-2.121a1 1 0 1 1 1.414 1.414l-2.12 2.121a1 1 0 0 1-1.415 0zM15.536 8.464a1 1 0 0 1 0-1.414l2.12-2.121a1 1 0 0 1 1.415 1.414L16.95 8.464a1 1 0 0 1-1.414 0z" />
														</svg>
													)}
												</div>
											</Fragment>
										}
										firstLine={<span className="mb-1 text-sm sm:text-base font-medium tracking-wide">{destination}</span>}
										secondLine={<span className="text-xs sm:text-sm font-base tracking-wide">{produk_id?.nama ? produk_id?.nama : '-'}</span>}
										thirdLine={
											<Fragment>
												<NumberFormat
													value={sellingPrice}
													className="mb-1 text-xs lg:text-base font-medium tracking-wide"
													displayType={"text"}
													thousandSeparator={"."}
													decimalSeparator={","}
													prefix={"Rp "}
												/>
											</Fragment>
										}
										fourthLine={
											<Fragment>
												{/**
												 * TYPE 2 === SUCCESS
												 * TYPE 3 === FAILED
												 * TYPE 4 === PENDING
												 */}
												{status === 2 ? (
													<span className="mb-1 text-xs sm:text-base text-green-500 font-medium tracking-wide">Sukses</span>
												) : status === 3 ? (
													<span className="mb-1 text-xs sm:text-base text-red-500 font-medium tracking-wide">Gagal</span>
												) : (
													<span className="mb-1 text-xs sm:text-base text-yellow-500 font-medium tracking-wide">Dalam Proses</span>
												)}
											</Fragment>
										}
									/>
								</label>
							</Fragment>
						))
					)}
				</Fragment>
			)}

			{paginationLoadingState ? (
				<div className="flex justify-center items-center">
					<span className="text-xs sm:text-base font-medium tracking-wide">Loading...</span>
				</div>
			) : (
				trxListState.length >= 10 &&
				!isLoadingState && (
					<Fragment>
						{!isMaxPaginationState && <MoreDataList setPage={setPageState} setPaginationLoading={setPaginationLoadingState} />}
					</Fragment>
				)
			)}
		</div>
	);
};

export default TrxListPage;
