import React, { Fragment } from "react";

interface LandscapeLoadingCardProps {
	line?: number | string;
	optional?: boolean;
}

const LandscapeLoadingCard: React.FC<LandscapeLoadingCardProps> = ({
	line,
	optional,
	children,
}): JSX.Element => {
	return (
		<div
			className="animate-pulse bg-gray-300 flex justify-between items-center mb-2 lg:mb-2 px-3.5 sm:px-4 lg:px-5 py-3.5 lg:py-4 lg:gap-0 duration-500 lg:ease-out rounded-md lg:rounded-lg">
			<div className="w-full flex items-center gap-3.5 lg:gap-4">
				{line === 2 ? (
					<Fragment>
						<div className="w-11 h-10 sm:w-[4.4rem] sm:h-16 lg:w-20 lg:h-16 sm:mr-0.5 lg:mr-0.5 animate-pulse bg-gray-400 rounded-md lg:rounded-lg" />
						<div className="w-full flex justify-between lg:justify-start xl:justify-between lg:flex-col xl:flex-row items-center lg:items-start xl:items-center">
							<div className="lg:mb-5 lg:mt-1 xl:mt-0 xl:mb-0">
								<div className="mb-3 sm:mb-5">
									<p className="h-2.5 sm:h-3.5 w-24 sm:w-56 lg:w-64 animate-pulse bg-gray-400 rounded-md"></p>
								</div>
								<div>
									<p className="h-2.5 sm:h-3.5 w-32 sm:w-64 lg:w-72 animate-pulse bg-gray-400 rounded-md"></p>
								</div>
							</div>
						</div>
					</Fragment>
				) : line === 3 ? (
					<Fragment>
						<div className="w-11 h-10 sm:w-[4.4rem] sm:h-16 lg:w-20 lg:h-16 sm:mr-0.5 lg:mr-0.5 animate-pulse bg-gray-400 rounded-md lg:rounded-lg" />
						<div className="w-full flex justify-between lg:justify-start xl:justify-between lg:flex-col xl:flex-row items-center lg:items-start xl:items-center">
							<div className="lg:mb-5 lg:mt-1 xl:mt-0 xl:mb-0">
								<div className="mb-3 sm:mb-5">
									<p className="h-2.5 sm:h-3.5 w-24 sm:w-56 lg:w-64 animate-pulse bg-gray-400 rounded-md"></p>
								</div>
								<div>
									<p className="h-2.5 sm:h-3.5 w-32 sm:w-64 lg:w-72 animate-pulse bg-gray-400 rounded-md"></p>
								</div>
							</div>
							<div className="mr-1 sm:mr-2 lg:mr-3 space-y-3 sm:space-y-4 md:space-y-3">
								<p className="h-2.5 sm:h-3.5 w-12 sm:w-20 animate-pulse bg-gray-400 rounded-md"></p>
							</div>
						</div>
					</Fragment>
				) : line === 4 ? (
					<Fragment>
						<div className="w-11 h-10 sm:w-[4.4rem] sm:h-16 lg:w-20 lg:h-16 sm:mr-0.5 lg:mr-0.5 animate-pulse bg-gray-400 rounded-md lg:rounded-lg" />
						<div className="w-full flex justify-between lg:justify-start xl:justify-between lg:flex-col xl:flex-row items-center lg:items-start xl:items-center">
							<div className="lg:mb-5 lg:mt-1 xl:mt-0 xl:mb-0">
								<div className="mb-3 sm:mb-5">
									<p className="h-2.5 sm:h-3.5 w-24 sm:w-56 lg:w-64 animate-pulse bg-gray-400 rounded-md"></p>
								</div>
								<div>
									<p className="h-2.5 sm:h-3.5 w-32 sm:w-64 lg:w-72 animate-pulse bg-gray-400 rounded-md"></p>
								</div>
							</div>
							<div className="mr-1 sm:mr-2 lg:mr-3 space-y-3 sm:space-y-4 md:space-y-3">
								<p className="h-2.5 sm:h-3.5 w-12 sm:w-20 animate-pulse bg-gray-400 rounded-md"></p>
								<p className="h-2.5 sm:h-3.5 w-12 sm:w-20 animate-pulse bg-gray-400 rounded-md"></p>
							</div>
						</div>
					</Fragment>
				) : optional ? (
					<Fragment>{children}</Fragment>
				) : (
					<div className="w-10 h-9 lg:w-20 lg:h-16" />
				)}
			</div>
		</div>
	);
};

interface BoxLoadingCardProps {
	heightTw?: string;
}

const BoxLoadingCard: React.FC<BoxLoadingCardProps> = ({ heightTw }) => {
	return (
		<div className={`${heightTw ? heightTw : "h-36 sm:h-48 lg:h-36 2xl:h-48"} animate-pulse bg-gray-400 p-3 mb-2 lg:mb-0 lg:gap-4 cursor-pointer lg:transition duration-500 lg:ease-out rounded-lg noselect`}></div>
	);
};

export { LandscapeLoadingCard, BoxLoadingCard };
