import React from "react";

interface MoreDataListProps {
	setPage: React.Dispatch<React.SetStateAction<number>>;
	setPaginationLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const MoreDataList: React.FC<MoreDataListProps> = ({
	setPage,
	setPaginationLoading,
}): JSX.Element => {

	return (
		<div className="flex justify-center items-center gap-2 mt-8 lg:mt-12 mb-8 lg:mb-6">
			<button
				onClick={(): void => {
					setPage((c) => c + 1);
					setPaginationLoading(true);
				}}
				className="flex justify-center items-center px-4 py-2 rounded-md focus:outline-none"
				style={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}>
				<span className="text-xs sm:text-lg text-white font-medium tracking-wide">Lihat Lainnya</span>
			</button>
		</div>
	);
};

export default MoreDataList;
