import { Fragment, useEffect, useState } from "react";

import moment from "moment";
import NumberFormat from "react-number-format";

const idLocale = require('moment/locale/id')
moment.updateLocale('id', idLocale);

interface trxPrintDataType {
  merchantName: string;
  productCode: string;
  productName: string;
  destination: string;
  sellingPrice: number;
  sellingAdmin: number;
  print: Array<{
    label: string;
    value: string;
  }>;
  feePpob: number;
  token: string;
  sn: string;
  createdAt: string;
}

interface PlnContentPropsType {
  printData: trxPrintDataType;
  printSize: string;
}

const PlnContent: React.FC<PlnContentPropsType> = ({
  printData,
  printSize,
}): JSX.Element => {
  const {
    destination,
    sellingPrice,
    sellingAdmin,
    print,
    sn,
    createdAt,
  } = printData;

  let printSetup: any = {
    "A4": {
      printTextSize: 'text-xs',
      printWhiteSpace: 'w-36 pr-4',
    },
    "80mm": {
      printTextSize: 'text-xs',
      printWhiteSpace: 'w-32 pr-4',
      printMargin: 'mx-1.5 -mt-1.5',
    },
    "58mm": {
      printTextSize: 'text-2xs',
      printWhiteSpace: 'w-28 pr-4',
      printMargin: 'mx-1.5 -mt-1.5',
      printMarginCustomeStruct: 'mx-1.5 mt-0.5'
    },
  }

  let plnPrint: any = {}

  if (print.length) {
    print.forEach(({ label, value }) => {
      plnPrint[label] = value;
    })
  }

  const { Daya: daya, Jenis: type, Nama: name, Periode: periode, Ref: ref, StanAwal: firstStan, StandAkhir: lastStan, Tagihan: invoice } = plnPrint;

  return (
    <div className="flex justify-center">
      <div>
        <div className="flex gap-16 mb-1 px-2">
          <div>
            {/* CUST NAME */}
            <div className={`${printSetup[printSize] ? printSetup[printSize].printMargin : printSetup[printSize].printMarginCustomeStruct} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>NAMA</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1 leading-3">
                <span className={`${printSetup[printSize].printTextSize}`}>{name}</span>
              </div>
            </div>

            {/* CUST ID */}
            <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>ID PELANGGAN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <span className={`${printSetup[printSize].printTextSize}`}>{destination}</span>
              </div>
            </div>

            {/* INVOICE */}
            <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>TAGIHAN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={invoice}
                  className={`${`${printSetup[printSize].printTextSize}`}`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>

            {/* DAYA */}
            <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>DAYA</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <span className={`${printSetup[printSize].printTextSize}`}>{daya}</span>
              </div>
            </div>

            {/* TYPE */}
            <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>JENIS</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <span className={`${printSetup[printSize].printTextSize}`}>{type}</span>
              </div>
            </div>

            {printSize === '58mm' || printSize === '80mm' ? (
              <Fragment>
                {/* WHEN THERE IS A PRINT FIELD THEN SHOW THE LABEL */}
                {print.length ? (
                  <Fragment>
                    {/* FIRST STAN */}
                    <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                      <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>STANAWAL</span>
                        </div>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <span className={`${printSetup[printSize].printTextSize}`}>{firstStan}</span>
                      </div>
                    </div>

                    {/* LAST STAN */}
                    <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                      <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>STANAKHIR</span>
                        </div>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <span className={`${printSetup[printSize].printTextSize}`}>{lastStan}</span>
                      </div>
                    </div>

                    {/* PERIODE */}
                    <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                      <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>PERIODE</span>
                        </div>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <span className={`${printSetup[printSize].printTextSize}`}>{periode}</span>
                      </div>
                    </div>

                    {/* REF */}
                    <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                      <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>REF</span>
                        </div>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <span className={`${printSetup[printSize].printTextSize}`}>{ref}</span>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                    <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                      <div>
                        <span className={`${printSetup[printSize].printTextSize}`}>SN</span>
                      </div>
                      <div>
                        <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                      </div>
                    </div>
                    <div className="flex-1">
                      <span className={`${printSetup[printSize].printTextSize}`}>{sn}</span>
                    </div>
                  </div>
                )}
              </Fragment>
            ) : ''}
          </div>

          {printSize === 'A4' && (
            <div>
              {/* DATA TIME */}
              <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>TANGGAL</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <span className={`${printSetup[printSize].printTextSize}`}>{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")}</span>
                </div>
              </div>

              {/* FIRST STAN */}
              <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>STANAWAL</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <span className={`${printSetup[printSize].printTextSize}`}>{firstStan}</span>
                </div>
              </div>

              {/* LAST STAN */}
              <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>STANAKHIR</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <span className={`${printSetup[printSize].printTextSize}`}>{lastStan}</span>
                </div>
              </div>

              {/* PERIODE */}
              <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>PERIODE</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <span className={`${printSetup[printSize].printTextSize}`}>{periode}</span>
                </div>
              </div>

              {/* REF */}
              <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>REF</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <span className={`${printSetup[printSize].printTextSize}`}>{ref}</span>
                </div>
              </div>
            </div>
          )}
        </div>

        {printSize === '58mm' || printSize === '80mm' ? (
          <Fragment>
            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>ADMIN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={sellingAdmin}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>

            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left mb-1 px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>TOTAL</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={sellingPrice + sellingAdmin}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>
            <div className="px-2 mt-2.5 leading-3">
              <span className={`${`${printSetup[printSize].printTextSize}`} font-bold`}>STRUK INI ADALAH BUKTI PEMBAYARAN YANG SAH</span>
            </div>
            <div className="px-2 mt-1 leading-3">
              <span className={`${printSetup[printSize].printTextSize}`}>Tersedia juga Pulsa, Game, Produk Pascabayar dan Produk Lainnya</span>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>ADMIN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={sellingAdmin}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>
            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left mb-1 px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>TOTAL</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={parseInt(invoice.replace(new RegExp('RP', 'gi'), '')) + sellingAdmin}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>
            <div className="px-2 mt-5 leading-3">
              <span className={`${`${printSetup[printSize].printTextSize}`} font-bold`}>STRUK INI ADALAH BUKTI PEMBAYARAN YANG SAH</span>
            </div>
            <div className="px-2 mt-2 leading-3">
              <span className={`${printSetup[printSize].printTextSize}`}>Tersedia juga Pulsa, Game, Produk Pascabayar dan Produk Lainnya</span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

interface SpeedyContentPropsType {
  printData: trxPrintDataType;
  printSize: string;
}

const SpeedyContent: React.FC<SpeedyContentPropsType> = ({
  printData,
  printSize,
}): JSX.Element => {
  const {
    destination,
    sellingPrice,
    sellingAdmin,
    print,
    sn,
    createdAt,
  } = printData;

  let printSetup: any = {
    "A4": {
      printTextSize: 'text-xs',
      printWhiteSpace: 'w-36 pr-4',
    },
    "80mm": {
      printTextSize: 'text-xs',
      printWhiteSpace: 'w-32 pr-4',
      printMargin: 'mx-1.5 -mt-1.5',
    },
    "58mm": {
      printTextSize: 'text-2xs',
      printWhiteSpace: 'w-28 pr-4',
      printMargin: 'mx-1.5 -mt-1.5',
      printMarginCustomeStruct: 'mx-1.5 mt-0.5'
    },
  }

  let speedyPrint: any = {}

  if (print.length) {
    print.forEach(({ label, value }) => {
      speedyPrint[label] = value;
    })
  }

  const { Nama: name, Periode: periode, Ref: ref, Tagihan: invoice } = speedyPrint;

  return (
    <div className="flex justify-center">
      <div>
        <div className="flex gap-16 mb-1 px-2">
          <div>
            {/* CUST NAME */}
            <div className={`${printSetup[printSize] ? printSetup[printSize].printMargin : printSetup[printSize].printMarginCustomeStruct} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>NAMA</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1 leading-3">
                <span className={`${printSetup[printSize].printTextSize}`}>{name}</span>
              </div>
            </div>

            {/* CUST ID */}
            <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>ID PELANGGAN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <span className={`${printSetup[printSize].printTextSize}`}>{destination}</span>
              </div>
            </div>

            {/* INVOICE */}
            <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>TAGIHAN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={invoice}
                  className={`${`${printSetup[printSize].printTextSize}`}`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>

            {printSize === '58mm' || printSize === '80mm' ? (
              <Fragment>
                {/* WHEN THERE IS A PRINT FIELD THEN SHOW THE LABEL */}
                {print.length ? (
                  <Fragment>
                    {/* PERIODE */}
                    <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                      <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>PERIODE</span>
                        </div>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <span className={`${printSetup[printSize].printTextSize}`}>{periode}</span>
                      </div>
                    </div>

                    {/* REF */}
                    <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                      <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>REF</span>
                        </div>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <span className={`${printSetup[printSize].printTextSize}`}>{ref}</span>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                    <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                      <div>
                        <span className={`${printSetup[printSize].printTextSize}`}>SN</span>
                      </div>
                      <div>
                        <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                      </div>
                    </div>
                    <div className="flex-1">
                      <span className={`${printSetup[printSize].printTextSize}`}>{sn}</span>
                    </div>
                  </div>
                )}
              </Fragment>
            ) : ''}
          </div>

          {printSize === 'A4' && (
            <div>
              {/* DATA TIME */}
              <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>TANGGAL</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <span className={`${printSetup[printSize].printTextSize}`}>{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")}</span>
                </div>
              </div>

              {/* PERIODE */}
              <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>PERIODE</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <span className={`${printSetup[printSize].printTextSize}`}>{periode.replace(new RegExp('PERIODE:', 'gi'), '')}</span>
                </div>
              </div>

              {/* REF */}
              <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>REF</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <span className={`${printSetup[printSize].printTextSize}`}>{ref}</span>
                </div>
              </div>
            </div>
          )}
        </div>

        {printSize === '58mm' || printSize === '80mm' ? (
          <Fragment>
            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>ADMIN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={sellingAdmin}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>

            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left mb-1 px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>TOTAL</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={sellingPrice + sellingAdmin}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>
            <div className="px-2 mt-2.5 leading-3">
              <span className={`${`${printSetup[printSize].printTextSize}`} font-bold`}>STRUK INI ADALAH BUKTI PEMBAYARAN YANG SAH</span>
            </div>
            <div className="px-2 mt-1 leading-3">
              <span className={`${printSetup[printSize].printTextSize}`}>Tersedia juga Pulsa, Game, Produk Pascabayar dan Produk Lainnya</span>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>ADMIN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={sellingAdmin}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>
            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left mb-1 px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>TOTAL</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  // value={parseInt(invoice.replace(new RegExp('RP', 'gi'), '')) + sellingAdmin}
                  value={0}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>
            <div className="px-2 mt-5 leading-3">
              <span className={`${`${printSetup[printSize].printTextSize}`} font-bold`}>STRUK INI ADALAH BUKTI PEMBAYARAN YANG SAH</span>
            </div>
            <div className="px-2 mt-2 leading-3">
              <span className={`${printSetup[printSize].printTextSize}`}>Tersedia juga Pulsa, Game, Produk Pascabayar dan Produk Lainnya</span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

interface BpjsContentPropsType {
  printData: trxPrintDataType;
  printSize: string;
}

const BpjsContent: React.FC<BpjsContentPropsType> = ({
  printData,
  printSize,
}): JSX.Element => {
  const {
    destination,
    sellingPrice,
    sellingAdmin,
    print,
    sn,
    createdAt,
  } = printData;

  let printSetup: any = {
    "A4": {
      printTextSize: 'text-xs',
      printWhiteSpace: 'w-36 pr-4',
    },
    "80mm": {
      printTextSize: 'text-xs',
      printWhiteSpace: 'w-32 pr-4',
      printMargin: 'mx-1.5 -mt-1.5',
    },
    "58mm": {
      printTextSize: 'text-2xs',
      printWhiteSpace: 'w-28 pr-4',
      printMargin: 'mx-1.5 -mt-1.5',
      printMarginCustomeStruct: 'mx-1.5 mt-0.5'
    },
  }

  let speedyPrint: any = {}

  if (print.length) {
    print.forEach(({ label, value }) => {
      speedyPrint[label] = value;
    })
  }

  const { Nama: name, Periode: periode, Ref: ref, Tagihan: invoice } = speedyPrint;

  return (
    <div className="flex justify-center">
      <div>
        <div className="flex gap-16 mb-1 px-2">
          <div>
            {/* CUST NAME */}
            <div className={`${printSetup[printSize] ? printSetup[printSize].printMargin : printSetup[printSize].printMarginCustomeStruct} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>NAMA</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1 leading-3">
                <span className={`${printSetup[printSize].printTextSize}`}>{name}</span>
              </div>
            </div>

            {/* CUST ID */}
            <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>ID PELANGGAN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <span className={`${printSetup[printSize].printTextSize}`}>{destination}</span>
              </div>
            </div>

            {/* INVOICE */}
            <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>TAGIHAN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={invoice}
                  className={`${`${printSetup[printSize].printTextSize}`}`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>

            {printSize === '58mm' || printSize === '80mm' ? (
              <Fragment>
                {/* WHEN THERE IS A PRINT FIELD THEN SHOW THE LABEL */}
                {print.length ? (
                  <Fragment>
                    {/* PERIODE */}
                    <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                      <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>PERIODE</span>
                        </div>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <span className={`${printSetup[printSize].printTextSize}`}>{periode}</span>
                      </div>
                    </div>

                    {/* REF */}
                    <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                      <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>REF</span>
                        </div>
                        <div>
                          <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <span className={`${printSetup[printSize].printTextSize}`}>{ref}</span>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                    <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                      <div>
                        <span className={`${printSetup[printSize].printTextSize}`}>SN</span>
                      </div>
                      <div>
                        <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                      </div>
                    </div>
                    <div className="flex-1">
                      <span className={`${printSetup[printSize].printTextSize}`}>{sn}</span>
                    </div>
                  </div>
                )}
              </Fragment>
            ) : ''}
          </div>

          {printSize === 'A4' && (
            <div>
              {/* DATA TIME */}
              <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>TANGGAL</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <span className={`${printSetup[printSize].printTextSize}`}>{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")}</span>
                </div>
              </div>

              {/* PERIODE */}
              <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>PERIODE</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <span className={`${printSetup[printSize].printTextSize}`}>{periode.replace(new RegExp('PERIODE:', 'gi'), '')}</span>
                </div>
              </div>

              {/* REF */}
              <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>REF</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <span className={`${printSetup[printSize].printTextSize}`}>{ref}</span>
                </div>
              </div>
            </div>
          )}
        </div>

        {printSize === '58mm' || printSize === '80mm' ? (
          <Fragment>
            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>ADMIN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={sellingAdmin}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>

            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left mb-1 px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>TOTAL</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={sellingPrice + sellingAdmin}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>

            <div className="px-2 mt-2.5 leading-3">
              <span className={`${`${printSetup[printSize].printTextSize}`} font-bold`}>STRUK INI ADALAH BUKTI PEMBAYARAN YANG SAH</span>
            </div>
            <div className="px-2 mt-1 leading-3">
              <span className={`${printSetup[printSize].printTextSize}`}>Tersedia juga Pulsa, Game, Produk Pascabayar dan Produk Lainnya</span>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>ADMIN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={sellingAdmin}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>

            {/* TOTAL PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left mb-1 px-2`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>TOTAL</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={parseInt(invoice.replace(new RegExp('RP', 'gi'), '')) + sellingAdmin}
                  className={`${`${printSetup[printSize].printTextSize}`} font-semibold`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>
            <div className="px-2 mt-5 leading-3">
              <span className={`${`${printSetup[printSize].printTextSize}`} font-bold`}>STRUK INI ADALAH BUKTI PEMBAYARAN YANG SAH</span>
            </div>
            <div className="px-2 mt-2 leading-3">
              <span className={`${printSetup[printSize].printTextSize}`}>Tersedia juga Pulsa, Game, Produk Pascabayar dan Produk Lainnya</span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

interface DefaultContentPropsType {
  printData: trxPrintDataType;
  printSize: string;
}

const DefaultContent: React.FC<DefaultContentPropsType> = ({
  printData,
  printSize
}): JSX.Element => {
  let [isPostpaidState, setIsPostpaidState] = useState<boolean>(false);

  const {
    destination,
    feePpob,
    productName,
    sellingPrice,
    sellingAdmin,
    print,
    sn,
    createdAt,
  } = printData;

  let printSetup: any = {
    "A4": {
      printTextSize: 'text-xs',
      printWhiteSpace: feePpob > 0 ? 'w-36 pr-4' : 'w-28 pr-4',
      printMargin: 'mx-3',
      printPadding: printSize === 'A4' && print.length >= 4 ? 'px-[23rem]' : print.length >= 8 ? 'px-64' : '',
      printGridTemplate: printSize === 'A4' && print.length >= 4 ? 'grid grid-flow-col grid-cols-2 grid-rows-4' : print.length >= 8 ? 'grid grid-flow-col grid-cols-3 grid-rows-4' : '',
    },
    "80mm": {
      printTextSize: 'text-xs',
      printWhiteSpace: feePpob > 0 ? 'w-36 pr-4' : 'w-24 pr-4',
      printMargin: 'mx-1.5 -mt-1.5',
      printPadding: 'px-2',
    },
    "58mm": {
      printTextSize: 'text-2xs',
      printWhiteSpace: feePpob > 0 ? 'w-36 pr-4' : 'w-20 pr-4',
      printMargin: 'mx-1.5 -mt-1.5',
      printMarginCustomeStruct: feePpob > 0 ? '' : 'mx-1.5 mt-0.5',
      printPadding: 'px-2',
    },
  }

  let printDataResult: any = {}

  if (print.length) {
    print.forEach(({ label, value }) => {
      printDataResult[label] = value;
    })
  }

  const { Token: token } = printDataResult;

  interface PrintDataFilterType {
    label: string;
    value: string
  }

  let printDataFilter: Array<PrintDataFilterType> = [];

  if (print.length) {
    printDataFilter = print.filter(({ label }: { label: string }) => label !== 'Token')
  }

  useEffect((): void => {
    if (feePpob > 0) {
      setIsPostpaidState(true)
    }
  }, []);

  return (
    <Fragment>
      <div className={`mb-1 ${printSetup[printSize].printPadding}`}>
        <div>
          <div className={`${printSetup[printSize].printGridTemplate}`}>

            {/* PRODUCT NAME */}
            <div className={`${printSetup[printSize] ? printSetup[printSize].printMargin : printSetup[printSize].printMarginCustomeStruct} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>PRODUK</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1 leading-3">
                <span className={`${printSetup[printSize].printTextSize}`}>{productName}</span>
              </div>
            </div>

            {/* DESTINATION */}
            <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>TUJUAN</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <span className={`${printSetup[printSize].printTextSize}`}>{destination}</span>
              </div>
            </div>

            {/* SELLING PRICE */}
            <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
              <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>HARGA</span>
                </div>
                <div>
                  <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                </div>
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={sellingPrice}
                  className={`${printSetup[printSize].printTextSize}`}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp "}
                />
              </div>
            </div>

            {printSize === "A4" && (
              <Fragment>
                {/* DATA TIME */}
                <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                  <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                    <div>
                      <span className={`${printSetup[printSize].printTextSize}`}>TANGGAL</span>
                    </div>
                    <div>
                      <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <span className={`${printSetup[printSize].printTextSize}`}>{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")}</span>
                  </div>
                </div>
              </Fragment>
            )}

            {isPostpaidState && (
              <Fragment>
                {/* ADMIN PRICE */}
                <div className="flex items-center text-left">
                  <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                    <div>
                      <span className={`${printSetup[printSize].printTextSize}`}>ADMIN</span>
                    </div>
                    <div>
                      <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <NumberFormat
                      value={sellingAdmin}
                      className={`${printSetup[printSize].printTextSize}`}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"Rp "}
                    />
                  </div>
                </div>
              </Fragment>
            )}

            <Fragment>
              {/* WHEN THERE IS A PRINT FIELD THEN SHOW THE LABEL */}
              {print.length ? (
                <Fragment>
                  {printDataFilter.map(({ label, value }, index) => (
                    <Fragment key={index}>
                      <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                        <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                          <div>
                            <span className={`${`${printSetup[printSize].printTextSize}`} uppercase`}>{label}</span>
                          </div>
                          <div>
                            <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                          </div>
                        </div>
                        <div className="flex-1">
                          <span className={`${printSetup[printSize].printTextSize}`}>{value}</span>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </Fragment>
              ) : ''}
            </Fragment>

            {/* WHEN THERE IS A PRINT FIELD THEN SHOW THE LABEL */}
            {!print.length && (
              <Fragment>
                <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                  <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                    <div>
                      <span className={`${`${printSetup[printSize].printTextSize}`}`}>SN</span>
                    </div>
                    <div>
                      <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <span className={`${printSetup[printSize].printTextSize}`}>{sn}</span>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>

      {printSize === '58mm' || printSize === '80mm' ? (
        <Fragment>
          {/* TOTAL PRICE */}
          <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left mb-1 px-2`}>
            <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
              <div>
                <span className={`${printSetup[printSize].printTextSize}`}>TOTAL</span>
              </div>
              <div>
                <span className={`${printSetup[printSize].printTextSize}`}>:</span>
              </div>
            </div>
            <div className="flex-1">
              <NumberFormat
                value={sellingPrice + sellingAdmin}
                className={`${`${printSetup[printSize].printTextSize}`} font-bold`}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"Rp "}
              />
            </div>
          </div>

          <div className="px-2 mt-3 leading-3">
            <span className={`${`${printSetup[printSize].printTextSize}`} font-bold`}>STRUK INI ADALAH BUKTI PEMBAYARAN YANG SAH</span>
          </div>
          <div className="px-2 mt-1 leading-3">
            <span className={`${printSetup[printSize].printTextSize}`}>Tersedia juga Pulsa, Game, Produk Pascabayar dan Produk Lainnya</span>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={`flex ${print.length ? 'justify-center mt-4' : ''}`}>
            <div className="block">
              {token && (
                <div className={`${printSetup[printSize].printMargin} flex items-center text-left`}>
                  <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                    <div>
                      <span className={`${printSetup[printSize].printTextSize}`}>TOKEN</span>
                    </div>
                    <div>
                      <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <span className={`${printSetup[printSize].printTextSize}`}>{token}</span>
                  </div>
                </div>
              )}

              {/* TOTAL PRICE */}
              <div className={`${printSetup[printSize].printMargin} flex justify-between items-center text-left mb-1`}>
                <div className={`flex justify-between ${printSetup[printSize].printWhiteSpace}`}>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>TOTAL</span>
                  </div>
                  <div>
                    <span className={`${printSetup[printSize].printTextSize}`}>:</span>
                  </div>
                </div>
                <div className="flex-1">
                  <NumberFormat
                    value={sellingPrice + sellingAdmin}
                    className={`${`${printSetup[printSize].printTextSize}`} font-bold`}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"Rp "}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="px-2 mt-4 leading-3">
            <span className={`${`${printSetup[printSize].printTextSize}`} font-bold`}>STRUK INI ADALAH BUKTI PEMBAYARAN YANG SAH</span>
          </div>
          <div className="px-2 mt-1 leading-3">
            <span className="text-xs">Tersedia juga Pulsa, Game, Produk Pascabayar dan Produk Lainnya</span>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export { DefaultContent, PlnContent, SpeedyContent, BpjsContent }