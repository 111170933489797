import axios from "axios";

import { selector } from "recoil";

const getDeviceDetectTokenStore = selector<number>({
	key: "deviceDetectTokenStore",
	get: async (): Promise<any> => {
		try {
			const result: any = await axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/info`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
			});
			const statusCode = result.data.status;
			return statusCode;
		} catch (err) {
			return err;
		}
	},
});

export default getDeviceDetectTokenStore;
