import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";
import { axiosAdaptor } from "../../../utils/axios-adaptor.util";

import toast from "react-hot-toast";

import loadingCardLength from "../../components/loading/loadingCardLength";
import { LandscapeLoadingCard } from "../../components/loading/LoadingCard";

import NumberFormat from "react-number-format";

import Header from "../../components/Header";

import NominalInput from "../deposit/NominalInput";

import ContentList from "../../components/ContentList";

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

interface PaymentMethodListSchemaType {
  active: boolean;
  admin: {
    nominal?: number;
    satuan?: string;
  };
  channel: string;
  created_at: string;
  description: string;
  icon: string;
  kode_merchant: string;
  order_number: number;
  provider: string;
  title: string;
  type: number;
  updated_at: string;
  _id: string;
}

const PaymentMethodPageList: React.FC = (props): JSX.Element => {
  const paymentMethodListSchema = [
    {
      active: false,
      admin: {
        nominal: 0,
        satuan: "",
      },
      channel: "",
      created_at: "",
      description: "",
      icon: "",
      kode_merchant: "",
      order_number: 0,
      provider: "",
      title: "",
      type: 0,
      updated_at: "",
      _id: "",
    },
  ];

  const [paymentMethodListState, setPaymentMethodListState] = useState<Array<PaymentMethodListSchemaType>>(paymentMethodListSchema);

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  function getPaymentMethodList(setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
    return new Promise((resolve, reject): void => {
      setLoading(true);
      axiosAdaptor({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/methode`,
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
        },
        validateStatus: (status): boolean => true,
      }).then((result) => {
        // console.log("[PAYMENT METHODE DEPOSIT LIST]", result);

        if (result.status === 200) {
          setLoading(false);
          resolve(result);
        } else {
          reject(result);
        }

      }).catch((error) => console.log("STATUS CODE", error));
    });
  };

  useEffect((): void => {
    getPaymentMethodList(setIsLoadingState).then((result: any): void => {
      const statusCode: number = result.data.status;
      const data: any = result.data.data;
      if (statusCode === 200) setPaymentMethodListState(data);
    }).catch((e: any): void => {
      toast.error(e.data.message, {
        style: {
          fontWeight: "bolder",
        },
      });
      console.log(e);
    });
  }, []);

  const getVirtualAccountList = (): Promise<unknown> => {
    return new Promise((resolve, reject): void => {
      axios({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/virtual-account/list`,
        method: "GET",
        validateStatus: (status: any): boolean => true,
      }).then((result: any): void => {
        // console.log("[VIRTUAL ACCOUNT LIST", result);

        if (result.status === 200) {
          resolve(result);
        } else {
          reject(result);
        }

      }).catch((error: any): void => console.log("STATUS CODE", error));
    });
  };

  const ScrollToTopOnMount = (): null => {
    useEffect((): void => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  };

  return (
    <div>
      <Header title="Pilih Metode Pembayaran" />

      <ScrollToTopOnMount />

      {isLoadingState ? (
        <div className="flex flex-col gap-4">
          {loadingCardLength.slice(0, 5).map((loading, index) => (
            <Fragment key={index}>
              <LandscapeLoadingCard line={3} />
            </Fragment>
          ))}
        </div>
      ) : (
        <Fragment>
          {paymentMethodListState.map(({ active, admin, channel, description, icon, title, type, _id: id }, index) => (
            <Fragment key={index}>
              {active && (
                <Fragment key={id}>
                  <ContentList
                    onClick={(): void => {
                      if (type === 5) {
                        toast.promise(getVirtualAccountList(), {
                          loading: "Loading...",
                          success: (result: any): string => {
                            const statusCode: number = result.data.status;
                            const data: any = result.data.data;
                            if (statusCode === 200) {
                              Swal.fire({
                                title: "Masukan Nominal",
                                padding: "1rem 0rem",
                                heightAuto: false,
                                allowOutsideClick: false,
                                confirmButtonText: "Lanjutkan",
                                showCancelButton: false,
                                showConfirmButton: false,
                                cancelButtonText: "Batal",
                                customClass: {
                                  title: "font-inter font-semibold text-2xl text-gray-800",
                                  confirmButton: "text-sm lg:text-base font-inter",
                                  cancelButton: "text-sm lg:text-base font-inter",
                                },
                                html: (
                                  <NominalInput title={title} type={type} channel={channel} loading={isLoadingState} data={data} />
                                ),
                              });
                            }

                            return "Silahkan Pilih Jenis Virtual Account";
                          },
                          error: (e: any): string => e.data.message,
                        },
                          {
                            style: {
                              fontWeight: "bolder",
                            },
                            duration: 5000,
                          }
                        );

                        return;
                      }

                      const modalNominalInput = async (data?: Array<any>): Promise<void> => {
                        Swal.fire({
                          title: "Masukan Nominal",
                          padding: "1rem 0rem",
                          heightAuto: false,
                          allowOutsideClick: false,
                          confirmButtonText: "Lanjutkan",
                          showCancelButton: false,
                          showConfirmButton: false,
                          cancelButtonText: "Batal",
                          customClass: {
                            title: "font-inter font-semibold text-2xl text-gray-800",
                            cancelButton: "text-sm lg:text-base font-inter",
                            confirmButton: "text-sm lg:text-base font-inter",
                          },
                          html: (
                            <NominalInput title={title} type={type} channel={channel} loading={isLoadingState} data={data} />
                          ),
                        });
                      };

                      modalNominalInput();
                    }}
                    line={3}
                    icon={<img src={icon} className="w-9 h-9 sm:w-16 sm:h-16 rounded-sm" alt="icon" />}
                    firstLine={<span className="mb-1 text-xs sm:text-base font-medium tracking-wide">{title}</span>}
                    secondLine={<span className="text-xs sm:text-sm font-base tracking-wide">{description}</span>}
                    thirdLine={admin ?
                      admin.satuan === 'rupiah' ? (
                        <Fragment>
                          <NumberFormat
                            value={admin.nominal}
                            className="mt-0.5 text-xs sm:text-base font-medium tracking-wide mr-2"
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="+Rp "
                          />
                          <span className="text-xs sm:text-base tracking-wide">(admin)</span>
                        </Fragment>

                      ) : admin.satuan === 'persen' ? (
                        <Fragment>
                          <span className="sm:mt-0.5 text-xs sm:text-base font-medium tracking-wide mr-2">+{admin.nominal}%</span>
                          <span className="text-xs sm:text-base tracking-wide">(admin)</span>
                        </Fragment>
                      ) : '' : ''}
                  />
                </Fragment>
              )}
            </Fragment>
          )
          )}
        </Fragment>
      )}
    </div>
  );
};

export default PaymentMethodPageList;
