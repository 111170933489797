import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../../../store/theme-app.store";

import moment from "moment";

import NumberFormat from "react-number-format";

import toast from "react-hot-toast";

import { useHistory } from "react-router-dom";

import loadingCardLength from "../../../components/loading/loadingCardLength";
import { LandscapeLoadingCard } from "../../../components/loading/LoadingCard";

import EmptyIcon from "../../../components/icons/EmptyIcon";

import Header from "../../../components/Header";

import ContentList from "../../../components/ContentList";

import MoreDataList from "../../../components/MoreDataList";

const idLocale = require('moment/locale/id')
moment.updateLocale('id', idLocale);

const DepositListPage: React.FC = (props): JSX.Element => {

  interface DepositListSchemaType {
    admin: number;
    created_at: string;
    expired_at: string;
    keterangan: string;
    kode_merchant: string;
    kode_pembayaran: string;
    nama_customer: string;
    nominal: number;
    status: number;
    type: number;
    updated_at: string;
    user_id?: string;
    vaname: string;
    _id: string;
  }

  const depositListSchema = [
    {
      admin: 0,
      created_at: "",
      expired_at: "",
      keterangan: "",
      kode_merchant: "",
      kode_pembayaran: "",
      nama_customer: "",
      nominal: 0,
      status: 0,
      type: 0,
      updated_at: "",
      user_id: "",
      vaname: "",
      _id: "",
    },
  ];

  const [depositListState, setDepositListState] = useState<Array<DepositListSchemaType>>(depositListSchema);

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [isPaginationLoadingState, setIsPaginationLoadingState] = useState<boolean>(false);
  const [isMaxPaginationState, setIsMaxPaginationState] = useState<boolean>(false);

  let [pageState, setPageState] = useState<number>(0);

  function getDepositList(depositPage: number, setLoading: React.Dispatch<React.SetStateAction<boolean>>, setPaginationLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
    return new Promise((resolve, reject): void => {
      if (depositPage <= 0) {
        setLoading(true);
      }
      axios({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/list?page=${depositPage}`,
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
        },
        validateStatus: (status): boolean => true,
      }).then((result: any): void => {
        // console.log("[DEPOSIT LIST]", result);

        if (result.status === 200) {
          setLoading(false);
          setPaginationLoading(false);
          resolve(result);
        } else {
          reject(result);
        }
      }).catch((error: any): void => console.log("STATUS CODE", error));
    });
  };

  useEffect((): void => {
    getDepositList(pageState, setIsLoadingState, setIsPaginationLoadingState).then((result: any): void => {
      const statusCode: number = result.status;
      const data: any = result.data.data;
      if (statusCode === 200) {
        if (pageState > 0) {
          if (!data.length) {
            setIsMaxPaginationState(true);
            toast.error(`Data telah mencapai batas maximum`, {
              style: { fontWeight: "bolder" },
            });
          }
          setDepositListState([...depositListState, ...data]);
          return;
        }

        setDepositListState(data);
      }
    }
    ).catch((e: any): void => {
      toast.error(e.data.message, {
        style: { fontWeight: "bolder" },
      });
      console.log(e);
    });
  }, [pageState]);

  const history: any = useHistory();
  function handleDepositListDetails(data: any) {
    history.push({
      pathname: '/dashboard/profile/history/deposit/show',
      state: { data }
    });
  }

  const themeState = useRecoilValue<string | null>(themeAppStore);

  const secondLineTheme: string = themeState === "dark" ? "text-gray-400" : 'text-gray-800';

  return (
    <div>
      <Header title="Riwayat Deposit" />

      {isLoadingState ? (
        <div className="flex flex-col gap-4">
          {loadingCardLength.slice(0, 10).map((loading, index) => (
            <Fragment key={index}>
              <LandscapeLoadingCard line={3} />
            </Fragment>
          ))}
        </div>
      ) : (
        <Fragment>
          {!depositListState.length ? (
            <div className="mt-24 lg:mt-20 2xl:mt-40">
              <EmptyIcon title="Anda Belum Memiliki Riwayat Deposit" />
            </div>
          ) : (
            depositListState.map(({
              admin,
              created_at: createdAt,
              expired_at: expiredAt,
              keterangan: description,
              kode_pembayaran: paymentCode,
              nominal,
              status,
              type,
              vaname,
              _id: id,
            }, index): JSX.Element => (
              <Fragment key={index}>
                <ContentList
                  onClick={(): void => {
                    const data = {
                      admin,
                      createdAt,
                      expiredAt,
                      description,
                      paymentCode,
                      nominal,
                      status,
                      type,
                      vaname,
                    };

                    handleDepositListDetails(data)
                  }}
                  line={3}
                  icon={
                    <Fragment>
                      {/**
                         * TYPE 2 === SUCCESS
                         * TYPE 3 === FAILED
                         * TYPE 4 === PENDING
                         */}
                      <div className="lg:w-16">
                        {status === 1 ? (
                          <svg
                            className="w-9 h-9 sm:w-14 sm:h-14 rounded-sm"
                            fill="#2DB981"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
                          </svg>
                        ) : status === 2 ? (
                          <svg
                            className="w-9 h-9 sm:w-14 sm:h-14 rounded-sm"
                            fill="#EF4449"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="40"
                            height="40">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z" />
                          </svg>
                        ) : (
                          <svg
                            className="w-9 h-9 sm:w-14 sm:h-14 rounded-sm"
                            fill="#F9B012"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="40"
                            height="40">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm10-5a1 1 0 0 1-1 1h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zM7 12a1 1 0 0 1-1 1H3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zm12.071 7.071a1 1 0 0 1-1.414 0l-2.121-2.121a1 1 0 0 1 1.414-1.414l2.121 2.12a1 1 0 0 1 0 1.415zM8.464 8.464a1 1 0 0 1-1.414 0L4.93 6.344a1 1 0 0 1 1.414-1.415L8.464 7.05a1 1 0 0 1 0 1.414zM4.93 19.071a1 1 0 0 1 0-1.414l2.121-2.121a1 1 0 1 1 1.414 1.414l-2.12 2.121a1 1 0 0 1-1.415 0zM15.536 8.464a1 1 0 0 1 0-1.414l2.12-2.121a1 1 0 0 1 1.415 1.414L16.95 8.464a1 1 0 0 1-1.414 0z" />
                          </svg>
                        )}
                      </div>
                    </Fragment>
                  }
                  firstLine={
                    <Fragment>
                      <NumberFormat
                        className="mb-1 text-xs sm:text-base font-medium tracking-wide"
                        value={nominal}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"Rp "}
                      />
                    </Fragment>
                  }
                  secondLine={<span className={`text-xs sm:text-base font-base tracking-wide ${secondLineTheme}`}>{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")}</span>}
                  thirdLine={
                    <Fragment>
                      {/**
                         * TYPE 2 === SUCCESS
                         * TYPE 3 === FAILED
                         * TYPE 4 === PENDING
                         */}
                      <div className="text-right lg:pr-0">
                        {status === 1 ? (
                          <span className="text-[#00ff00] text-xs sm:text-base font-medium tracking-wide">Sukses</span>
                        ) : status === 2 ? (
                          <span className="text-[#ff0000] text-xs sm:text-base font-medium tracking-wide">Gagal</span>
                        ) : (
                          <span className="text-yellow-500 text-xs sm:text-base font-medium tracking-wide">Dalam Proses</span>
                        )}
                      </div>
                    </Fragment>
                  }
                />
              </Fragment>
            )
            )
          )}
        </Fragment>
      )}

      {isPaginationLoadingState ? (
        <div className="flex justify-center items-center">
          <span className="text-xs sm:text-base font-medium tracking-wide">Loading...</span>
        </div>
      ) : (
        depositListState.length >= 10 &&
        !isLoadingState && (
          <Fragment>
            {!isMaxPaginationState && <MoreDataList setPage={setPageState} setPaginationLoading={setIsPaginationLoadingState} />}
          </Fragment>
        )
      )}
    </div>
  );
};

export default DepositListPage;
