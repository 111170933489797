import axios from "axios";

export function getUserInfo(setIsLoadingState: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
  return new Promise((resolve, reject): void => {
    setIsLoadingState(true);
    axios({
      url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/info`,
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("user_token"),
      },
      validateStatus: (status): boolean => {
        return true;
      },
    })
      .then((result: any): void => {
        // console.log("[USER INFO]", result);

        if (result.status === 200) {
          setIsLoadingState(false);
          resolve(result);
        } else {
          reject(result);
        }
      })
      .catch((error: any): void => {
        console.log("STATUS CODE", error);
      });
  });
};

export function getDptCities(id: string): Promise<void> {
  return new Promise((resolve, reject): void => {
    axios({
      url: `${process.env.REACT_APP_API_ENDPOINT_URL}/propinsi/${id}/kabupaten`,
      method: "GET",
      validateStatus: (status: any): boolean => true,
    }).then((result: any): void => {
      // console.log('[DPT KABUPATEN]', result)

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    }).catch((error: any): void => console.log("STATUS CODE", error));
  });
};

export function generateDeviceToken(length: number) {
  const a: any =
    "_-:abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split(
      ""
    );
  let b = [];
  for (let i = 0; i < length; i++) {
    let j: string = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[j];
  }
  return b.join("");
};