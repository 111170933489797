import React, { Fragment, useEffect, useState } from "react";

import { axiosAdaptor } from "../../utils/axios-adaptor.util";

import toast from "react-hot-toast";

import Carousel, { autoplayPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import LandscapeLoadingBannerCard from "./loading/BannerList";

const BannerList: React.FC = (props): JSX.Element => {

	interface BannerListSchemaType {
		_id: string;
		cover: string;
		kode_merchant: string;
		title: string;
		url: string;
	}

	const bannerListSchema = [
		{
			cover: "",
			kode_merchant: "",
			title: "",
			url: "",
			_id: "",
		},
	];

	const [bannerListState, setBannerListState] = useState<Array<BannerListSchemaType>>(bannerListSchema);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

	function getBannerList(): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			setIsLoadingState(true);
			axiosAdaptor({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/banner/list?limit=3`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then(async (result: any) => {
				// console.log('[BANNER LIST]', result)

				// const cacheStore = await cache.store

				// console.log('Cache store:', cacheStore)

				if (result.status === 200) {
					setIsLoadingState(false);
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		getBannerList().then((result: any) => {
			const statusCode = result.data.status;
			const data = result.data.data;
			if (statusCode === 200) setBannerListState(data);
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder", },
			});
			console.log(e);
		});
	}, []);

	return (
		<section>
			<div className="flex flex-col lg:gap-1.5">
				{/* BANNER TITLE LIST */}
				<div title="Judul Banner">
					<span className="text-sm sm:text-lg lg:text-base font-medium tracking-widest">{process.env.REACT_APP_DASHBOARD_BANNER_LIST_TITLE}</span>
				</div>

				{/* BANNER DESCRIPTION LIST */}
				<div title="Deskripsi Banner">
					<span className="text-xs sm:text-base lg:text-[0.805rem] text-gray-500 font-hind">{process.env.REACT_APP_DASHBOARD_BANNER_LIST_DESCRIPTION}</span>
				</div>
			</div>

			<div className="mt-6">
				{isLoadingState ? (
					<LandscapeLoadingBannerCard />
				) : (
					<div>
						{/* BANNER CAROUSEL */}
						<Carousel
							plugins={["infinite", { resolve: autoplayPlugin, options: { interval: 4000 } }]}
							animationSpeed={1000}>
							{bannerListState.map(({ _id: id, cover, title, url, }: { _id: string; cover: string; title: string; url: string; }, index): JSX.Element => (
								<Fragment key={index}>
									<a href={url} target="_blank" rel="noopener noreferrer">
										<img className="px-2 aspect-[16/8]" key={id} src={cover} alt="banner" />
									</a>
								</Fragment>
							))}
						</Carousel>
					</div>
				)}
			</div>
		</section>
	);
};

export default BannerList;
