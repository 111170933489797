import React from "react";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../store/theme-app.store";

export const LogoutDropdownMenu: React.FC = (props): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	function handleClickUserLogout(): void {
		if (localStorage.getItem("user_token")) {
			localStorage.clear();
			window.location.replace("/auth/login");
		}
	};

	const defaultTheme = {
		iconTheme: 'lg:text-black',
	}

	if (themeState === 'dark') {
		defaultTheme['iconTheme'] = 'lg:text-gray-100';
	}

	return (
		<button
			onClick={(): void => handleClickUserLogout()}
			className="flex items-center gap-2 px-2 py-3 rounded rounded-t-none focus:outline-none">
			<span className="px-1 py-1">
				<svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M17.4399 15.3699C17.2499 15.3699 17.0599 15.2999 16.9099 15.1499C16.6199 14.8599 16.6199 14.3799 16.9099 14.0899L18.9399 12.0599L16.9099 10.0299C16.6199 9.73994 16.6199 9.25994 16.9099 8.96994C17.1999 8.67994 17.6799 8.67994 17.9699 8.96994L20.5299 11.5299C20.8199 11.8199 20.8199 12.2999 20.5299 12.5899L17.9699 15.1499C17.8199 15.2999 17.6299 15.3699 17.4399 15.3699Z" fill="currentColor" />
					<path d="M19.9298 12.8101H9.75977C9.34977 12.8101 9.00977 12.4701 9.00977 12.0601C9.00977 11.6501 9.34977 11.3101 9.75977 11.3101H19.9298C20.3398 11.3101 20.6798 11.6501 20.6798 12.0601C20.6798 12.4701 20.3398 12.8101 19.9298 12.8101Z" fill="currentColor" />
					<path d="M11.7598 20.75C6.60977 20.75 3.00977 17.15 3.00977 12C3.00977 6.85 6.60977 3.25 11.7598 3.25C12.1698 3.25 12.5098 3.59 12.5098 4C12.5098 4.41 12.1698 4.75 11.7598 4.75C7.48977 4.75 4.50977 7.73 4.50977 12C4.50977 16.27 7.48977 19.25 11.7598 19.25C12.1698 19.25 12.5098 19.59 12.5098 20C12.5098 20.41 12.1698 20.75 11.7598 20.75Z" fill="currentColor" />
				</svg>
			</span>
			<div>
				<span className="text-md font-medium capitalize tracking-wider">Keluar</span>
			</div>
		</button>
	);
};

/* LOGOUT MENU MOBILE DEVICE */
export const LogoutSidebarMenu: React.FC = (props): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	function handleClickUserLogout(): void {
		if (localStorage.getItem("user_token")) {
			localStorage.clear();
			window.location.replace("/auth/login");
		}
	};

	const sidebarThemeMode: string = themeState === "dark" ? "bg-dark-sidebar border-gray-700" : "bg-white";

	return (
		<li
			onClick={(): void => handleClickUserLogout()}
			className={`${sidebarThemeMode} block lg:hidden border-b`}>
			<div className="flex w-full justify-between items-center cursor-pointer noselect">
				<div className="w-36 lg:w-40 pl-6 py-5">
					<span className="text-sm sm:text-base lg:text-[0.905rem] 2xl:text-base font-medium capitalize tracking-wider">Keluar</span>
				</div>
				<div className="flex items-center px-5 py-4">
					<svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
				</div>
			</div>
		</li>
	);
};
