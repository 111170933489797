import { Fragment } from "react";

import moment from "moment";

const idLocale = require('moment/locale/id')
moment.updateLocale('id', idLocale);

interface PlnHeaderPropsType {
  merchantName: string;
  printSize: string;
  createdAt: string;
}

const PlnHeader: React.FC<PlnHeaderPropsType> = ({
  merchantName,
  printSize,
  createdAt,
}): JSX.Element => {
  const printHeaderSetup: any = {
    "A4": {
      printTextSize: 'text-sm',
      printTitleSize: 'text-base',
      printMargin: '-ml-[8rem]',
      printWrapper: 'w-screen flex justify-center',
      printDataTimeDisplay: true,
    },
    "80mm": {
      printTextSize: 'text-xs',
      printTitleSize: 'text-sm',
      printMargin: 'mx-1.5',
    },
    "58mm": {
      printTextSize: 'text-2xs',
      printTitleSize: 'text-xs',
      printMargin: 'mx-1.5',
    }
  }

  return (
    <div className={`${printHeaderSetup[printSize].printWrapper}`}>
      <div className="px-2">
        <div>
          <span className={`${printHeaderSetup[printSize].printTextSize} font-medium uppercase`}>{merchantName}</span>
        </div>
        <div className={`${printHeaderSetup[printSize].printDataTimeDisplay ? 'mb-3' : ''} leading-5`}>
          <span className={`${printHeaderSetup[printSize].printTitleSize} font-bold uppercase`}>Struk Pembayaran PLN Pascabayar</span>
        </div>
        {/* DATE TIME */}
        <div className={`${printHeaderSetup[printSize].printDataTimeDisplay ? 'hidden' : 'block'} ${printHeaderSetup[printSize].printMargin} py-1 text-left`}>
          <div>
            <span className={`${printHeaderSetup[printSize].printTextSize} font-medium`}>{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")} WIB</span>
          </div>
        </div>
      </div>
    </div>
  );
}

interface SpeedyHeaderPropsType {
  merchantName: string;
  printSize: string;
  createdAt: string;
}

const SpeedyHeader: React.FC<SpeedyHeaderPropsType> = ({
  merchantName,
  printSize,
  createdAt,
}): JSX.Element => {
  const printHeaderSetup: any = {
    "A4": {
      printTextSize: 'text-sm',
      printTitleSize: 'text-base',
      printMargin: '-ml-[8rem]',
      printWrapper: 'w-screen flex justify-center',
      printDataTimeDisplay: true,
    },
    "80mm": {
      printTextSize: 'text-xs',
      printTitleSize: 'text-sm',
      printMargin: 'mx-1.5',
    },
    "58mm": {
      printTextSize: 'text-2xs',
      printTitleSize: 'text-xs',
      printMargin: 'mx-1.5',
    }
  }

  return (
    <div className={`${printHeaderSetup[printSize].printWrapper}`}>
      <div className="px-2">
        <div>
          <span className={`${printHeaderSetup[printSize].printTextSize} font-medium uppercase`}>{merchantName}</span>
        </div>
        <div className={`${printHeaderSetup[printSize].printDataTimeDisplay ? 'mb-3' : ''} leading-5`}>
          <span className={`${printHeaderSetup[printSize].printTitleSize} font-bold uppercase`}>Struk Pembayaran SPEEDY Pascabayar</span>
        </div>
        {/* DATE TIME */}
        <div className={`${printHeaderSetup[printSize].printDataTimeDisplay ? 'hidden' : 'block'} ${printHeaderSetup[printSize].printMargin} py-1 text-left`}>
          <div>
            <span className={`${printHeaderSetup[printSize].printTextSize} font-medium`}>{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")} WIB</span>
          </div>
        </div>
      </div>
    </div>
  );
}

interface BpjsHeaderPropsType {
  merchantName: string;
  printSize: string;
  createdAt: string;
}

const BpjsHeader: React.FC<BpjsHeaderPropsType> = ({
  merchantName,
  printSize,
  createdAt,
}): JSX.Element => {
  const printHeaderSetup: any = {
    "A4": {
      printTextSize: 'text-sm',
      printTitleSize: 'text-base',
      printMargin: '-ml-[8rem]',
      printWrapper: 'w-screen flex justify-center',
      printDataTimeDisplay: true,
    },
    "80mm": {
      printTextSize: 'text-xs',
      printTitleSize: 'text-sm',
      printMargin: 'mx-1.5',
    },
    "58mm": {
      printTextSize: 'text-2xs',
      printTitleSize: 'text-xs',
    }
  }

  return (
    <div className={`${printHeaderSetup[printSize].printWrapper}`}>
      <div className="px-2">
        <div>
          <span className={`${printHeaderSetup[printSize].printTextSize} font-medium uppercase`}>{merchantName}</span>
        </div>
        <div className={`${printHeaderSetup[printSize].printDataTimeDisplay ? 'mb-3' : ''} leading-5`}>
          <span className={`${printHeaderSetup[printSize].printTitleSize} font-bold uppercase`}>Struk Pembayaran BPJS Pascabayar</span>
        </div>
        {/* DATE TIME */}
        <div className={`${printHeaderSetup[printSize].printDataTimeDisplay ? 'hidden' : 'block'} ${printHeaderSetup[printSize].printMargin} py-1 text-left`}>
          <div>
            <span className={`${printHeaderSetup[printSize].printTextSize} font-medium`}>{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")} WIB</span>
          </div>
        </div>
      </div>
    </div>
  );
}

interface DefaultHeaderPropsType {
  merchantName: string;
  feePpob: number;
  printSize: string;
  createdAt: string;
}

const DefaultHeader: React.FC<DefaultHeaderPropsType> = ({
  merchantName,
  feePpob,
  printSize,
  createdAt,
}): JSX.Element => {
  const printHeaderSetup: any = {
    "A4": {
      printTextSize: 'text-sm',
      printTitleSize: 'text-base',
      printMargin: feePpob < 1 ? 'mx-1.5' : '',
      printDataTimeDisplay: true,
    },
    "80mm": {
      printTextSize: 'text-xs',
      printTitleSize: 'text-sm',
      printMargin: feePpob < 1 ? 'mx-1.5' : ''
    },
    "58mm": {
      printTextSize: 'text-2xs',
      printTitleSize: 'text-xs',
      printMargin: feePpob < 1 ? 'mx-1.5' : ''
    }
  }

  return (
    <Fragment>
      <div className="px-2">
        <div>
          <span className={`${printHeaderSetup[printSize].printTextSize} font-medium uppercase`}>{merchantName}</span>
        </div>
        <div className={`${printHeaderSetup[printSize].printDataTimeDisplay ? 'mb-3' : ''} leading-5`}>
          <span className={`${printHeaderSetup[printSize].printTitleSize} font-bold uppercase`}>Struk Pembayaran Transaksi</span>
        </div>
        {/* DATE TIME */}
        <div className={`${printHeaderSetup[printSize].printDataTimeDisplay ? 'hidden' : 'block'} ${printHeaderSetup[printSize].printMargin} py-1 text-left`}>
          <div>
            <span className={`${printHeaderSetup[printSize].printTextSize} font-medium`}>{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")} WIB</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export { DefaultHeader, PlnHeader, SpeedyHeader, BpjsHeader }