import React, { useEffect } from "react";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../../store/theme-app.store";
import userInfoStore from "../../../store/userInfo";

import NumberFormat from "react-number-format";

import Header from "../../components/Header";

interface CardProfileProps {
	name: string;
	balance: number;
}

const CardProfile: React.FC<CardProfileProps> = ({
	name,
	balance,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const cardTheme = {
		defaultCardTheme: 'bg-white',
		cardText: 'text-xs lg:text-sm lg:text-base-content',
	}

	if (themeState === 'dark') {
		cardTheme['defaultCardTheme'] = 'bg-[#1f283e] border-gray-700';
		cardTheme['cardText'] = 'lg:text-white';
	}

	return (
		<div
			className={`${cardTheme.defaultCardTheme} card flex-1 shadow compact side mb-4 rounded-md lg:mr-4`}>
			<div className="flex-row items-center space-x-4 card-body">
				<div>
					<div className="avatar">
						<div className="rounded-full">
							<svg className="w-8 h-8 lg:w-10 lg:h-10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12.1596 11.62C12.1296 11.62 12.1096 11.62 12.0796 11.62C12.0296 11.61 11.9596 11.61 11.8996 11.62C8.99957 11.53 6.80957 9.25 6.80957 6.44C6.80957 3.58 9.13957 1.25 11.9996 1.25C14.8596 1.25 17.1896 3.58 17.1896 6.44C17.1796 9.25 14.9796 11.53 12.1896 11.62C12.1796 11.62 12.1696 11.62 12.1596 11.62ZM11.9996 2.75C9.96957 2.75 8.30957 4.41 8.30957 6.44C8.30957 8.44 9.86957 10.05 11.8596 10.12C11.9096 10.11 12.0496 10.11 12.1796 10.12C14.1396 10.03 15.6796 8.42 15.6896 6.44C15.6896 4.41 14.0296 2.75 11.9996 2.75Z" fill="currentColor" />
								<path d="M12.1696 22.55C10.2096 22.55 8.23961 22.05 6.74961 21.05C5.35961 20.13 4.59961 18.87 4.59961 17.5C4.59961 16.13 5.35961 14.86 6.74961 13.93C9.74961 11.94 14.6096 11.94 17.5896 13.93C18.9696 14.85 19.7396 16.11 19.7396 17.48C19.7396 18.85 18.9796 20.12 17.5896 21.05C16.0896 22.05 14.1296 22.55 12.1696 22.55ZM7.57961 15.19C6.61961 15.83 6.09961 16.65 6.09961 17.51C6.09961 18.36 6.62961 19.18 7.57961 19.81C10.0696 21.48 14.2696 21.48 16.7596 19.81C17.7196 19.17 18.2396 18.35 18.2396 17.49C18.2396 16.64 17.7096 15.82 16.7596 15.19C14.2696 13.53 10.0696 13.53 7.57961 15.19Z" fill="currentColor" />
							</svg>
						</div>
					</div>
				</div>
				<div>
					<h2 className="text-sm lg:text-base font-medium mb-1">{name}</h2>
					<NumberFormat
						value={balance}
						className={`${cardTheme.cardText} font-medium lg:text-opacity-60`}
						displayType={"text"}
						thousandSeparator={"."}
						decimalSeparator={","}
						prefix={"Rp "}
					/>
				</div>
			</div>
		</div>
	);
};

interface CardPointProps {
	point: number;
}

const CardPoint: React.FC<CardPointProps> = ({ point }): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const cardTheme = {
		defaultCardTheme: 'bg-white',
		cardText: 'text-xs lg:text-sm lg:text-base-content'
	}

	if (themeState === 'dark') {
		cardTheme['defaultCardTheme'] = 'bg-[#1f283e] border-gray-700';
		cardTheme['cardText'] = 'lg:text-white';
	}

	return (
		<div
			className={`${cardTheme.defaultCardTheme} card flex-1 shadow compact side mb-4 rounded-md lg:mr-4`}>
			<div className="flex-row items-center space-x-4 card-body">
				<div>
					<h2 className="text-sm lg:text-base font-medium mb-1">Poin</h2>
					<NumberFormat
						value={point}
						className={`${cardTheme.cardText} font-medium lg:text-opacity-60`}
						displayType={"text"}
						thousandSeparator={"."}
						decimalSeparator={","}
						prefix={"Rp "}
					/>
				</div>
			</div>
		</div>
	);
};

interface CardCommitionProps {
	commition: number;
}

const CardCommition: React.FC<CardCommitionProps> = ({
	commition,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const cardTheme = {
		defaultCardTheme: 'bg-white',
		cardText: 'text-xs lg:text-sm lg:text-base-content'
	}

	if (themeState === 'dark') {
		cardTheme['defaultCardTheme'] = 'bg-[#1f283e] border-gray-700';
		cardTheme['cardText'] = 'lg:text-white';
	}

	return (
		<div
			className={`${cardTheme.defaultCardTheme} card flex-1 shadow compact side mb-4 rounded-md lg:mr-4`}>
			<div className="flex-row items-center space-x-4 card-body">
				<div>
					<h2 className="text-sm lg:text-base font-medium mb-1">Komisi</h2>
					<NumberFormat
						value={commition}
						className={`${cardTheme.cardText} font-medium lg:text-opacity-60`}
						displayType={"text"}
						thousandSeparator={"."}
						decimalSeparator={","}
						prefix={"Rp "}
					/>
				</div>
			</div>
		</div>
	);
};

interface CardStatusProps {
	active: boolean;
}

const CardStatus: React.FC<CardStatusProps> = ({ active }): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const cardTheme = {
		defaultCardTheme: 'bg-white',
	}

	if (themeState === 'dark') {
		cardTheme['defaultCardTheme'] = 'bg-[#1f283e] border-gray-700';
	}

	return (
		<div
			className={`${cardTheme.defaultCardTheme} card flex-1 shadow compact side mb-4 rounded-md`}>
			<div className="flex-row items-center space-x-4 card-body">
				<div>
					<h2 className="text-sm lg:text-base font-medium mb-1">Status</h2>
					{active ? (
						<p className="text-xs lg:text-sm font-medium text-success uppercase">Aktif</p>
					) : (
						<p className="text-xs lg:text-sm font-medium text-error uppercase">Banned</p>
					)}
				</div>
			</div>
		</div>
	);
};

interface CardMoreInfoProps {
	id: string;
	name: string;
	phone: string;
	balance: number;
	commition: number;
	point: number;
	merchantName: string;
	merchantAddress: string;
	address: string;
	kecamatanId: string;
	kabupatenId: string;
	provinceId: string;
}

const CardMoreInfo: React.FC<CardMoreInfoProps> = ({
	id,
	name,
	phone,
	balance,
	commition,
	point,
	merchantName,
	merchantAddress,
	address,
	kecamatanId,
	kabupatenId,
	provinceId,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const cardTheme = {
		defaultCardTheme: 'bg-white',
	}

	if (themeState === 'dark') {
		cardTheme['defaultCardTheme'] = 'bg-[#1f283e] border-gray-700';
	}

	return (
		<div
			className={`${cardTheme.defaultCardTheme} flex-1 card shadow compact side rounded-md`}>
			<div className="flex-row items-center space-x-4 card-body">
				<div className="w-full">
					<h2 className="text-sm lg:text-lg font-medium mb-1">Informasi Selengkapnya</h2>

					{/* USER ID */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">ID Pengguna</span>
						</div>
						<div className="flex-1 text-right">
							<span className="text-xs lg:text-base font-medium tracking-wider">{id}</span>
						</div>
					</div>

					{/* NAME */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">Nama</span>
						</div>
						<div className="flex-1 text-right">
							<span className="text-xs lg:text-base font-medium">{name}</span>
						</div>
					</div>

					{/* PHONE NUMBER */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">Nomor Telepon</span>
						</div>
						<div className="flex-1 text-right">
							<span className="text-xs lg:text-base font-medium">{phone}</span>
						</div>
					</div>

					{/* BALANCE */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">Saldo</span>
						</div>
						<div className="flex-1 text-right">
							<NumberFormat
								value={balance}
								className="text-xs lg:text-base font-medium"
								displayType={"text"}
								thousandSeparator={"."}
								decimalSeparator={","}
							/>
						</div>
					</div>

					{/* COMMITON */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">Komisi</span>
						</div>
						<div className="flex-1 text-right">
							<span className="text-xs lg:text-base font-medium">{commition}</span>
						</div>
					</div>

					{/* POINT */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">Poin</span>
						</div>
						<div className="flex-1 text-right">
							<span className="text-xs lg:text-base font-medium">{point}</span>
						</div>
					</div>

					{/* MERCHANT NAME */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">Nama Toko</span>
						</div>
						<div className="flex-1 text-right">
							<span className="text-xs lg:text-base font-medium">{merchantName}</span>
						</div>
					</div>

					{/* MERCHANT ADDRESS */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">Alamat Toko</span>
						</div>
						<div className="flex-1 text-right">
							<span className="text-xs lg:text-base font-medium">{merchantAddress}</span>
						</div>
					</div>

					{/* ADDRESS */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">Alamat</span>
						</div>
						<div className="flex-1 text-right">
							<span className="text-xs lg:text-base font-medium">{address}</span>
						</div>
					</div>

					{/* KECAMATAN ID */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">Kecamatan</span>
						</div>
						<div className="flex-1 text-right">
							<span className="text-xs lg:text-base font-medium">{kecamatanId}</span>
						</div>
					</div>

					{/* KABUPATEN ID */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">Kota</span>
						</div>
						<div className="flex-1 text-right">
							<span className="text-xs lg:text-base font-medium">{kabupatenId}</span>
						</div>
					</div>

					{/* PROVINCE */}
					<div className="flex justify-between items-center py-2.5 border-b border-dotted border-gray-300">
						<div className="flex-1">
							<span className="text-xs lg:text-base">Provinsi</span>
						</div>
						<div className="flex-1 text-right">
							<span className="text-xs lg:text-base font-medium">{provinceId}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const UserInfoDetailsPage: React.FC = (props): JSX.Element => {
	const userInformationState = useRecoilValue(userInfoStore);

	const {
		aktif: active,
		alamat: address,
		id_kabupaten: kabupatenId,
		id_kecamatan: kecamatanId,
		id_propinsi: provinceId,
		komisi: commition,
		nama: name,
		phone,
		poin: point,
		saldo: balance,
		toko: { alamat: merchantAddress, nama: merchantName },
		_id: id,
	} = userInformationState;

	const ScrollToTopOnMount = (): null => {
		useEffect(() => {
			window.scrollTo(0, 0);
		}, []);

		return null;
	};

	return (
		<div>
			<Header title={`Informasi User`} />

			<ScrollToTopOnMount />

			<div className="w-full">
				<div className="mb-4">
					<div className="grid grid-cols-1 lg:grid-cols-4">
						<CardProfile name={name} balance={balance} />
						<CardPoint point={point} />
						<CardCommition commition={commition} />
						<CardStatus active={active} />
					</div>
				</div>
				<div className="flex">
					<CardMoreInfo
						id={id}
						name={name}
						phone={phone}
						balance={balance}
						commition={commition}
						point={point}
						merchantName={merchantName}
						merchantAddress={merchantAddress}
						address={address}
						kecamatanId={kecamatanId}
						kabupatenId={kabupatenId}
						provinceId={provinceId}
					/>
				</div>
			</div>
		</div>
	);
};

export default UserInfoDetailsPage;
