import React, { Fragment, useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import axios from "axios";

import { useRecoilValue } from "recoil";

import favoriteNumberStore from "../../../../store/favorite-number.store";

import NumberFormat from "react-number-format";

import toast from "react-hot-toast";

import Header from "../../../components/Header";
import ContentList from "../../../components/ContentList";

import { LandscapeLoadingCard } from "../../../components/loading/LoadingCard";
import loadingCardLength from "../../../components/loading/loadingCardLength";

import EmptyIcon from "../../../components/icons/EmptyIcon";

import DestinationInput from "../../../components/DestinationInput";

import InquiryPrepaidDetails from "./InquiryPrepaidDetails";

import PinTrxModalValidation from "../../profile/PinTrxModalValidation";

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

const PulsaPage: React.FC = (props: any): JSX.Element => {
	if (!props.location.state) window.location.replace("/dashboard");

	const { name, productType, inputMenu } = props.location.state;

	const menuIcon: string | null = localStorage.getItem("menu_icon");

	const favoriteNumberState = useRecoilValue(favoriteNumberStore);

	const [destinationState, setDestinationState] = useState<number>(0);

	interface PulsaDenomListSchemaType {
		_id: string;
		nama: string;
		bebas_nominal: boolean;
		description: string;
		harga_jual: number;
		harga_promo: number;
		kode_produk: string;
		poin: number;
	}

	const pulsaDenomListSchema = [
		{
			_id: "",
			nama: "",
			bebas_nominal: false,
			description: "",
			harga_jual: 0,
			harga_promo: 0,
			kode_produk: "",
			poin: 0,
		},
	];

	const [pulsaDenomListState, setPulsaDenomListState] = useState<Array<PulsaDenomListSchemaType>>(pulsaDenomListSchema);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

	const [productNotFoundState, setProductNotFoundState] = useState<string>("");


	function handleChangeDestination(e: any): void {
		e.preventDefault();
		const destinationValue: number = e.target.value;

		setDestinationState(destinationValue);
	};

	function handlePasteDestination(e: any): void {
		e.preventDefault();
		const destinationValue = e.clipboardData.getData('Text')

		setDestinationState(destinationValue);
	};

	async function getPulsaDenomList(destinationValue: number, setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			setLoading(true);
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/product/pulsa?q=${destinationValue}`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log('PULSA DENOM LIST', result)

				setLoading(false);
				if (result.status === 200) {
					return resolve(result);
				} else {
					return reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	const showProductListByMinDestination = (): void => {
		if (destinationState.toString().length >= 5) {
			if (pulsaDenomListState.length <= 1) {
				getPulsaDenomList(destinationState, setIsLoadingState).then((result: any): void => {
					setProductNotFoundState("");
					const statusCode: number = result.data.status;
					const data: any = result.data.data;
					if (statusCode === 200) setPulsaDenomListState(data);
				}).catch((e: any): void => {
					const message: string = e.data.message;
					setProductNotFoundState(message);
				});
			}
		}
	}

	function setDestinationByFavoriteNumber() {
		if (favoriteNumberState) setDestinationState(favoriteNumberState);
	}

	const showProductListByFavoriteNumber = () => {
		if (favoriteNumberState) {
			getPulsaDenomList(favoriteNumberState, setIsLoadingState).then((result: any): void => {
				setProductNotFoundState("");
				const statusCode: number = result.data.status;
				const data: any = result.data.data;
				if (statusCode === 200) {
					setPulsaDenomListState(data);
				}
			}).catch((e: any): void => {
				const message: string = e.data.message;
				setProductNotFoundState(message);
			});
		}
	}

	const resetProductList = (): void => {
		if (destinationState.toString().length <= 5) setPulsaDenomListState([]);
	}

	useEffect((): void => {
		showProductListByMinDestination();
		setDestinationByFavoriteNumber();
		showProductListByFavoriteNumber();
		resetProductList();
	}, [favoriteNumberState, destinationState]);

	async function handleClickpulsa(nominalFree: boolean, productCode: string, productType: string, sellingPrice: number, destination: number): Promise<void> {
		const nominalInputModal = async (): Promise<void> => {
			const { value: swalNominal } = await Swal.fire<Array<string>>({
				title: "Masukan Nominal",
				padding: "1rem 0rem",
				heightAuto: false,
				allowOutsideClick: false,
				confirmButtonText: "Lanjutkan",
				showCancelButton: true,
				cancelButtonText: "Batal",
				customClass: {
					title: "font-inter font-semibold text-lg lg:text-2xl text-gray-800",
				},
				html: (
					<div>
						<span className="text-sm lg:text-base font-medium font-inter">Silahkan Masukan Nominal Terlebih Dahulu.</span>
						<input type="number" id="swal-input1" className="swal2-input text-center" pattern="[0-9]*" autoFocus />
					</div>
				),
				focusConfirm: true,
				preConfirm: (): Array<string> => {
					return [
						(document.getElementById("swal-input1") as HTMLInputElement).value,
					];
				},
			});

			if (swalNominal) {
				const swalNominalInput = swalNominal[0]
				if (!swalNominalInput) {
					toast.error("Maaf Anda Harus Memasukan Nominal", {
						style: { fontWeight: "bolder" },
					});
					nominalInputModal();
					return;
				}

				await Swal.fire<Array<string>>({
					title: "Konfirmasi Pembelian",
					padding: "1rem 0rem",
					showConfirmButton: false,
					focusConfirm: true,
					heightAuto: false,
					allowOutsideClick: false,
					customClass: {
						title: "font-inter font-semibold text-lg lg:text-2xl text-gray-800",
					},
					html: (
						<InquiryPrepaidDetails
							nominal={parseInt(swalNominalInput)}
							purchaseConfirm={(counter: number, nominal: number = 0) => PinTrxModalValidation("prepaid", "", productCode, destination, counter, nominal)}
							productCode={productCode}
							productType={productType}
							sellingPrice={sellingPrice}
							destination={destination}
						/>
					),
				});
			}
		};

		if (!nominalFree) {
			await Swal.fire<Array<string>>({
				title: "Konfirmasi Pembelian",
				padding: "1rem 0rem",
				showConfirmButton: false,
				focusConfirm: true,
				heightAuto: false,
				allowOutsideClick: false,
				customClass: {
					title: "font-inter font-semibold text-lg lg:text-2xl text-gray-800",
				},
				html: (
					<InquiryPrepaidDetails
						purchaseConfirm={(counter: number, nominal: number = 0) => PinTrxModalValidation("prepaid", "", productCode, destination, counter, nominal)}
						productCode={productCode}
						productType={productType}
						sellingPrice={sellingPrice}
						destination={destination}
					/>
				),
			});
			return;
		}

		nominalInputModal();
	};

	const ScrollToTopOnMount = (): null => {
		useEffect((): void => {
			window.scrollTo(0, 0);
		}, []);

		return null;
	};

	return (
		<div>
			<Header title={`Topup ${name}`} />

			<ScrollToTopOnMount />

			<div className="mb-12">
				<DestinationInput
					type={`${inputMenu}`}
					onChange={(e: HTMLInputElement) => handleChangeDestination(e)}
					onPaste={(e: HTMLInputElement) => handlePasteDestination(e)}
					value={destinationState}
					productType={productType}
				/>
			</div>

			{isLoadingState ? (
				<div className="flex flex-col gap-4">
					{loadingCardLength.map(((loading, index): JSX.Element => (
						<Fragment key={index}>
							<LandscapeLoadingCard line={3} />
						</Fragment>
					)))}
				</div>
			) : productNotFoundState ? (
				<div className="mt-16 2xl:mt-40">
					<EmptyIcon title="Produk Yang Anda Cari Tidak Ditemukan" />
				</div>
			) : (
				destinationState.toString().length > 5 && pulsaDenomListState.map(({
					bebas_nominal: nominalFree,
					description,
					harga_jual: sellingPrice,
					harga_promo: promoPrice,
					kode_produk: productCode,
					nama: name,
					_id: id,
				}: {
					bebas_nominal: boolean;
					description: string;
					harga_jual: number;
					harga_promo: number;
					kode_produk: string;
					nama: string;
					_id: string;
				}, index): JSX.Element => (
					<Fragment key={index}>
						<ContentList
							onClick={(): any => handleClickpulsa(nominalFree, productCode, productType, sellingPrice, destinationState)}
							line={3}
							icon={<img className="w-9 h-9 sm:w-14 sm:h-14 rounded-sm" src={`${menuIcon}`} alt="icon" />}
							firstLine={<span className="mb-1 text-sm sm:text-base font-medium tracking-wide">{name}</span>}
							secondLine={<span className="text-xs sm:text-sm font-base tracking-wide">{description}</span>}
							thirdLine={
								<Fragment>
									{promoPrice ? (
										<Fragment>
											<div>
												<NumberFormat
													className="text-red-500 text-xs sm:text-base font-medium tracking-wide line-through mb-2"
													value={sellingPrice}
													displayType={"text"}
													thousandSeparator={"."}
													decimalSeparator={","}
													prefix={"Rp. "}
												/>
											</div>
											<div>
												<NumberFormat
													className="text-xs sm:text-base font-medium tracking-wide"
													value={sellingPrice - promoPrice}
													displayType={"text"}
													thousandSeparator={"."}
													decimalSeparator={","}
													prefix={"Rp. "}
												/>
											</div>
										</Fragment>
									) : (
										<NumberFormat
											className="text-xs sm:text-base font-medium tracking-wide"
											value={sellingPrice}
											displayType={"text"}
											thousandSeparator={"."}
											decimalSeparator={","}
											prefix={"Rp. "}
										/>
									)}
								</Fragment>
							}
						/>
					</Fragment>
				)))}
		</div>
	);
};

export default withRouter(PulsaPage);
