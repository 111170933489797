import React, { Fragment } from "react";

import { useRecoilValue, useRecoilState } from "recoil";
import userInfoStore from "../../../store/userInfo";
import sidebarStore from "../../../store/sidebar.store";

import SidebarMax from "./SidebarMax";
import SidebarMin from "./SidebarMin";

const SidebarMenu: React.FC = (): JSX.Element => {
  const userInformation = useRecoilValue(userInfoStore);

  const { nama: name, saldo: ballance } = userInformation;

  const [sidebarInterface, setSidebarInterface] = useRecoilState<boolean>(sidebarStore);

  return (
    <Fragment>
      {sidebarInterface ? (
        <Fragment>
          <div className="hidden lg:block">
            <SidebarMax name={name} bellance={ballance} />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {/* Mobile Sidebar */}
          <div onClick={(): void => setSidebarInterface(true)} className="block lg:hidden animate__animated animate__fadeIn min-h-screen fixed z-40 w-full bg-gray-800 bg-opacity-70 lg:bg-opacity-0"></div>
          <div className="block lg:hidden fixed z-50" onClick={(): void => setSidebarInterface(true)}>
            <SidebarMax name={name} bellance={ballance} />
          </div>

          {/* Desktop Sidebar */}
          <SidebarMin />
        </Fragment>
      )}
    </Fragment>
  );
};

export default SidebarMenu;
