import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import axios from "axios";

import { useRecoilValue } from "recoil";
import favoriteNumberStore from "../../../../store/favorite-number.store";
import themeAppStore from "../../../../store/theme-app.store";

import toast from "react-hot-toast";

import DestinationInput from "../../../components/DestinationInput";

import PinTrxModalValidation from "../../profile/PinTrxModalValidation";

import Header from "../../../components/Header";

import InquiryPostpaidDetails from "./InquiryPostpaidDetails";

import withReactContent from "sweetalert2-react-content";
import ReactSweetAlert2 from "sweetalert2";

import "./PostpaidDenom.css";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

const PostpaidDenomPage: React.FC = (props: any): JSX.Element => {
	if (!props.location.state) window.location.replace("/dashboard");

	const { name, productCode, productType, inputMenu } = props.location.state;

	const favoriteNumberState = useRecoilValue(favoriteNumberStore);

	const [destinationState, setDestinationState] = useState<number>(0);

	useEffect((): void => {
		if (favoriteNumberState) setDestinationState(favoriteNumberState);
	}, [favoriteNumberState]);

	const [isDisableButtonState, setIsDisableButton] = useState<boolean>(false);

	async function getInquiryPostpaid(productCode: string, destination: any, setDisableButton: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			setDisableButton(true);

			const payload = {
				kode_produk: productCode.toUpperCase(),
				tujuan: destination,
			};

			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/trx/postpaid/inquiry`,
				method: "POST",
				data: JSON.stringify(payload),
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log('[INQUIRY POSTPAID]', result)

				if (result.status === 200) {
					setDisableButton(false);
					return resolve(result);
				} else {
					setDisableButton(false);
					return reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	const handleSubmitDestinationCheck = (e: any): void => {
		e.preventDefault();
		if (destinationState === 0) {
			toast.error("Nomor Pelanggan Tidak Boleh Kosong!", {
				duration: 4000,
				style: { fontWeight: "bolder" },
			});
			return;
		}

		toast.promise(getInquiryPostpaid(productCode, destinationState, setIsDisableButton), {
			loading: "Mengecek Nomor Pelanggan",
			success: (result: any): string => {
				const statusCode: number = result.data.status;
				const data: any = result.data.data;
				if (statusCode === 200) {
					const inquiryPostpaidModalDetails = async () => {
						const { value: swalConfirmationPurchasePostpaid } = await Swal.fire(
							{
								title: "Konfirmasi Pembelian",
								padding: "1rem 0rem",
								confirmButtonText: "Bayar",
								focusConfirm: true,
								heightAuto: false,
								showCancelButton: true,
								customClass: {
									title: "font-inter font-semibold text-lg lg:text-2xl text-gray-800",
									confirmButton: "text-sm lg:text-base font-inter",
									cancelButton: "text-sm lg:text-base font-inter",
								},
								html: <InquiryPostpaidDetails destination={destinationState} productType={productType} data={data} />,
							}
						);

						if (swalConfirmationPurchasePostpaid) PinTrxModalValidation("postpaid", data.tracking_id, data.kode_produk, destinationState, data.counter, 0);
					};

					inquiryPostpaidModalDetails();
				}

				return "Nomor Pelanggan Di Temukan";
			},
			error: (e: any) => e.data.message,
		},
			{
				style: { fontWeight: "bolder" },
			}
		);
	};

	const ScrollToTopOnMount = (): null => {
		useEffect((): void => {
			window.scrollTo(0, 0);
		}, []);

		return null;
	};

	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		btnCheck: 'bg-blue-500 lg:bg-white text-white lg:text-black',
		spinIcon: 'text-black'
	}

	if(themeState === 'dark') {
		defaultTheme['btnCheck'] = 'bg-dark-menu text-white';
		defaultTheme['spinIcon'] = 'text-white';
	}

	if (/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		defaultTheme['spinIcon'] = 'text-white';
	}

	return (
		<div>
			<Header title={`Pembayaran ${name}`} />

			<ScrollToTopOnMount />

			<div className="flex gap-4">
				<DestinationInput
					type={`${inputMenu}`}
					onChange={(e: any) => {
						const destinationValue: number = e.target.value;
						setDestinationState(destinationValue);
					}}
					value={destinationState}
					productType={productType}
				/>
				<form
					onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmitDestinationCheck(e)}>
					{isDisableButtonState ? (
						<button
							className={`${defaultTheme.btnCheck} btn-check-shadow lg:h-full absolute lg:static bottom-0 right-0 m-5 lg:m-0 inline-flex items-center px-4 py-2 font-medium tracking-wide rounded-full lg:rounded-lg focus:outline-none cursor-not-allowed z-50`}
							disabled>
							<svg
								className={`${defaultTheme.spinIcon} animate-spin -ml-1 mr-3 h-5 w-5`}
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24">
								<circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
								<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
							</svg>
							Mengecek
						</button>
					) : (
						<button
							className={`${defaultTheme.btnCheck} btn-check-shadow lg:h-full fixed lg:static bottom-0 right-0 m-4 lg:m-0 px-3 py-2 font-medium tracking-wide rounded-full lg:rounded-lg focus:outline-none z-50`}>
							Cek Tagihan
						</button>
					)}
				</form>
			</div>
		</div>
	);
};

export default withRouter(PostpaidDenomPage);
