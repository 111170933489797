import React from "react";

import { useRecoilState } from "recoil";
import themeAppStore from "../../store/theme-app.store";
import dashboardInterfaceStore from "../../store/dashboard-interface.store";

import screenfull from "screenfull";

const SettingInterface: React.FC = (props): JSX.Element => {
	const [dashboardThemeInterfaceState, setDashboardThemeInterfaceState] = useRecoilState<string | null>(themeAppStore);

	const [dashboardInterfaceState, setDashboardInterfaceState] = useRecoilState<boolean>(dashboardInterfaceStore);

	const handleClickDarkMode = (): void => {
		setDashboardThemeInterfaceState("dark");
		localStorage.setItem("theme", "dark");
	};

	const handleClickLightMode = (): void => {
		setDashboardThemeInterfaceState("");
		localStorage.setItem("theme", "");
	};

	return (
		<div>
			{dashboardInterfaceState && (
				<div
					onClick={(): void => setDashboardInterfaceState(false)}
					className="bg-gray-800 bg-opacity-90 flex items-center justify-center fixed left-0 bottom-0 w-full h-full z-50">
					<div className="bg-white grid grid-cols-3 gap-10 px-6 py-3 rounded">
						{/* RELOAD PAGE */}
						<button
							className="px-4 py-3 flex flex-col justify-center items-center focus:outline-none"
							onClick={(): void => window.location.reload()}>
							<div className="py-4 rounded">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="70"
									height="70">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z" />
								</svg>
							</div>
							<div>
								<span style={{ color: process.env.REACT_APP_PRIMARY_COLOR }}>Refresh Page</span>
							</div>
						</button>

						{/* FULL SCREEN */}
						<button
							className="px-4 py-3 flex flex-col justify-center items-center focus:outline-none"
							onClick={(): void => {
								if (screenfull.isEnabled) screenfull.toggle();
							}}>
							<div className="py-4 rounded">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="70" height="70"> <path fill="none" d="M0 0h24v24H0z" /> <path d="M20 3h2v6h-2V5h-4V3h4zM4 3h4v2H4v4H2V3h2zm16 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z" />
								</svg>
							</div>
							<div>
								<span style={{ color: process.env.REACT_APP_PRIMARY_COLOR }}>Fullscreen</span>
							</div>
						</button>

						{/* THEME MODE */}
						{dashboardThemeInterfaceState === "dark" ? (
							/* LIGHT MODE */
							<button
								onClick={handleClickLightMode}
								className="px-4 py-3 flex flex-col justify-center items-center focus:outline-none">
								<div className="py-4 rounded">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="70" height="70"> <path fill="none" d="M0 0h24v24H0z" /> <path d="M8 12h2v2H4v-2h2a6 6 0 1 1 6 6v-2a4 4 0 1 0-4-4zm-2 8h9v2H6v-2zm-4-4h8v2H2v-2zm9-15h2v3h-2V1zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3z" />
									</svg>
								</div>
								<div>
									<span style={{ color: process.env.REACT_APP_PRIMARY_COLOR }}>Light Mode</span>
								</div>
							</button>
						) : (
							/* DARK MODE */
							<button
								onClick={handleClickDarkMode}
								className="px-4 py-3 flex flex-col justify-center items-center focus:outline-none">
								<div className="py-4 rounded">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="70" height="70"> <path fill="none" d="M0 0h24v24H0z" /> <path d="M16 20.334v-2.199a7.522 7.522 0 0 0 3.623-4.281 9 9 0 0 1-10.622-8.99A7.518 7.518 0 0 0 5.151 10H3.117a9.505 9.505 0 0 1 8.538-7.963 7 7 0 0 0 10.316 8.728A9.503 9.503 0 0 1 16 20.335zM7 20h7v2H7v-2zm-3-8h6v2H4v-2zm-2 4h10v2H2v-2z" />
									</svg>
								</div>
								<div>
									<span style={{ color: process.env.REACT_APP_PRIMARY_COLOR }}>Dark Mode</span>
								</div>
							</button>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default SettingInterface;
