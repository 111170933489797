import React from "react";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../store/theme-app.store";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SearchProduct from "./SearchProduct";

import "./Header.css";

interface HeaderProps {
	title: string;
	btnRefresh?: boolean;
	inputSearch?: boolean;
	option?: boolean;
	handleSearch?: (e: any) => void;
	handleRefresh?: () => void;
}

const Header: React.FC<HeaderProps> = ({
	title,
	btnRefresh,
	inputSearch,
	option,
	children,
	handleSearch,
	handleRefresh,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		btnRefresh: 'bg-white text-white',
		icon: 'text-black'
	}

	if(themeState === 'dark') {
		defaultTheme['btnRefresh'] = 'bg-dark-menu shadow-lg';
		defaultTheme['icon'] = 'text-white';
	}

	return (
		<div className={`flex justify-between ${inputSearch ? "flex-col lg:flex-row" : ''} flex-row items-center gap-12 lg:gap-0 sm:mt-6 lg:mt-0 mb-10`}>
			<div className={`w-full lg:w-auto flex ${inputSearch ? "justify-start" : btnRefresh ? "justify-between" : children && "justify-start"} items-center gap-6 lg:gap-6`}>
				{/* BUTTON BACK */}
				<div className="flex">
					<button
						onClick={(): void => window.history.back()}
						className="block mr-8 tracking-wide focus:outline-none">
						<FontAwesomeIcon icon={faArrowLeft} className={`${defaultTheme.icon} font-black text-base sm:text-xl lg:text-xl`}></FontAwesomeIcon>
					</button>
					{/* HEADER TITLE */}
					<div>
						<span className="text-base sm:text-lg lg:text-xl font-medium tracking-wide">{title}</span>
					</div>
				</div>
			</div>

			{/* BUTTON REFRESH TEXT DESKTOP DEVICE */}
			{btnRefresh && (
				<button
					onClick={handleRefresh}
					className={`text-xs sm:text-base lg:text-base btn-refresh-shadow ${defaultTheme.btnRefresh} font-medium px-3 py-1.5 lg:px-3 lg:py-2 tracking-wide rounded-md focus:outline-none`}
					style={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}>
					Refresh
				</button>
			)}

			{/* SEARCHING DATA */}
			{inputSearch && (
				<div className="w-full lg:w-72 xl:w-80 2xl:w-96">
					<SearchProduct onChange={handleSearch} />
				</div>
			)}

			{/* OPTIONAL */}
			{option && children}
		</div>
	);
};

export default Header;
