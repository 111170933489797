import React from "react";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../store/theme-app.store";

import "./ContentList.css";

interface ContentListProps {
	line?: string | number;
	icon?: JSX.Element;
	firstLine?: string | number | JSX.Element;
	secondLine?: string | number | JSX.Element;
	thirdLine?: string | number | JSX.Element;
	fourthLine?: string | number | JSX.Element;
	optional?: boolean;
	onClick?: () => void;
}

const ContentList: React.FC<ContentListProps> = ({
	line,
	icon,
	firstLine,
	secondLine,
	thirdLine,
	fourthLine,
	optional,
	children,
	onClick,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const contentListTheme: string = themeState === "dark" ? "bg-dark-menu lg:text-gray-200" : "bg-white text-gray-800";

	return (
		<div
			onClick={onClick}
			className={`${contentListTheme} flex items-center px-4 py-3.5 lg:py-4 lg:px-6 mb-6 rounded-md lg:rounded-lg shadow hover:shadow-xl duration-500 lg:ease-out lg:gap-4 cursor-pointer lg:transition noselect`}>
			{line === 2 ? (
				<div className="w-full flex items-center gap-4 lg:my-0">
					{icon}
					<div className="w-full flex items-center flex-1 lg:flex-col lg:items-start lg:gap-3 xl:flex-row xl:items-center xl:gap-0">
						<div className="flex flex-col break-words">
							{firstLine}
							{secondLine}
						</div>
					</div>
				</div>
			) : line === 3 ? (
				<div className="w-full flex items-center gap-4">
					{icon}
					<div className="w-full flex justify-between items-center lg:flex-col lg:items-start lg:gap-3 xl:flex-row xl:items-center xl:gap-0">
						<div className="flex flex-col flex-1 break-words">
							{firstLine}
							{secondLine}
						</div>

						{thirdLine && (
							<div className="flex-1 text-right lg:text-left xl:text-right pr-3 lg:pr-4">
								{thirdLine}
							</div>
						)}

					</div>
				</div>
			) : line === 4 ? (
				<div className="w-full flex items-center gap-4">
					{icon}
					<div className="w-full flex justify-between items-center lg:flex-col lg:items-start lg:gap-3 xl:flex-row xl:items-center xl:gap-0">
						<div className="flex flex-col flex-1 break-words">
							{firstLine}
							{secondLine}
						</div>

						<div className="flex flex-col flex-1 text-right lg:text-left xl:text-right pr-3 lg:pr-4">
							{thirdLine}
							{fourthLine}
						</div>
					</div>
				</div>
			) : optional && (
				<div className="w-full flex items-center gap-1 lg:gap-4">
					{children}
				</div>
			)}
		</div>
	);
};

export default ContentList;
