import { withRouter } from "react-router-dom";

import moment from "moment";

import { BpjsHeader, DefaultHeader, PlnHeader, SpeedyHeader } from "./layouts/PrintHeader";

import { BpjsContent, DefaultContent, PlnContent, SpeedyContent } from "./layouts/PrintContent";

import './TrxPrint.css';

const idLocale = require('moment/locale/id')
moment.updateLocale('id', idLocale);

const TrxPrint: React.FC = (props: any): JSX.Element => {
  if (!props.location.state) window.location.replace("/dashboard");

  interface trxPrintDataType {
    feePpob: number;
    merchantName: string;
    productCode: string;
    productName: string;
    destination: string;
    sellingPrice: number;
    sellingAdmin: number;
    print: Array<{
      label: string;
      value: string;
    }>;
    token: string;
    sn: string;
    createdAt: string;
  }

  const { printSize, trxPrintData }: { printSize: string, trxPrintData: trxPrintDataType } = props.location.state;

  const {
    feePpob,
    print,
    merchantName,
    productCode,
    createdAt,
  } = trxPrintData;

  let printSetup: any = {
    "A4": {
      printWrapperSize: print.length ? 'w-full' : 'w-[24rem]',
      printPosition: print.length ? 'flex justify-center' : 'flex justify-start'
    },
    "80mm": {
      printWrapperSize: 'w-[20rem]',
      printPosition: 'flex justify-start'
    },
    "58mm": {
      printWrapperSize: 'w-[15rem]',
      printPosition: 'flex justify-start'
    },
  }

  const PrintHeader: React.FC = (): JSX.Element => {
    switch (productCode) {
      case 'PLNPOSTPAID':
        return <PlnHeader merchantName={merchantName} printSize={printSize} createdAt={createdAt} />
      case 'BPJS':
        return <BpjsHeader merchantName={merchantName} printSize={printSize} createdAt={createdAt} />
      case 'SPEEDY':
        return <SpeedyHeader merchantName={merchantName} printSize={printSize} createdAt={createdAt} />

      default:
        return <DefaultHeader merchantName={merchantName} feePpob={feePpob} printSize={printSize} createdAt={createdAt} />
    }
  }

  const PrintContent: React.FC = (): JSX.Element => {
    switch (productCode) {
      case 'PLNPOSTPAID':
        return <PlnContent printData={trxPrintData} printSize={printSize} />
      case 'BPJS':
        return <BpjsContent printData={trxPrintData} printSize={printSize} />
      case 'SPEEDY':
        return <SpeedyContent printData={trxPrintData} printSize={printSize} />

      default:
        return <DefaultContent printData={trxPrintData} printSize={printSize} />
    }
  }

  return (
    <div className="min-h-screen bg-white text-black overflow-hidden">
      <div className={`${printSetup[printSize].printPosition}`}>
        <div className={`${printSetup[printSize].printWrapperSize} ${printSize === 'A4' ? 'hidden sm:block' : 'block'} text-center px-2 pt-1`}>
          <PrintHeader />
          <PrintContent />
          <div className="hidden lg:block mt-12 mx-4 print:hidden">
            Tekan <kbd className="kbd kbd-sm">CTRL</kbd>/<kbd className="kbd kbd-sm">⌘</kbd> + <kbd className="kbd kbd-sm">P</kbd> untuk melakukan print.
          </div>
          <div className="flex justify-center my-3 print:hidden">
            <button onClick={() => window.print()} className="block lg:hidden text-xs lg:text-base text-white font-medium tracking-wide p-2 rounded-md focus:outline-none" style={{ background: process.env.REACT_APP_PRIMARY_COLOR }}>Aksi</button>
          </div>
        </div>
        {printSize === 'A4' && (
          <div className="flex sm:hidden justify-center items-center min-h-screen text-center print:hidden">
            <span>Silahkan gunakan mode landscape untuk melihat format print <kbd className="kbd kbd-sm bg-white">A4</kbd></span>
          </div>
        )}
      </div>
    </div >
  );
}

export default withRouter(TrxPrint);