import React, { Fragment, useCallback, useEffect, useState } from "react";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../../store/theme-app.store";

import axios from "axios";

import moment from "moment";

import loadingCardLength from "../../components/loading/loadingCardLength";

import { LandscapeLoadingCard } from "../../components/loading/LoadingCard";

import toast from "react-hot-toast";

import Header from "../../components/Header";

import ContentList from "../../components/ContentList";

import EmptyIcon from "../../components/icons/EmptyIcon";

import MoreDataList from "../../components/MoreDataList";

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

const idLocale = require('moment/locale/id')
moment.updateLocale('id', idLocale);

interface TrxNotificationListState {
	created_at: string;
	kode_merchant: string;
	penerima: string;
	pengirim: string;
	pesan: string;
	status: number;
	tipe: string;
	updated_at: string;
	user_id?: string;
}

const TrxNotificationListPage: React.FC = (): JSX.Element => {
	const trxNotificationListSchema = [
		{
			created_at: "",
			kode_merchant: "",
			penerima: "",
			pengirim: "",
			pesan: "",
			status: 0,
			tipe: "",
			updated_at: "",
			user_id: "",
		},
	];

	const [trxNotificationListState, setTrxNotificationListState] = useState<Array<TrxNotificationListState>>(trxNotificationListSchema);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
	const [isPaginationLoadingState, setIsPaginationLoadingState] = useState<boolean>(false);
	const [isMaxPaginationState, setIsMaxPaginationState] = useState<boolean>(false);


	let [pageState, setPageState] = useState<number>(0);

	function trxNotificationList(allNotificationPage: number, setLoading: React.Dispatch<React.SetStateAction<boolean>>, setPaginationLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			if (allNotificationPage <= 0) setLoading(true);
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/outbox/list?page=${allNotificationPage}`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log('[NOTIFICATION ALL LIST]', result)

				if (result.status === 200) {
					setLoading(false);
					setPaginationLoading(false);
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	const trxNotificationListCallback = useCallback((allNotificationPage, setLoading): void => {
		trxNotificationList(allNotificationPage, setLoading, setIsPaginationLoadingState).then((result: any) => {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) {
				if (pageState > 0) {
					if (!data.length) {
						setIsMaxPaginationState(true);
						toast.error(`Data telah mencapai batas maximum`, {
							style: { fontWeight: "bolder" },
						});
					}

					setTrxNotificationListState([...trxNotificationListState, ...data]);
					return;
				}

				setTrxNotificationListState(data);
			}
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	}, [pageState]);

	useEffect((): void => {
		trxNotificationListCallback(pageState, setIsLoadingState);
	}, [trxNotificationListCallback, pageState]);

	const themeState = useRecoilValue<string | null>(themeAppStore);

	const secondLineTheme: string = themeState === "dark" ? "text-gray-400" : 'text-gray-800';

	return (
		<div>
			<Header title="Seluruh Riwayat Notifikasi" />

			{isLoadingState ? (
				<div className="flex flex-col gap-4">
					{loadingCardLength.slice(0, 10).map((loading, index): JSX.Element => (
						<Fragment key={index}>
							<LandscapeLoadingCard line={2} />
						</Fragment>
					))}
				</div>
			) : (
				<Fragment>
					{!trxNotificationListState.length ? (
						<div className="mt-28 lg:mt-8 2xl:mt-40">
							<EmptyIcon title="Anda Belum Memiliki Notifikasi Transaksi" />
						</div>
					) : (
						trxNotificationListState.map(({ pesan: message, created_at: createdAt }, index) => (
							<Fragment key={index}>
								<ContentList
									onClick={(): any => {
										Swal.fire({
											title: 'Detail Notifikasi Tranksaksi',
											padding: "1rem 0rem",
											showConfirmButton: false,
											showCancelButton: true,
											cancelButtonText: "Tutup",
											heightAuto: false,
											customClass: {
												title: "font-inter font-semibold text-lg sm:text-2xl text-gray-800",
												cancelButton: "text-sm sm:text-base font-inter",
												confirmButton: "text-sm sm:text-base font-inter",
											},
											html: (
												<div className="flex flex-col justify-center items-center">
													<div>
														<span className="text-black text-sm sm:text-base font-dmsans">{message}</span>
													</div>
												</div>
											),
										});
									}}
									optional
								>
									<div className="w-full flex items-center gap-3 lg:gap-4">
										<div className="lg:w-16">
											<svg
												className="w-9 h-9 sm:w-16 sm:h-16 rounded-sm"
												fill={process.env.REACT_APP_PRIMARY_COLOR}
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="40"
												height="40">
												<path fill="none" d="M0 0h24v24H0z" />
												<path d="M22 20H2v-2h1v-6.969C3 6.043 7.03 2 12 2s9 4.043 9 9.031V18h1v2zM5 18h14v-6.969C19 7.148 15.866 4 12 4s-7 3.148-7 7.031V18zm4.5 3h5a2.5 2.5 0 1 1-5 0z" />
											</svg>
										</div>
										<div className="w-full flex justify-between items-center lg:flex-col lg:items-start lg:gap-3 xl:flex-row xl:items-center xl:gap-0">
											<div className="flex flex-col flex-1">
												<span className="w-[15rem] sm:w-[28rem] md:w-[38rem] lg:w-[38rem] xl:w-[40rem] 2xl:w-[50rem] mb-1 text-xs md:text-base font-medium tracking-wide truncate">{message}</span>
												<span className={`${secondLineTheme} text-xs sm:text-sm font-base tracking-wide`}>{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")} WIB</span>
											</div>
										</div>
									</div>
								</ContentList>
							</Fragment>
						))
					)}
				</Fragment>
			)}

			{isPaginationLoadingState ? (
				<div className="flex justify-center items-center">
					<span className="text-xs sm:text-base font-medium tracking-wide">
						Loading...
					</span>
				</div>
			) : (
				trxNotificationListState.length >= 10 &&
				trxNotificationListState.length &&
				!isLoadingState && (
					<Fragment>
						{!isMaxPaginationState && <MoreDataList setPage={setPageState} setPaginationLoading={setIsPaginationLoadingState} />}
					</Fragment>
				)
			)}
		</div>
	);
};

export default TrxNotificationListPage;
