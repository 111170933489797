import React from "react";

const Home: React.FC = (props): JSX.Element => {
	return (
		<div className="min-h-screen flex justify-center items-center bg-gray-200">
			<img className="w-40 md:w-52 lg:w-64 xl:w-80" src={process.env.REACT_APP_LANDSCAPE_WEB_ICON} alt="icon" />
		</div>
	);
};

export default Home;
