import { Fragment, useEffect } from "react";

import { withRouter } from "react-router-dom";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../../../store/theme-app.store";

import moment from "moment";

import toast from "react-hot-toast";

import QRCode from "react-qr-code";

import NumberFormat from "react-number-format";

import Header from "../../../components/Header";

const idLocale = require('moment/locale/id')
moment.updateLocale('id', idLocale);

const DepositListDetailsPage: React.FC = (props: any): JSX.Element => {
  if (!props.location.state) window.location.replace("/dashboard");

  const { data } = props.location.state;

  const { admin, createdAt, expiredAt, description, paymentCode, nominal, status, type, vaname } = data;

  const ScrollToTopOnMount = (): null => {
    useEffect((): void => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  };

  const themeState = useRecoilValue<string | null>(themeAppStore);

  const defaultTheme = {
    contentList: 'bg-white',
    label: 'text-gray-500',
    border: '',
  }

  if(themeState === 'dark') {
    defaultTheme['contentList'] = 'bg-[#1F283E]';
    defaultTheme['label'] = 'text-gray-400';
    defaultTheme['border'] = 'border-gray-700';
  }

  return (
    <Fragment>
      <Header title="Detail Riwayat Deposit" />

      <ScrollToTopOnMount />

      <div className={`${defaultTheme.contentList} p-3 shadow-lg rounded-md lg:rounded-lg mb-3`}>
        <div className={`${defaultTheme.border} flex justify-between p-2 text-left border-b`}>
          {/* DEPOSIT TIME */}
          <div>
            <span className="text-xs sm:text-base font-medium">{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")} WIB</span>
          </div>

          {/**
					 * TYPE 2 === SUCCESS
					 * TYPE 3 === FAILED
					 * TYPE 4 === PENDING
					 */}
          <div className="text-left">
            {status === 1 ? (
              <span className="text-xs sm:text-base font-semibold text-green-500 tracking-wide">Topup Sukses</span>
            ) : status === 2 ? (
              <span className="text-xs sm:text-base font-semibold text-red-500 tracking-wide">Topup Gagal</span>
            ) : (
              <span className="text-xs sm:text-base font-semibold text-yellow-500 tracking-wide">Menunggu Pembayaran</span>
            )}
          </div>
        </div>

        {type === 1 || type === 2 ? ("") : type === 4 || type === 5 || type === 6 ? (
          <Fragment>
            {/* PAYMENT METHODE */}
            <div className="flex flex-col justify-center items-center p-2">
              <div className="pt-1.5">
                <span className="text-sm sm:text-base">Kode Pembayaran</span>
              </div>
              <div className="p-1.5">
                <span className="text-sm sm:text-base font-medium">{paymentCode}</span>
              </div>
              <button
                className="copy p-1.5 focus:outline-none inline-flex items-center gap-2"
                data-clipboard-text={paymentCode}
                onClick={(): void => {
                  toast.success(`Text Berhasil Di Salin`, {
                    style: { fontWeight: "bolder" },
                  });
                }}>
                <svg
                  fill="gray"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
                </svg>
                <div>
                  <span className="text-xs sm:text-base">Salin Kode</span>
                </div>
              </button>
            </div>
          </Fragment>
        ) : type === 8 && (
          <Fragment>
            <div className="flex justify-center lg:text-3xl font-medium mt-8 mb-4">
              <QRCode size={200} value={paymentCode} />
            </div>
            <div className="text-base mt-1 mb-4 text-center">
              <span>Scan QRCode Ini Untuk Melakukan Pembayaran</span>
            </div>
          </Fragment>
        )}
        {/* DEPOSIT INFORMATION */}
        <div className="flex items-start flex-col px-2 py-1 lg:p-2 text-left">
          <div className="text-left">
            <span className="text-sm sm:text-base font-medium">Informasi Deposit</span>
          </div>
        </div>
        {/* PAYMENT METHOD */}
        <div className="flex items-start flex-col px-2 py-1 lg:p-2 text-left">
          <div>
            <span className={`${defaultTheme.label} text-xs sm:text-base`}>Metode Pembayaran</span>
          </div>
          <div className="text-left">
            <span className="text-xs sm:text-base font-medium">
              {type === 1
                ? "Bank Transfer"
                : type === 2
                  ? "Ewallet Transfer"
                  : type === 4
                    ? "Agent Indomart"
                    : type === 6
                      ? "Agent Alfamart"
                      : type === 5
                        ? `Agen ${vaname}`
                        : type === 8
                          ? "Qris"
                          : "-"}
            </span>
          </div>
        </div>
        {/* DEPOSIT NOMINAL */}
        <div className="flex items-start flex-col px-2 py-1 lg:p-2 text-left">
          <div>
            <span className={`${defaultTheme.label} text-xs sm:text-base`}>Nominal</span>
          </div>
          <div className="flex gap-2">
            <NumberFormat
              value={nominal}
              className="text-xs sm:text-base font-medium"
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"Rp "}
            />
          </div>
        </div>
        {/* ADMIN PRICE */}
        <div className="flex items-start flex-col px-2 py-1 lg:p-2 text-left">
          <div>
            <span className={`${defaultTheme.label} text-xs sm:text-base`}>Admin</span>
          </div>
          <div className="flex gap-2">
            <NumberFormat
              value={admin}
              className="text-xs sm:text-base font-medium"
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"Rp "}
            />
          </div>
        </div>
        {/* TOTAL PRICE */}
        <div className="flex items-start flex-col px-2 py-1 lg:p-2 text-left">
          <div>
            <span className={`${defaultTheme.label} text-xs sm:text-base`}>Total Bayar</span>
          </div>
          <div className="flex gap-2">
            <NumberFormat
              value={nominal + admin}
              className="text-xs sm:text-base font-medium"
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"Rp "}
            />
          </div>
        </div>
        {/* DEPOSIT DESCRIPTION */}
        {type === 1 || type === 2 ? ("") : (
          <div className="flex items-start flex-col px-2 py-1 lg:p-2 text-left">
            <div className="mr-3">
              <span className={`${defaultTheme.label} text-xs sm:text-base`}>Keterangan</span>
            </div>
            <div className="text-left">
              <span className="text-xs sm:text-base font-medium">{description}</span>
            </div>
          </div>
        )}
        {/* EXPIRED TIME */}
        <div className="flex items-start flex-col px-2 py-1 lg:p-2 text-left">
          <div>
            <span className={`${defaultTheme.label} text-xs sm:text-base`}>Waktu Kadaluarsa</span>
          </div>
          <div className="text-left">
            <span className="text-xs sm:text-base font-medium">{moment.utc(expiredAt).format("DD MMMM YYYY HH:mm:ss")} WIB</span>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default withRouter(DepositListDetailsPage);