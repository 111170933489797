import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../../../store/theme-app.store";
import userInfoStore from "../../../../store/userInfo";

import moment from "moment";

import toast from "react-hot-toast";

import loadingCardLength from "../../../components/loading/loadingCardLength";
import { LandscapeLoadingCard } from "../../../components/loading/LoadingCard";

import NumberFormat from "react-number-format";

import Header from "../../../components/Header";

import ContentList from "../../../components/ContentList";

import EmptyIcon from "../../../components/icons/EmptyIcon";

import MoreDataList from "../../../components/MoreDataList";

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

const idLocale = require('moment/locale/id')
moment.updateLocale('id', idLocale);

interface CommitionModalExchangeProps {
	commition: number;
}

const CommitionModalExchange: React.FC<CommitionModalExchangeProps> = ({
	commition,
}): JSX.Element => {
	return (
		<div>
			<span className="text-sm sm:text-base font-medium font-inter">Tukar Komisi Menjadi Saldo Aktif</span>
			<div className="w-full flex justify-center">
				<input
					type="number"
					onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
						if (!/[0-9]/.test(e.key)) e.preventDefault();
					}}
					id="swal-input1"
					className="swal2-input text-base sm:text-lg font-medium font-inter text-center"
					defaultValue={commition}
					pattern="[0-9]*"
					autoFocus
				/>
			</div>
		</div>
	);
};

const CommitionListPage: React.FC = (props): JSX.Element => {
	const userInformationState = useRecoilValue(userInfoStore);

	const { komisi: commition } = userInformationState;

	interface CommmitionListSchemaType {
		created_at: string;
		jumlah: number;
		keterangan: string;
		kode_merchant: string;
		ref_id?: string;
		type: number;
		user_id?: string;
		_id: string;
	}

	const commitionListSchema = [
		{
			created_at: "",
			jumlah: 0,
			keterangan: "",
			kode_merchant: "",
			ref_id: "",
			type: 0,
			user_id: "",
			_id: "",
		},
	];

	const [commitionListState, setCommitionListState] = useState<Array<CommmitionListSchemaType>>(commitionListSchema);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
	const [isPaginationLoadingState, setIsPaginationLoadingState] = useState<boolean>(false);
	const [isMaxPaginationState, setIsMaxPaginationState] = useState<boolean>(false);

	let [pageState, setPageState] = useState<number>(0);

	function getCommitionList(commitionPage: number, setLoading: React.Dispatch<React.SetStateAction<boolean>>, setPaginationLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			if (commitionPage <= 0) setLoading(true);

			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/komisi/list?page=${commitionPage}`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log('[COMITION LIST]', result)

				if (result.status === 200) {
					setLoading(false);
					setPaginationLoading(false);

					resolve(result);
				} else {
					reject(result);
				}
			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		getCommitionList(pageState, setIsLoadingState, setIsPaginationLoadingState).then((result: any): void => {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) {
				if (pageState > 0) {
					if (!data.length) {
						setIsMaxPaginationState(true);
						toast.error(`Data telah mencapai batas maximum`, {
							style: {
								fontWeight: "bolder",
							},
						});
					}
					setCommitionListState([...commitionListState, ...data]);
					return;
				}

				setCommitionListState(data);
			}
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	}, [pageState]);

	function comitionExchange(comitionLength: number): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/komisi/tukar`,
				method: "POST",
				data: JSON.stringify({
					jumlah: comitionLength,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status: any): boolean => true,
			}).then((result: any): void => {
				// console.log('[COMITION EXCHAGE]', result)

				if (result.status === 200) {
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	async function handleClickCommitionExchange(commition: number) {
		if (!commition) {
			toast.error(`Maaf anda belum memiliki komisi`, {
				style: {
					fontWeight: "bolder",
				},
			});
			return;
		}

		const { value: swalComitionExchange } = await Swal.fire<Array<string>>({
			title: "Tukar Komisi",
			padding: "1rem 0rem",
			showConfirmButton: true,
			confirmButtonText: "Tukarkan",
			showCancelButton: true,
			cancelButtonText: "Kembali",
			heightAuto: false,
			customClass: {
				title: "font-inter font-semibold text-lg sm:text-2xl text-gray-800",
				cancelButton: "text-sm sm:text-base font-inter",
				confirmButton: "text-sm sm:text-base font-inter",
			},
			html: <CommitionModalExchange commition={commition} />,
			focusConfirm: false,
			preConfirm: (): Array<string> => [
				(document.getElementById("swal-input1") as HTMLInputElement).value,
			],
		});

		if (swalComitionExchange) {
			const swalComitionExchangeValue = swalComitionExchange[0];
			toast.promise(comitionExchange(parseInt(swalComitionExchangeValue)), {
				loading: "Loading...",
				success: (result: any): string => result.data.message,
				error: (e: any): string => e.data.message,
			},
				{
					style: {
						fontWeight: "bolder",
					},
				}
			);
		}
	};

	const themeState = useRecoilValue<string | null>(themeAppStore);

	const btnComitionExchangeTheme: string = themeState === "dark" ? "bg-dark-menu shadow-lg" : "text-white";

	return (
		<div>
			<Header title="Riwayat Komisi" />

			<div className="w-full bg-white lg:bg-opacity-0 fixed z-30 bottom-0 left-0 lg:static border-t lg:border-none flex justify-between items-center lg:mb-6 lg:-mt-3 px-3 py-2">
				<div className="block lg:flex items-center">
					<div>
						<span className="text-sm lg:text-base text-gray-500 font-medium tracking-wide mr-3">Total Komisi</span>
					</div>
					<NumberFormat
						value={commition}
						className="text-sm sm:text-base text-green-500 font-medium tracking-wide"
						displayType={"text"}
						thousandSeparator={"."}
						decimalSeparator={","}
						prefix={"Rp "}
					/>
				</div>
				<button
					onClick={() => handleClickCommitionExchange(commition)}
					className={`text-xs sm:text-base -mt-16 lg:-mt-0 mr-4 lg:mr-0 ${btnComitionExchangeTheme} font-medium px-4 py-3 lg:px-3 lg:py-2 tracking-wide rounded-md focus:outline-none`}
					style={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}>
					Tukar Komisi
				</button>
			</div>

			{isLoadingState ? (
				<div className="flex flex-col gap-4">
					{loadingCardLength.slice(0, 10).map((loading, index): JSX.Element => (
						<Fragment key={index}>
							<LandscapeLoadingCard line={4} />
						</Fragment>
					))}
				</div>
			) : (
				<Fragment>
					{!commitionListState.length ? (
						<div className="mt-24 lg:mt-8 2xl:mt-40">
							<EmptyIcon title="Anda Belum Memiliki Riwayat Komisi" />
						</div>
					) : (
						commitionListState.map(({ created_at: createdAt, jumlah: length, keterangan: description }, index): JSX.Element => (
							<Fragment key={index}>
								<ContentList
									icon={
										<Fragment>
											<div className="lg:w-16">
												{length < 0 ? (
													<svg
														fill="#EF4449"
														className="w-9 h-9 sm:w-14 sm:h-14 rounded-sm"
														viewBox="0 0 24 24"
														width="40"
														height="40">
														<path fill="none" d="M0 0h24v24H0z" />
														<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-3.5-8v2H11v2h2v-2h1a2.5 2.5 0 1 0 0-5h-4a.5.5 0 1 1 0-1h5.5V8H13V6h-2v2h-1a2.5 2.5 0 0 0 0 5h4a.5.5 0 1 1 0 1H8.5z" />
													</svg>
												) : (
													<svg
														fill="#43BA81"
														className="w-9 h-9 sm:w-14 sm:h-14 rounded-sm"
														viewBox="0 0 24 24"
														width="40"
														height="40">
														<path fill="none" d="M0 0h24v24H0z" />
														<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-3.5-8v2H11v2h2v-2h1a2.5 2.5 0 1 0 0-5h-4a.5.5 0 1 1 0-1h5.5V8H13V6h-2v2h-1a2.5 2.5 0 0 0 0 5h4a.5.5 0 1 1 0 1H8.5z" />
													</svg>
												)}
											</div>
										</Fragment>
									}
									line={3}
									firstLine={<span className="mb-1 text-sm sm:text-base font-medium tracking-wide">{description}</span>}
									secondLine={<span className="text-xs sm:text-sm font-base tracking-wide text-gray-600">{moment(createdAt).format("DD MMMM YYYY HH:mm:ss")} WIB</span>}
									thirdLine={
										<Fragment>
											{length < 0 ? (
												<NumberFormat
													value={length}
													className="mb-1 text-xs sm:text-lg text-red-500 font-medium tracking-wide"
													displayType={"text"}
													thousandSeparator={"."}
													decimalSeparator={","}
													prefix={"Rp "}
												/>
											) : (
												<NumberFormat
													value={length}
													className="mb-1 text-xs sm:text-lg text-green-500 font-medium tracking-wide"
													displayType={"text"}
													thousandSeparator={"."}
													decimalSeparator={","}
													prefix={"Rp "}
												/>
											)}
										</Fragment>
									}
								/>
							</Fragment>
						)
						)
					)}
				</Fragment>
			)}

			{isPaginationLoadingState ? (
				<div className="flex justify-center items-center">
					<span className="text-xs sm:text-base font-medium tracking-wide">Loading...</span>
				</div>
			) : (
				commitionListState.length >= 10 &&
				!isLoadingState && (
					<Fragment>
						{!isMaxPaginationState && <MoreDataList setPage={setPageState} setPaginationLoading={setIsPaginationLoadingState} />}
					</Fragment>
				)
			)}
		</div>
	);
};

export default CommitionListPage;
