import axios from "axios";

import { selector } from "recoil";

const getMerchantInfoStore = selector<MerchantInfoType>({
	key: "getMerchantInfoStore",
	get: async (): Promise<any> => {
		const result: any = await axios({
			url: `${process.env.REACT_APP_API_ENDPOINT_URL}/app/info?id=${process.env.REACT_APP_API_DATA_MERCHANT_CODE}`,
			method: "GET",
		});
		if (result.status === 200) {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) return data;
		}
	},
});

export default getMerchantInfoStore;
