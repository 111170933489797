import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../store/theme-app.store";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faTty } from "@fortawesome/free-solid-svg-icons";

import ModalFavoriteNumber from "./ModalFavoriteNumber";

import "./DestinationInput.css";

interface DestinationInputProps {
	type: string;
	onChange: (e: any) => void;
	onPaste?: (e: any) => void;
	value: number;
	productType: string;
}

const DestinationInput: React.FC<DestinationInputProps> = ({
	type,
	onChange,
	onPaste,
	value,
	productType,
}): JSX.Element => {
	useEffect((): void => {
		if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) type = "text";
	}, []);

	const history = useHistory();

	const themeState = useRecoilValue(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white',
		input: 'bg-white text-gray-800',
		icon: 'lg:text-gray-500'
	}

	if(themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['icon'] = 'lg:text-gray-100'
	}

	return (
		<div className="w-full lg:w-full xl:w-2/4">
			<div
				className={`${defaultTheme.inputWrapper} py-0 sm:py-1 lg:py-0 flex destination-input-shadow rounded-lg`}>
				{/* PHONE ICON */}
				<div className="flex justify-center items-center pr-3 pl-3 sm:pl-5">
					<FontAwesomeIcon icon={faTty} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl`}></FontAwesomeIcon>
				</div>
				{/* DESTINATION INPUT NUMBER */}
				<input
					type={type}
					onChange={onChange}
					onPaste={onPaste}
					onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
						if (type === "Number") if (!/[0-9]/.test(e.key)) e.preventDefault();
					}}
					className={`${defaultTheme.input} w-full px-1 py-2.5 text-sm sm:text-lg lg:text-base font-medium tracking-wider focus:outline-none`}
					value={value ? value : ""}
					placeholder="Nomor Tujuan"
					autoFocus
				/>
				{/* SYNC ICON */}
				<div className="flex justify-center items-center pt-3 pb-2.5 pl-3 pr-3 sm:pr-5 rounded-r-md lg:rounded-r">
					{/* SMALL SCREEN */}
					<button
						onClick={() => {
							history.push({
								pathname: `/dashboard/trx/favorite-number`,
								state: { productType },
							});
						}}
						className="block lg:hidden">
						<FontAwesomeIcon icon={faAddressBook} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl lg:text-2xl`}></FontAwesomeIcon>
					</button>

					{/* EXTRA LARGE SCREEN */}
					<label
						htmlFor="my-modal-2"
						title="Nomor Favorit"
						className="hidden lg:block cursor-pointer">
						<FontAwesomeIcon icon={faAddressBook} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl lg:text-2xl`}></FontAwesomeIcon>
					</label>
					<input type="checkbox" id="my-modal-2" className="modal-toggle" />
					<div className="hidden lg:block modal">
						<div className="flex flex-col items-center w-full mt-6 sm:py-4 2xl:py-8 fixed overflow-auto top-0 bottom-0">
							<div className="modal-box rounded-lg">
								<div className="flex justify-end mb-3">
									<label
										htmlFor="my-modal-2"
										className="p-1.5 cursor-pointer">
										<svg
											className="w-6 h-6"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
										</svg>
									</label>
								</div>
								<div className="flex justify-center mb-6">
									<span className="font-medium text-xl text-gray-800">Nomor Favorit</span>
								</div>
								<ModalFavoriteNumber productType={productType} />
								<div className="modal-action justify-center">
									<label htmlFor="my-modal-2" className="btn">Close</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DestinationInput;
