import React, { Fragment, useState } from "react";

import { useHistory } from "react-router-dom";

import axios from "axios";

import toast from "react-hot-toast";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoginHeader: React.FC = (): JSX.Element => {
	return (
		<div className="w-full mb-3">
			{/* TITLE */}
			<h1 className="font-semibold text-white text-2xl mb-1.5">{process.env.REACT_APP_LOGIN_TITLE}</h1>
			<div>
				{/* DESCRIPTION */}
				<span className="text-white text-base sm:text-lg lg:text-base">{process.env.REACT_APP_LOGIN_DESCRIPTION}</span>
			</div>
		</div>
	);
};

interface LoginInputProps<T> {
	phone: T;
	setPhone: React.Dispatch<React.SetStateAction<T>>;
	pin: T;
	setPin: React.Dispatch<React.SetStateAction<T>>;
	showPin: boolean;
	setShowPin: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginInput: React.FC<LoginInputProps<number | string>> = ({
	phone,
	setPhone,
	pin,
	setPin,
	showPin,
	setShowPin,
}): JSX.Element => {
	function handleChangePin(e: React.ChangeEvent<HTMLInputElement>): void {
		const re: RegExp = /^[0-9\b]+$/;
		if (e.target.value === "" || re.test(e.target.value)) setPin(e.target.value);
	};

	function handleChangePhone(e: React.ChangeEvent<HTMLInputElement>): void {
		const re: RegExp = /^[0-9\b]+$/;
		if (e.target.value === "" || re.test(e.target.value)) setPhone(e.target.value);
	};

	function handleClickShowPin(e: React.MouseEvent<HTMLDivElement, MouseEvent>, showPin: boolean): void {
		e.preventDefault();

		if (!showPin) {
			setShowPin(false);
			const inputPin: Element | null = document.querySelector("#number-pin");
			inputPin!.classList.add("password");
		} else {
			setShowPin(true);
			const inputPin: Element | null = document.querySelector("#number-pin");
			if (inputPin!.classList.contains("password")) inputPin!.classList.remove("password");
		}
	};

	let inputType: string = "text";

	return (
		<div className="w-full mb-4">
			{/* NUMBER PHONE INPUT */}
			<label htmlFor="number-phone" className="text-gray-400 text-[11.2px] sm:text-sm lg:text-[11.2px] font-bold uppercase">Nomor HP</label>
			<div className="w-full flex border border-gray-800 mt-0.5 mb-5 lg:mb-3 rounded-sm" style={{ backgroundColor: "#2e3744" }}>
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangePhone(e)}
					type={inputType}
					className="w-full px-4 py-2.5 text-sm sm:text-base lg:text-base text-white font-medium focus:outline-none tracking-widest rounded-sm"
					id="number-phone"
					style={{ backgroundColor: "#2e3744" }}
					value={phone ? phone : ""}
					maxLength={parseInt(process.env.REACT_APP_LOGIN_CONTACT_LENGTH ?? "")}
					autoFocus
					autoComplete="off"
				/>
			</div>

			{/* NUMBER PIN INPUT */}
			<label htmlFor="number-pin" className="text-gray-400 text-[11.2px] sm:text-sm lg:text-[11.2px] font-bold uppercase">PIN</label>
			<div className="w-full flex border border-gray-800 mt-0.5 rounded-sm" style={{ backgroundColor: "#2e3744" }}>
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChangePin(e)}
					type={inputType}
					id="number-pin"
					className="password w-full px-4 py-2.5 text-sm sm:text-base lg:text-base text-white font-medium focus:outline-none tracking-wider rounded-sm"
					style={{ backgroundColor: "#2e3744" }}
					value={pin ? pin : ""}
					maxLength={parseInt(process.env.REACT_APP_LOGIN_PIN_LENGTH ?? "")}
					autoComplete="off"
				/>
				{showPin ?
					<Fragment>
						<div
							onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => handleClickShowPin(e, false)}
							className="flex justify-center items-center bg-transparent px-5 py-3 cursor-pointer focus:outline-none">
							<FontAwesomeIcon icon={faEyeSlash} className="fas fa-bars font-black text-lg text-gray-400" />
						</div>
					</Fragment>
					:
					<Fragment>
						<div
							onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => handleClickShowPin(e, true)}
							className="flex justify-center items-center bg-transparent px-5 py-3 cursor-pointer focus:outline-none">
							<FontAwesomeIcon icon={faEye} className="fas fa-bars font-black text-lg text-gray-400" />
						</div>
					</Fragment>
				}
			</div>
		</div>
	);
};

interface LoginSubmitButtonProps {
	isLoading: boolean;
}

const LoginSubmitButton: React.FC<LoginSubmitButtonProps> = ({ isLoading }: { isLoading: boolean }) => {
	return (
		<Fragment>
			{isLoading ? (
				<button
					className="w-full mt-6 lg:mt-4 py-3.5 px-20 text-base font-medium rounded-sm focus:outline-none cursor-not-allowed"
					style={{ color: "#000000", backgroundColor: "#cccccc" }}
					disabled
				>
					Loading...
				</button>
			) : (
				<button
					type="submit"
					className="w-full mt-6 lg:mt-4 py-3 px-20 text-base text-white font-medium rounded-sm focus:outline-none"
					style={process.env.REACT_APP_PRIMARY_COLOR ? { color: "#FFFFFF", backgroundColor: process.env.REACT_APP_PRIMARY_COLOR, } : { color: "#000000" }}>
					Masuk
				</button>
			)}
		</Fragment>
	);
};

const LoginContent: React.FC = (): JSX.Element => {
	return (
		<div className="hidden lg:flex flex-col justify-center items-center w-1/2 px-8 lg:px-14 py-10 lg:py-8">
			<img src={process.env.REACT_APP_LOGIN_ICON} alt="icon" className="mb-6 w-60 2xl:w-80" />
			<div className="flex justify-center mb-2 font-medium text-lg">
				<span className="text-center text-[17.2px] text-white tracking-wider">{process.env.REACT_APP_LOGIN_CONTENT_TITLE}</span>
			</div>
			<div className="flex justify-center">
				<span className="text-base text-center text-white tracking-wide">{process.env.REACT_APP_LOGIN_CONTENT_DESCRIPTION}</span>
			</div>
		</div>
	);
};

const LoginFormControll: React.FC = () => {
	const [phoneState, setPhoneState] = useState<number | string>(0);
	const [pinState, setPinState] = useState<number | string>(0);
	const [showPinState, setShowPinState] = useState<boolean>(false);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

	const history = useHistory();

	function authUser<T>(phone: T, pin: T, setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			setLoading(true);
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/login`,
				method: "POST",
				data: JSON.stringify({ phone, pin }),
				headers: {
					"Content-Type": "application/json",
					merchantcode: `${process.env.REACT_APP_API_DATA_MERCHANT_CODE}`,
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log('[AUTH USER]', result)

				if (result.status === 200) return resolve(result);

				setLoading(false);
				return reject(result);
			}).catch((error: any): void => {
				console.log("STATUS CODE", error);
			});
		});
	};

	function userAuthSubmit<T>(e: any, phone: T, pin: T): void {
		e.preventDefault();

		if (!phone) {
			toast.error("Nomor HP Tidak Boleh Kosong!", {
				style: { fontWeight: "bolder" },
			});
			return;
		}


		if (!pin) {
			toast.error("Nomor PIN Tidak Boleh Kosong!", {
				style: { fontWeight: "bolder" },
			});
			return;
		}

		toast.promise(
			authUser<T>(phone, pin, setIsLoadingState),
			{
				loading: "Loading..",
				success: (result: any): string => {
					let status: number = result.data.status;
					let data: any = result.data.data;
					if (status === 200) {
						setTimeout((): void => {
							localStorage.setItem("validate_login_id", data.validate_id);
							history.push("/auth/send-otp");
						}, 4000);
					}
					return "Silahkan verifikasi otp terlebih dahulu, tunggu sebentar..";
				},
				error: (e: any): string => {
					let data = e.data;
					return data.message;
				},
			},
			{
				style: {
					fontWeight: "bolder",
				},
			}
		);
	};

	return (
		<Fragment>
			<form
				onSubmit={(e: React.FormEvent<HTMLFormElement>) => userAuthSubmit<number | string>(e, phoneState, pinState)}
				className="w-full pl-8 pr-8 lg:pr-0 py-10 lg:py-8 2xl:pt-16 2xl:pb-8 lg:w-2/4 animate__animated animate__fadeIn flex justify-center items-center flex-col">
				<LoginHeader />
				<LoginInput phone={phoneState} setPhone={setPhoneState} pin={pinState} setPin={setPinState} showPin={showPinState} setShowPin={setShowPinState} />
				<LoginSubmitButton isLoading={isLoadingState} />
				{process.env.REACT_APP_REGISTER_URL_PLAYSTORE && (
					<div className="mt-4">
						<span className="text-white text-sm sm:text-base lg:text-sm">Tidak punya akun?</span>{" "}
						<a className="text-sm hover:underline text-blue-500"
							href={process.env.REACT_APP_REGISTER_URL_PLAYSTORE}
							target="_blank"
							rel="noopener noreferrer">
							Daftar
						</a>
					</div>
				)}
			</form>
		</Fragment>
	);
};

const LoginPage: React.FC = (): JSX.Element => {
	return (
		<Fragment>
			<div className="min-h-screen w-full px-4 lg:px-0 flex justify-center items-center">
				<div className="bg-gray-700 flex justify-between items-center w-full lg:w-2/3 my-6 lg:my-0 shadow-xl rounded-lg">
					<LoginFormControll />
					<LoginContent />
				</div>
			</div>
		</Fragment>
	);
};

export default LoginPage;
