import React, { useEffect } from "react";

import { useHistory } from "react-router";

const Guest: React.FC = ({ children }): JSX.Element => {
	const history: any = useHistory();
	const validateLoginId = localStorage.getItem("validate_login_id");
	const userToken = localStorage.getItem("user_token");

	useEffect((): void => {
		if (validateLoginId && userToken) return history.push("/dashboard");
		if (validateLoginId) return history.push("/auth/send-otp");

		return history.push("/auth/login");
	});

	return <div>{children}</div>;
};

export default Guest;
