import React from "react";

import { Link } from "react-router-dom";

import { useRecoilValue, useSetRecoilState } from "recoil";
import userInfoStore from "../../store/userInfo";
import dropdownStore from "../../store/dropdown.store";
import dashboardInterfaceStore from "../../store/dashboard-interface.store";
import themeAppStore from "../../store/theme-app.store";

import { LogoutDropdownMenu } from "./Logout";

import "./Dropdown.css";

interface DropdownProps {
	animateClass?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ animateClass }): JSX.Element => {
	const userInformationState = useRecoilValue(userInfoStore);

	const setDropdownState = useSetRecoilState<boolean>(dropdownStore);

	const { nama: name } = userInformationState;

	const setCurrentDashboardInterface = useSetRecoilState<boolean>(dashboardInterfaceStore);

	const handleClickShowDashboardInterface = (e: any): void => {
		e.preventDefault();
		setCurrentDashboardInterface(true);
		setDropdownState(false);
	};

	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		dropdown: 'bg-white text-black',
		borderDropdown: '',
	}

	if (themeState === 'dark') {
		defaultTheme['dropdown'] = 'bg-dark-dropdown lg:text-gray-200 border-gray-700';
		defaultTheme['borderDropdown'] = 'border-gray-700'
	}

	return (
		<div
			className={`${animateClass} hidden lg:block w-72 fixed z-40 right-8 top-1 lg:mt-16 mb-4 pt-0 rounded-lg`}>
			<div className="flex justify-end px-4">
				<div className="arrow-up"></div>
			</div>
			<div className={`${defaultTheme.dropdown} rounded-lg shadow-md`}>
				{/* USER ACCOUNT INFO */}
				<div
					className={`${defaultTheme.borderDropdown} flex items-center gap-3 px-2 py-2 border-b`}>
					<div className="hidden lg:block rounded-lg bg-gray-200">
						<svg className="w-10 h-10 m-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path opacity="0.4" d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z" fill="#292D32" />
							<path d="M17.08 14.1499C14.29 12.2899 9.73996 12.2899 6.92996 14.1499C5.65996 14.9999 4.95996 16.1499 4.95996 17.3799C4.95996 18.6099 5.65996 19.7499 6.91996 20.5899C8.31996 21.5299 10.16 21.9999 12 21.9999C13.84 21.9999 15.68 21.5299 17.08 20.5899C18.34 19.7399 19.04 18.5999 19.04 17.3599C19.03 16.1299 18.34 14.9899 17.08 14.1499Z" fill="#292D32" />
						</svg>
					</div>
					<div>
						<div className="mb-0.5">
							<span className="font-medium capitalize tracking-wider">{name}</span>
						</div>
						<Link
							to="/dashboard/profile/user/info"
							onClick={(): void => setDropdownState(false)}>
							<div>
								<span className="text-xs font-medium uppercase tracking-wider">Lihat Profil</span>
							</div>
						</Link>
					</div>
				</div>

				{/* SETTING DASHBOARD INTERFACE */}
				<div
					onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleClickShowDashboardInterface(e)}
					className={`${defaultTheme.borderDropdown} flex items-center gap-2 px-2 py-4 border-b cursor-pointer`}>
					<div className="px-1">
						<svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8 22.7499C4.28 22.7499 1.25 19.7199 1.25 15.9999C1.25 12.9599 3.29 10.2799 6.22 9.48989C6.59 9.38989 6.97 9.57989 7.11 9.93989C7.72 11.4699 9.02 12.6399 10.61 13.0699C11.49 13.3099 12.51 13.3099 13.37 13.0699C13.74 12.9699 14.13 13.1599 14.27 13.5199C14.58 14.3099 14.74 15.1499 14.74 16.0099C14.74 17.9299 13.92 19.7599 12.49 21.0299C11.27 22.1299 9.67 22.7499 8 22.7499ZM6.02 11.1299C4.07 11.9299 2.75 13.8499 2.75 15.9999C2.75 18.8899 5.11 21.2499 8 21.2499C9.3 21.2499 10.54 20.7699 11.5 19.8999C12.62 18.9099 13.25 17.4899 13.25 15.9999C13.25 15.5399 13.19 15.0999 13.08 14.6699C12.14 14.8199 11.12 14.7699 10.22 14.5199C8.41 14.0199 6.89 12.7799 6.02 11.1299Z" fill="currentColor" />
							<path d="M12 14.75C11.38 14.75 10.78 14.67 10.22 14.51C8.19 13.95 6.5 12.45 5.72 10.48C5.41 9.7 5.25 8.86 5.25 8C5.25 4.28 8.28 1.25 12 1.25C15.72 1.25 18.75 4.28 18.75 8C18.75 8.86 18.59 9.7 18.28 10.49C17.5 12.45 15.82 13.96 13.78 14.52C13.22 14.67 12.62 14.75 12 14.75ZM12 2.75C9.11 2.75 6.75 5.11 6.75 8C6.75 8.67 6.87 9.32 7.12 9.93C7.73 11.46 9.03 12.63 10.62 13.06C11.5 13.3 12.52 13.3 13.38 13.06C14.97 12.63 16.28 11.45 16.88 9.93C17.12 9.32 17.25 8.67 17.25 8C17.25 5.11 14.89 2.75 12 2.75Z" fill="currentColor" />
							<path d="M16 22.75C14.33 22.75 12.73 22.13 11.5 21.02C11.34 20.88 11.25 20.67 11.25 20.46C11.25 20.25 11.34 20.04 11.5 19.9C12.61 18.92 13.25 17.5 13.25 16C13.25 15.33 13.13 14.68 12.88 14.07C12.8 13.87 12.81 13.65 12.91 13.46C13.01 13.27 13.18 13.13 13.38 13.07C14.97 12.64 16.27 11.47 16.88 9.93998C17.02 9.57998 17.41 9.39998 17.77 9.48998C20.7 10.29 22.74 12.96 22.74 16C22.75 19.72 19.72 22.75 16 22.75ZM13.12 20.39C13.97 20.95 14.96 21.25 16 21.25C18.89 21.25 21.25 18.89 21.25 16C21.25 13.85 19.93 11.93 17.98 11.13C17.24 12.54 16 13.66 14.52 14.26C14.67 14.82 14.75 15.41 14.75 16C14.75 17.62 14.17 19.18 13.12 20.39Z" fill="currentColor" />
						</svg>
					</div>
					<span className="text-md font-medium capitalize tracking-wider">Tampilan</span>
				</div>

				{/* LOGOUT MENU */}
				<LogoutDropdownMenu />
			</div>
		</div>
	);
};

export default Dropdown;
