import React from "react";

import "./ContentGrid.css";

interface ContentGridProps {
	onClick?: () => void;
	bgImage?: string;
	title?: string | JSX.Element;
	optional?: boolean;
}

const ContentGrid: React.FC<ContentGridProps> = ({
	onClick,
	bgImage,
	title,
	optional,
	children,
}): JSX.Element => {
	return (
		<div>
			{optional ? (
				<div onClick={onClick}>
					{children}
				</div>
			) : (
				<div
					onClick={onClick}
					style={{
						backgroundImage: `url(${bgImage})`,
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
					className={`flex justify-center items-end h-36 sm:h-48 lg:h-36 2xl:h-48 mb-0 lg:mb-0 text-white rounded-md lg:rounded-lg content-grid-shadow hover:shadow-xl lg:gap-4 cursor-pointer lg:transition duration-500 lg:ease-out noselect`}>
					{title && (
						<div className="flex justify-center items-center gap-1 lg:gap-5 bg-gray-800 bg-opacity-60 w-full text-center rounded-b-md lg:rounded-b-lg">
							<div className="text-center px-2 py-4">
								<div className="flex flex-col">
									<span className="text-2xs leading-tight mb-1 sm:text-base font-medium tracking-wide">{title}</span>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ContentGrid;
