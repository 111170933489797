import React, { Fragment, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import axios from "axios";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../../../store/theme-app.store";

import NumberFormat from "react-number-format";

import toast from "react-hot-toast";

import Header from "../../../components/Header";

import ContentList from "../../../components/ContentList";

import loadingCardLength from "../../../components/loading/loadingCardLength";
import { LandscapeLoadingCard } from "../../../components/loading/LoadingCard";

import EmptyIcon from "../../../components/icons/EmptyIcon";

import MoreDataList from "../../../components/MoreDataList";

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

/* 
  Downline features
  */

interface DownlineShowProps {
  id: string;
  handleDownlineListShowPage: (id: string) => void;
}

export const DownlineShowMenu: React.FC<DownlineShowProps> = ({
  id,
  handleDownlineListShowPage,
}): JSX.Element => {
  return (
    <button
      onClick={(): void => {
        handleDownlineListShowPage(id);
        Swal.close();
      }}
      className="w-full mb-4 px-3 py-3 rounded-md lg:rounded-lg focus:outline-none"
      style={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}>
      <span className="text-sm sm:text-lg font-medium text-white">Lihat Downline</span>
    </button>
  );
};

interface DownlineEditMenuProps {
  id: string;
  name: string;
  markup: number;
}

export const DownlineEditMenu: React.FC<DownlineEditMenuProps> = ({
  id,
  name,
  markup,
}): JSX.Element => {
  function editMarkup(userId: string, markup: number): Promise<void> {
    return new Promise((resolve, reject): void => {
      axios({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/downline/edit-up`,
        method: "POST",
        data: JSON.stringify({
          user_id: userId,
          markup,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("user_token"),
        },
        validateStatus: (status: any): boolean => {
          return true;
        },
      }).then((result: any): void => {
        // console.log('[DOWNLINE MARKUP EDIT]', result)

        if (result.status === 200) {
          resolve(result);
        } else {
          reject(result);
        }

      }).catch((error: any): void => console.log("STATUS CODE", error));
    });
  };

  return (
    <button
      onClick={async (): Promise<void> => {
        const { value: swalDownlineMarkupEdit } = await Swal.fire({
          title: "Ubah Markup",
          padding: "1rem 0rem",
          showConfirmButton: true,
          confirmButtonText: "Ubah",
          showCancelButton: true,
          cancelButtonText: "Batal",
          heightAuto: false,
          customClass: {
            title: "font-inter font-semibold text-lg sm:text-2xl text-gray-800",
            cancelButton: "text-sm sm:text-base font-inter",
            confirmButton: "text-sm sm:text-base font-inter",
          },
          html: (
            <div>
              <span className="text-sm sm:text-lg font-medium font-inter capitalize">Ubah Markup Milik {name}</span>
              <div className="w-full flex justify-center">
                <input
                  type="number"
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (!/[0-9]/.test(e.key)) e.preventDefault();
                  }}
                  id="swal-input1"
                  className="swal2-input text-base sm:text-lg font-medium font-inter text-center"
                  defaultValue={markup}
                  pattern="[0-9]*"
                  autoFocus
                />
              </div>
            </div>
          ),
          focusConfirm: false,
          preConfirm: (): Array<string> => [
            (document.getElementById("swal-input1") as HTMLInputElement).value,
          ],
        });

        if (swalDownlineMarkupEdit) {
          const swalDownlineMarkupEditValue = swalDownlineMarkupEdit[0]
          if (!swalDownlineMarkupEditValue || swalDownlineMarkupEditValue === '0') {
            toast.error(`Markup tidak boleh kosong`, {
              style: {
                fontWeight: "bolder",
              },
            });
          } else {
            toast.promise(
              editMarkup(id, parseInt(swalDownlineMarkupEditValue)),
              {
                loading: "Loading...",
                success: (result: any): string => {
                  let message: string = result.data.message;
                  return message;
                },
                error: (e): string => {
                  let message: string = e.data.message;
                  return message;
                },
              },
              {
                style: {
                  fontWeight: "bolder",
                },
              }
            );
          }
        }
        Swal.close();
      }}
      className="w-full px-3 py-3 rounded-md lg:rounded-lg focus:outline-none"
      style={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}>
      <span className="text-sm sm:text-lg font-medium text-white">Edit Markup</span>
    </button>
  );
};

const DownlineListPage: React.FC = (props): JSX.Element => {
  interface DownlineListSchemaType {
    aktif: boolean;
    created_at: string;
    kode_merchant: string;
    kyc_verification: boolean;
    markup: number;
    nama: string;
    phone: string;
    poin: number;
    saldo: number;
    toko: {
      alamat: string;
      nama: string;
    };
    total_downline: number;
    updated_at: string;
    _id: string;
  }

  const downlineListSchema = [
    {
      aktif: false,
      created_at: "",
      kode_merchant: "",
      kyc_verification: false,
      markup: 0,
      nama: "",
      phone: "",
      poin: 0,
      saldo: 0,
      toko: {
        alamat: "",
        nama: "",
      },
      total_downline: 0,
      updated_at: "",
      _id: "",
    },
  ];

  const [downlineListState, setDownlineListState] = useState<Array<DownlineListSchemaType>>(downlineListSchema);

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [isPaginationLoadingState, setIsPaginationLoadingState] = useState<boolean>(false);
  const [isMaxPaginationState, setIsMaxPaginationState] = useState<boolean>(false);

  let [pageState, setPageState] = useState<number>(0);


  function getDownlineList(donwlinePage: number, setLoading: React.Dispatch<React.SetStateAction<boolean>>, setPaginationLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
    return new Promise((resolve, reject): void => {
      if (donwlinePage <= 0) setLoading(true);

      axios({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/downline/list?page=${donwlinePage}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("user_token"),
        },
        validateStatus: (status): boolean => {
          return true;
        },
      }).then((result: any): void => {
        // console.log('[DOWNLINE LIST]', result)

        if (result.status === 200) {
          setLoading(false);
          setPaginationLoading(false);
          resolve(result);
        } else {
          reject(result);
        }

      }).catch((error: any): void => console.log("STATUS CODE", error));
    });
  };

  useEffect(() => {
    getDownlineList(pageState, setIsLoadingState, setIsPaginationLoadingState).then((result: any): void => {
      const statusCode: number = result.data.status;
      const data: any = result.data.data;
      if (statusCode === 200) {
        if (pageState > 0) {
          if (!data.length) {
            setIsMaxPaginationState(true);
            toast.error(`Data telah mencapai batas maximum`, {
              style: {
                fontWeight: "bolder",
              },
            });
          }
          setDownlineListState([...downlineListState, ...data]);
          return;
        }

        setDownlineListState(data);
      }
    }).catch((e: any): void => {
      toast.error(e.data.message, {
        style: {
          fontWeight: "bolder",
        },
      });
      console.log(e);
    });
  }, [pageState]);

  const history: any = useHistory();
  function handleDownlineAdd(): void {
    history.push({ pathname: "/dashboard/profile/downline/add" });
  }

  function handledownlineListShowPage(id: string): void {
    history.push({ pathname: "/dashboard/profile/downline/downline/show", state: { id } });
  };

  const themeState = useRecoilValue<string | null>(themeAppStore);

  const btnAddDownlineTheme: string = themeState === "dark" ? "bg-dark-menu shadow-lg" : "bg-white text-white";

  return (
    <div>
      <Header title="Riwayat Downline" />

      {/* <div className="w-full flex justify-end mb-8"> */}
      <div className="w-full hidden mb-8">
        <button
          onClick={(): void => handleDownlineAdd()}
          className={`text-xs sm:text-base btn-refresh-shadow mr-4 lg:mr-0 ${btnAddDownlineTheme} font-medium px-4 py-3 lg:px-3 lg:py-2 tracking-wide rounded-md focus:outline-none`}
          style={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}>
          Tambah Downline
        </button>
      </div>

      {isLoadingState ? (
        <div className="flex flex-col gap-4">
          {loadingCardLength.slice(0, 10).map((loading, index) => (
            <Fragment key={index}>
              <LandscapeLoadingCard optional>
                <div className="h-20 lg:h-44 xl:h-20"></div>
              </LandscapeLoadingCard>
            </Fragment>
          ))}
        </div>
      ) : (
        <Fragment>
          {!downlineListState.length ? (
            <div className="mt-24 lg:mt-20 2xl:mt-40">
              <EmptyIcon title="Anda Belum Memiliki Downline" />
            </div>
          ) : (
            downlineListState.map(
              ({
                aktif,
                markup,
                nama: name,
                poin: point,
                saldo: balance,
                total_downline: downlineTotal,
                _id: id,
              }, index): JSX.Element => (
                <Fragment key={index}>
                  <ContentList
                    onClick={() =>
                      Swal.fire({
                        padding: "2rem 0rem",
                        showConfirmButton: false,
                        showCancelButton: false,
                        heightAuto: false,
                        width: 250,
                        html: (
                          <Fragment>
                            <DownlineShowMenu id={id} handleDownlineListShowPage={(): void => handledownlineListShowPage(id)} />
                            <DownlineEditMenu id={id} name={name} markup={markup} />
                          </Fragment>
                        ),
                      })
                    }
                    optional>
                    <div className="flex flex-row gap-1 lg:gap-4 xl:flex-row w-full">
                      <div className="flex flex-1">
                        <div className="flex flex-col">
                          {/* DOWNLINE NAME */}
                          <div className="flex">
                            <div className="mr-7 lg:mr-10">
                              <span className="text-xs sm:text-base tracking-wide">Nama</span>
                            </div>
                            <div className="mb-1">
                              <span className="text-xs sm:text-base tracking-wide capitalize">{name}</span>
                            </div>
                          </div>

                          {/* DOWNLINE TOTAL */}
                          <div className="flex">
                            <div className="mr-2 lg:mr-3.5">
                              <span className="text-xs sm:text-base tracking-wide">Downline</span>
                            </div>
                            <div className="mb-1">
                              <span className="text-xs sm:text-base tracking-wide">{downlineTotal}</span>
                            </div>
                          </div>

                          {/* DOWNLINE STATUS */}
                          <div className="flex">
                            <div className="mr-6 lg:mr-9">
                              <span className="text-xs sm:text-base tracking-wide">Status</span>
                            </div>
                            <div>
                              {aktif ? (
                                <span className="text-xs sm:text-base text-green-500 tracking-wide">Aktif</span>
                              ) : (
                                <span className="text-xs sm:text-base text-red-500 tracking-wide">Non Aktif</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-1 gap-4 lg:gap-14 xl:gap-4">
                        <div className="flex flex-col">
                          {/* DOWNLINE BALANCE */}
                          <div className="flex">
                            <div className="mr-6 lg:mr-10 xl:mr-7">
                              <span className="text-xs sm:text-base tracking-wide">Saldo</span>
                            </div>
                            <div className="mb-1">
                              <NumberFormat
                                value={balance}
                                className="text-xs sm:text-base tracking-wide"
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"Rp "}
                              />
                            </div>
                          </div>

                          {/* DOWNLINE POINT */}
                          <div className="flex">
                            <div className="mr-8 lg:mr-12 xl:mr-9">
                              <span className="text-xs sm:text-base tracking-wide">Poin</span>
                            </div>
                            <div className="mb-1">
                              <span className="text-xs sm:text-base tracking-wide">{point}</span>
                            </div>
                          </div>

                          {/* DOWNLINE MARKUP */}
                          <div className="flex">
                            <div className="mr-3 lg:mr-6 xl:mr-3">
                              <span className="text-xs sm:text-base tracking-wide">Markup</span>
                            </div>
                            <div>
                              <NumberFormat
                                value={markup}
                                className="text-xs sm:text-base tracking-wide"
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"Rp "}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ContentList>
                </Fragment>
              )
            )
          )}
        </Fragment>
      )}

      {isPaginationLoadingState ? (
        <div className="flex justify-center items-center">
          <span className="text-xs lg:text-base font-medium tracking-wide">
            Loading...
          </span>
        </div>
      ) : (
        downlineListState.length >= 10 &&
        !isLoadingState && (
          <Fragment>
            {!isMaxPaginationState && <MoreDataList
              setPage={setPageState}
              setPaginationLoading={setIsPaginationLoadingState}
            />}
          </Fragment>
        )
      )}
    </div>
  );
};

export default DownlineListPage;
