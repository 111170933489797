import { atom } from "recoil";

const submenuStore = atom<Array<SubmenuType>>({
	key: "submenuStore",
	default: [
		{
			active: false,
			bebas_nominal: false,
			category_id: "",
			description: "",
			icon: "",
			jenis: 0,
			kode_merchant: "",
			kode_produk: 0,
			located: 0,
			menu_input: "",
			name: "",
			order_number: 0,
			parent_id: "",
			type: 1,
			_id: "",
		},
	],
});

export default submenuStore;
