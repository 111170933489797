import React from "react";

const LandscapeLoadingCardMenu: React.FC = (): JSX.Element => {
	return (
		<div
			className="flex justify-between items-center lg:gap-0 animate-pulse bg-gray-300 shadow px-4 py-5 lg:py-3 duration-500 lg:ease-out rounded-md lg:rounded-lg">
			<div className="w-full h-16 sm:h-[5rem] lg:h-auto flex justify-center lg:justify-start items-center flex-col lg:flex-row gap-4 lg:gap-4">
				<div className="hidden lg:block w-10 h-9 lg:w-16 lg:h-12 animate-pulse bg-gray-400 rounded-xl" />
				<div className="hidden lg:block w-full">
					<div className="lg:mb-4">
						<p className="h-2.5 lg:h-3 w-10 lg:w-5/6 animate-pulse bg-gray-400 rounded"></p>
					</div>
					<div className="hidden lg:block">
						<p className="h-2 lg:h-3 lg:w-5/6 animate-pulse bg-gray-400 rounded"></p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LandscapeLoadingCardMenu;
