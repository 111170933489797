import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../../store/theme-app.store";

import moment from "moment";

import NumberFormat from "react-number-format";

import toast from "react-hot-toast";

import Header from "../../components/Header";

import ContentList from "../../components/ContentList";

import loadingCardLength from "../../components/loading/loadingCardLength";
import { LandscapeLoadingCard } from "../../components/loading/LoadingCard";

import EmptyIcon from "../../components/icons/EmptyIcon";

import MoreDataList from "../../components/MoreDataList";

const idLocale = require('moment/locale/id')
moment.updateLocale('id', idLocale);

const RewardListPage: React.FC = (props): JSX.Element => {
	interface RewardListSchemaType {
		created_at: string;
		kode_merchant: string;
		ref: string;
		reward: {
			created_at: string;
			description: string;
			kode_merchant: string;
			poin: number;
			poin_promo: string;
			stock: number;
			title: string;
			url_images: string;
			_id: string;
		};
		status: number;
		updated_at: string;
		user_id?: string;
		_id: string;
	}

	const rewardListSchema = [
		{
			created_at: "",
			kode_merchant: "",
			ref: "",
			reward: {
				created_at: "",
				description: "",
				kode_merchant: "",
				poin: 0,
				poin_promo: "",
				stock: 0,
				title: "",
				url_images: "",
				_id: "",
			},
			status: 0,
			updated_at: "",
			user_id: "",
			_id: "",
		},
	];

	const [rewardListState, setRewardListState] = useState<Array<RewardListSchemaType>>(rewardListSchema);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
	const [isPaginationLoadingState, setIsPaginationLoadingState] = useState<boolean>(false);
	const [isMaxPaginationState, setIsMaxPaginationState] = useState<boolean>(false);

	let [pageState, setPageState] = useState<number>(0);

	function getRewardList(rewardPage: number, setLoading: React.Dispatch<React.SetStateAction<boolean>>, setPaginationLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			if (rewardPage <= 0) setLoading(true);

			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/reward/history?page=${rewardPage}`,
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log('[REWARD LIST EXCHANGE HISTORY]', result)

				if (result.status === 200) {
					setLoading(false);
					setPaginationLoading(false);
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		getRewardList(pageState, setIsLoadingState, setIsPaginationLoadingState).then((result: any) => {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) {
				if (pageState > 0) {
					if (!data.length) {
						setIsMaxPaginationState(true);
						toast.error(`Data telah mencapai batas maximum`, {
							style: {
								fontWeight: "bolder",
							},
						});
					}
					setRewardListState([...rewardListState, ...data]);
					return;
				}

				setRewardListState(data);
			}
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	}, [pageState]);

	const themeState = useRecoilValue<string | null>(themeAppStore);

	const iconTheme: string = themeState === "dark" ? "#FFFFFF" : "#000000";

	return (
		<div>
			<Header title="Riwayat Penukaran Reward" />

			{isLoadingState ? (
				<div className="flex flex-col gap-4">
					{loadingCardLength.slice(0, 10).map((loading, index) => (
						<Fragment key={index}>
							<LandscapeLoadingCard line={4} />
						</Fragment>
					))}
				</div>
			) : (
				<Fragment>
					{!rewardListState.length ? (
						<div className="mt-24 lg:mt-20 2xl:mt-40">
							<EmptyIcon title="Anda Belum Memiliki Riwayat Penukaran Reward" />
						</div>
					) : (
						rewardListState.map(({ created_at: createdAt, reward: { poin: point, title }, status }, index): JSX.Element => (
							<Fragment key={index}>
								<ContentList optional>
									<div className="w-full flex items-center gap-3 lg:gap-4">
										<div className="lg:w-16">
											<svg
												fill={iconTheme}
												className="w-9 h-9 lg:w-16 lg:h-16 rounded-sm"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="40"
												height="40">
												<path fill="none" d="M0 0h24v24H0z" />
												<path d="M15 2a4 4 0 0 1 3.464 6.001L23 8v2h-2v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V10H1V8l4.536.001A4 4 0 0 1 12 3.355 3.983 3.983 0 0 1 15 2zm-2 8h-2v10h2V10zM9 4a2 2 0 0 0-.15 3.995L9 8h2V6a2 2 0 0 0-1.697-1.977l-.154-.018L9 4zm6 0a2 2 0 0 0-1.995 1.85L13 6v2h2a2 2 0 0 0 1.995-1.85L17 6a2 2 0 0 0-2-2z" />
											</svg>
										</div>
										<div className="w-full flex justify-between items-center lg:flex-col lg:items-start lg:gap-3 xl:flex-row xl:items-center xl:gap-0">
											<div className="flex flex-col w-44 sm:w-auto lg:w-3/4 2xl:w-5/6 break-words">
												<span className="mb-1 text-xs sm:text-base font-medium tracking-wide">{title}</span>
												<NumberFormat
													value={point}
													className="mb-1 text-xs sm:text-sm text-green-500 font-medium tracking-wide"
													displayType={"text"}
													thousandSeparator={"."}
													decimalSeparator={","}
												/>
											</div>
											<div className="flex flex-col w-24 lg:w-60 text-right lg:text-left xl:text-right pr-4">
												{status === 2 ? (
													<span className="mb-1 text-xs sm:text-base text-green-500 font-medium tracking-wide">Success</span>
												) : status === 3 ? (
													<span className="mb-1 text-xs sm:text-base text-red-500 font-medium tracking-wide">Gagal</span>
												) : (
													<span className="mb-1 text-xs sm:text-base text-yellow-500 font-medium tracking-wide">Dalam Proses</span>
												)}
												<span className="text-2xs sm:text-base text-gray-600 leading-4 sm:leading-3 md:leading-4">{moment.utc(createdAt).format("DD MMMM YYYY HH:mm:ss")} WIB</span>
											</div>
										</div>
									</div>
								</ContentList>
							</Fragment>
						)
						)
					)}
				</Fragment>
			)}

			{isPaginationLoadingState ? (
				<div className="flex justify-center items-center">
					<span className="text-xs sm:text-base font-medium tracking-wide">Loading...</span>
				</div>
			) : (
				rewardListState.length >= 10 &&
				!isLoadingState && (
					<Fragment>
						{!isMaxPaginationState && <MoreDataList setPage={setPageState} setPaginationLoading={setIsPaginationLoadingState} />}
					</Fragment>
				)
			)}
		</div>
	);
};

export default RewardListPage;
