import React from "react";

import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InquiryLoadingPrepaidDetails: React.FC = (props): JSX.Element => {
	return (
		<div>
			<div className="p-3 bg-white shadow-lg rounded-lg mb-3">
				{/* PRODUCT DETAILS */}
				<div className="flex justify-between p-2">
					<div>
						<span className="text-sm sm:text-lg font-medium font-dmsans">Detail Produk</span>
					</div>
					<div>
						<FontAwesomeIcon icon={faReceipt}></FontAwesomeIcon>
					</div>
				</div>
				<hr />

				{/* PRODUCT NAME */}
				<div className="flex items-start flex-col p-2">
					<div className="mb-1">
						<span className="text-xs sm:text-base text-gray-500 font-dmsans">Nama Produk</span>
					</div>
					<div>
						<p className="h-4 w-52 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* DESTINATION NUMBER */}
				<div className="flex items-start flex-col p-2">
					<div className="mb-1">
						<span className="text-xs sm:text-sm text-gray-500 font-dmsans">
							Nomor Tujuan
						</span>
					</div>
					<div>
						<p className="h-4 w-52 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* COUNTER */}
				<div className="flex items-start flex-col p-2">
					<div className="mb-1">
						<span className="text-xs sm:text-sm text-gray-500 font-dmsans">
							Pengisian Ke
						</span>
					</div>
					<div>
						<p className="h-4 w-8 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* POINT */}
				<div className="flex items-start flex-col p-2">
					<div className="mb-1">
						<span className="text-xs sm:text-sm text-gray-500 font-dmsans">Poin</span>
					</div>
					<div>
						<p className="h-4 w-8 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>
			</div>

			{/* PRICE DETAILS */}
			<div className="p-3 bg-white shadow-lg rounded-lg">
				{/* PRICE DETAILS */}
				<div className="flex justify-between p-2">
					<div>
						<span className="text-sm lg:text-base font-medium font-dmsans">Detail Harga</span>
					</div>
					<div>
						<FontAwesomeIcon icon={faReceipt}></FontAwesomeIcon>
					</div>
				</div>
				<hr />

				{/* SELLING PRICE */}
				<div className="flex items-start flex-col p-2">
					<div className="mb-1">
						<span className="text-xs sm:text-sm text-gray-500 font-dmsans">Harga Jual</span>
					</div>
					<div>
						<p className="h-4 w-24 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* DISCOUNT PRICE */}
				<div className="flex items-start flex-col p-2">
					<div className="mb-1">
						<span className="text-xs sm:text-sm text-gray-500 font-dmsans">Diskon</span>
					</div>
					<div>
						<p className="h-4 w-24 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* CASHBACK */}
				<div className="flex items-start flex-col p-2">
					<div className="mb-1">
						<span className="text-xs sm:text-sm text-gray-500 font-dmsans">Cashback</span>
					</div>
					<div>
						<div>
							<p className="h-4 w-24 animate-pulse bg-gray-300 rounded"></p>
						</div>
					</div>
				</div>

				{/* TOTAL PURCHASE */}
				<div className="flex items-start flex-col p-2">
					<div className="mb-1">
						<span className="text-xs sm:text-sm text-gray-500 font-dmsans">Total Bayar</span>
					</div>
					<div>
						<p className="h-4 w-24 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InquiryLoadingPrepaidDetails;
