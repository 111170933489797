import React, { Fragment } from "react";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../../store/theme-app.store";

import Header from "../Header";

import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TrxLoadingDetails: React.FC = (props): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);
	const defaultTheme = {
		constentList: 'bg-white',
		label: 'text-gray-500',
		border: ''
	}

	if(themeState === 'dark') {
		defaultTheme['constentList'] = 'bg-[#1F283E]';
		defaultTheme['label'] = 'text-gray-400';
		defaultTheme['border'] = 'border-gray-700';
	}

	return (
		<Fragment>
			<div className="flex justify-between items-center">
				<Header title="Detail Riwayat Transaksi" />
			</div>

			<div className={`${defaultTheme.constentList} p-3 shadow-lg rounded-lg my-3`}>
				<div className={`${defaultTheme.border} flex justify-between items-center p-2`}>
					<div>
						<p className="h-4 w-40 lg:w-48 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* DESTINATION NUMBER */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>Nomor Tujuan</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-48 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* PRUDUCT DESCRIPTION */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>Deskripsi Produk</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-48 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* PRODUCT PRICE */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>Harga</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-20 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* ADMIN PRICE */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>Admin</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-20 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* PAYMENT METHOD */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>Metode Pembayaran</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-48 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>
			</div>

			{/* TRX INFORMATION */}
			<div className={`${defaultTheme.constentList} p-3 shadow-lg rounded-lg`}>
				{/* PRICE DETAILS */}
				<div className={`${defaultTheme.border} flex justify-between p-2`}>
					<div>
						<span className="text-sm sm:text-lg font-medium font-dmsans">Informasi Transaksi</span>
					</div>
					<div>
						<FontAwesomeIcon icon={faReceipt}></FontAwesomeIcon>
					</div>
				</div>
				<hr />

				{/* PAYMENT STATUS */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>Status</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-20 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* COUNTER */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>Pengisian Ke</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-20 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* BONUS POINT */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>Bonus Poin</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-20 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* SERIAL NUMBER */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>Serial Number</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-48 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* TRX ID */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>ID Transaksi</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-48 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* TRX TIME */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>Waktu Transaksi</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-48 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>

				{/* STATUS TIME */}
				<div className="flex flex-col gap-1 px-2 lg:pt-3.5 pb-2.5 lg:pb-2 text-left">
					<div>
						<span className={`${defaultTheme.label} text-xs sm:text-base text-gray-500`}>Waktu Status</span>
					</div>
					<div>
						<p className="h-4 w-40 lg:w-48 animate-pulse bg-gray-300 rounded"></p>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default TrxLoadingDetails;
