interface loadingCardLengthType {
	id: number;
}

const loadingCardLength: Array<loadingCardLengthType> = [];

const total = 26;
for(var i = 0; i <= total; i++) {
	loadingCardLength.push({ id: i }); 
}

export default loadingCardLength;
