import React, { Fragment, useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import { useSetRecoilState } from "recoil";
import favoriteNumberStore from "../../../store/favorite-number.store";

import axios from "axios";

import toast from "react-hot-toast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import loadingCardLength from "../../components/loading/loadingCardLength";

import { LandscapeLoadingCard } from "../../components/loading/LoadingCard";

import Header from "../../components/Header";

import ContentList from "../../components/ContentList";

interface FavoriteNumberType {
	created_at: string;
	kode_merchant: string;
	nama: string;
	tujuan: number;
	type: string;
	user_id?: string;
	_id: string;
}

interface FavoriteNumberProps {
	defaultFavoriteNumber: Array<FavoriteNumberType>;
	setDataSearch: React.Dispatch<React.SetStateAction<string>>;
	setFavoriteNumber: React.Dispatch<React.SetStateAction<FavoriteNumberType[]>>;
}

const FavoriteNumber: React.FC<FavoriteNumberProps> = ({
	defaultFavoriteNumber,
	setDataSearch,
	setFavoriteNumber,
}): JSX.Element => {
	function handleChangeFavoriteNumber(e: any) {
		const searchInput = e.target.value;
		if (searchInput.length > 0) {
			setDataSearch(searchInput);
			return;
		}

		setFavoriteNumber(defaultFavoriteNumber);
	}
	return (
		<div className="flex search-input-shadow mt-2 mb-6 rounded-md lg:rounded-lg">
			{/* SEARCH ICON */}
			<div className="flex justify-center items-center pl-4 pr-4 py-2">
				<FontAwesomeIcon
					icon={faSearch}
					className="font-black text-xl sm:text-2xl text-gray-800"></FontAwesomeIcon>
			</div>

			{/* INPUT SEARCH */}
			<input
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeFavoriteNumber(e)}
				className="w-full bg-opacity-0 text-gray-500 pr-4 py-3 sm:py-2.5 text-sm sm:text-base capitalize focus:outline-none rounded-md lg:rounded-lg"
				type="text"
				placeholder="Cari Nomor Favorit"
			/>
		</div>
	);
};

const FavoriteNumberPage: React.FC = (props: any): JSX.Element => {
	if (!props.location.state) window.location.replace("/dashboard");

	const { productType } = props.location.state;

	const setFavoriteNumberStateManagement = useSetRecoilState(favoriteNumberStore);

	interface FavoriteNumberSchemaType {
		created_at: string;
		kode_merchant: string;
		nama: string;
		tujuan: number;
		type: string;
		user_id?: string;
		_id: string;
	}

	const favoriteNumberSchema = [
		{
			created_at: "",
			kode_merchant: "",
			nama: "",
			tujuan: 0,
			type: "",
			user_id: "",
			_id: "",
		},
	];

	const [tempFavoriteNumberState, setTempFavoriteNumberState] = useState<Array<FavoriteNumberSchemaType>>(favoriteNumberSchema);

	const [favoriteNumberState, setFavoriteNumberState] = useState<Array<FavoriteNumberType>>(favoriteNumberSchema);

	const [dataSearchState, setDataSearchState] = useState("");

	const [isLoadingState, setIsLoadingState] = useState(false);

	function getFavoriteNumber(productType: string, setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<void> {
		return new Promise((resolve, reject): void => {
			setLoading(true);
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/favorite/get?type=${productType}`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log(`[FAVORITE NUMBER]`, result);

				if (result.status === 200) {
					setLoading(false);
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		getFavoriteNumber(productType, setIsLoadingState).then((result: any): void => {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) {
				setFavoriteNumberState(data);
				setTempFavoriteNumberState(data);
			}
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	}, [productType]);

	useEffect((): void => {
		const search: Array<FavoriteNumberType> = tempFavoriteNumberState.filter(({ nama: name }) => name.toLowerCase().includes(dataSearchState.toLowerCase()));
		setFavoriteNumberState(search);
	}, [dataSearchState]);

	return (
		<div>
			<Header title="Nomor Favorit" />

			<FavoriteNumber defaultFavoriteNumber={favoriteNumberState} setDataSearch={setDataSearchState} setFavoriteNumber={setFavoriteNumberState} />

			{isLoadingState ? (
				<div className="flex flex-col">
					{loadingCardLength.slice(0, 10).map((loading, index): JSX.Element => (
						<Fragment key={index}>
							<div className="mb-4">
								<LandscapeLoadingCard optional>
									<div className="w-full py-1">
										<div className="mb-3">
											<p className="h-2 lg:h-3.5 w-40 lg:w-64 animate-pulse bg-gray-400 rounded"></p>
										</div>
										<div>
											<p className="h-2 lg:h-3.5 w-40 lg:w-64 animate-pulse bg-gray-400 rounded"></p>
										</div>
									</div>
								</LandscapeLoadingCard>
							</div>
						</Fragment>
					))}
				</div>
			) : favoriteNumberState.length > 0 ? (
				<div className="flex flex-col bg-white">
					{favoriteNumberState.map(({ nama: name, tujuan: destination, _id: id }, index): JSX.Element => (
						<Fragment key={index}>
							<ContentList
								onClick={(): void => {
									setFavoriteNumberStateManagement(destination);
									window.history.back();
								}}
								line={2}
								firstLine={<span className="mb-1 text-sm sm:text-base font-medium tracking-wide">{name}</span>}
								secondLine={<span className="text-xs sm:text-sm font-base tracking-wide">{destination}</span>}
							/>
						</Fragment>
					))}
				</div>
			) : (
				<Fragment>
					<div className="text-center">
						<span className="text-red-500 text-xs lg:text-base font-inter tracking-wide">
							Nomor Favorit Tidak Di Temukan :(
						</span>
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default withRouter(FavoriteNumberPage);
