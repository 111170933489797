import React, { Fragment, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import axios from "axios";

import { useRecoilValue } from "recoil";
import userInfoStore from "../../../../store/userInfo";
import themeAppStore from "../../../../store/theme-app.store";

import toast from "react-hot-toast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faLock, faMapMarkedAlt, faMapMarkerAlt, faPhone, faStoreAlt, faUserAlt } from "@fortawesome/free-solid-svg-icons";

import ReCAPTCHA from "react-google-recaptcha";

import Header from "../../../components/Header";

import { getDptCities } from "../../../../modules";

/* 
Downline get data
	*/
interface DownlineDptCityType {
	id: string;
	nama: string;
	_id: string;
}

function downlineProvinces(e: any, setDownlineProvinceId: React.Dispatch<React.SetStateAction<string>>, setDownlineDptCity: React.Dispatch<React.SetStateAction<DownlineDptCityType[]>>): void {
	let provinceCodeId: any = e.target.value;
	let provinceId: string = e.target.options[e.target.selectedIndex].dataset.id;

	setDownlineProvinceId(provinceId);

	getDptCities(provinceCodeId).then((result: any): void => {
		const statusCode: number = result.data.status;
		const data: any = result.data.data;
		if (statusCode === 200) setDownlineDptCity(data);
	}).catch((e: any): void => {
		toast.error(e.data.message, {
			style: {
				fontWeight: "bolder",
			},
		});
		console.log(e);
	});
};

function downlineCities(e: any, setDownlineCityId: React.Dispatch<React.SetStateAction<string>>, setDownlineDptSubdistrict: React.Dispatch<React.SetStateAction<DownlineDptCityType[]>>): void {
	let kabupatenCodeId: any = e.target.value;
	let kabupatenId: string = e.target.options[e.target.selectedIndex].dataset.id;

	setDownlineCityId(kabupatenId);

	const getDptSubdistrict = (id: string): Promise<void> => {
		return new Promise((resolve, reject): void => {
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/kabupaten/${id}/kecamatan`,
				method: "GET",
				validateStatus: (status: any): boolean => true,
			}).then((result: any): void => {
				// console.log('[DPT KECAMATAN]', result)

				if (result.status === 200) {
					resolve(result);
				} else {
					reject(result);
				}
			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	getDptSubdistrict(kabupatenCodeId).then((result: any): void => {
		const statusCode: number = result.data.status;
		const data: any = result.data.data;
		if (statusCode === 200) setDownlineDptSubdistrict(data);
	}).catch((e: any): void => {
		toast.error(e.data.message, {
			style: {
				fontWeight: "bolder",
			},
		});
		console.log(e);
	});
};

//==========================================================================

/* 
	Downline Components
	*/
interface DownlineInputNameProps {
	setDownlineName: React.Dispatch<React.SetStateAction<string>>;
}

const DownlineInputName: React.FC<DownlineInputNameProps> = ({
	setDownlineName,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white',
		input: 'bg-white text-gray-800',
		icon: 'lg:text-gray-500'
	}

	if (themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['icon'] = 'lg:text-gray-100';
	}

	return (
		<Fragment>
			<label htmlFor="downline-name" className="font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide">Nama Downline :</label>
			<div className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md mb-4`}>
				<div className="flex justify-center items-center py-3 px-4">
					<FontAwesomeIcon icon={faUserAlt} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl`}></FontAwesomeIcon>
				</div>
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setDownlineName(e.target.value)}
					type="string"
					className={`${defaultTheme.input} w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="downline-name"
					placeholder="Masukan nama"
					autoComplete="off"
					autoFocus
				/>
			</div>
		</Fragment>
	);
};

interface DownlineInputNumberPhoneProps {
	setDownlineNumberPhone: React.Dispatch<React.SetStateAction<number>>;
}

const DownlineInputNumberPhone: React.FC<DownlineInputNumberPhoneProps> = ({
	setDownlineNumberPhone,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white',
		input: 'bg-white text-gray-800',
		icon: 'lg:text-gray-500'
	}

	if (themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['icon'] = 'lg:text-gray-100';
	}

	return (
		<Fragment>
			<label htmlFor="downline-phone-number" className="font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide">Nomor HP Downline :</label>
			<div className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md mb-4`}>
				<div className="flex justify-center items-center py-3 px-4">
					<FontAwesomeIcon icon={faPhone} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl`}></FontAwesomeIcon>
				</div>
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setDownlineNumberPhone(parseInt(e.target.value))}
					onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
						if (!/[0-9]/.test(e.key)) e.preventDefault();
					}}
					type="number"
					className={`${defaultTheme.input} w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="downline-phone-number"
					placeholder="Masukan nomor telepon"
					autoComplete="off"
				/>
			</div>
		</Fragment>
	);
};

interface DownlineInputMerchantNameProps {
	setDownlineMerchantName: React.Dispatch<React.SetStateAction<string>>;
}

const DownlineInputMerchantName: React.FC<DownlineInputMerchantNameProps> = ({
	setDownlineMerchantName,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white',
		input: 'bg-white text-gray-800',
		icon: 'lg:text-gray-500'
	}

	if (themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['icon'] = 'lg:text-gray-100';
	}

	return (
		<Fragment>
			<label
				htmlFor="merchant-name"
				className="font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide">
				Nama Toko :
			</label>
			<div
				className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md mb-4`}>
				<div className="flex justify-center items-center py-3 px-3.5">
					<FontAwesomeIcon
						icon={faStoreAlt}
						className={`${defaultTheme.icon} font-black text-xl sm:text-2xl`}></FontAwesomeIcon>
				</div>
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setDownlineMerchantName(e.target.value)}
					type="string"
					className={`${defaultTheme.input} w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="merchant-name"
					placeholder="Masukan nama toko"
					autoComplete="off"
				/>
			</div>
		</Fragment>
	);
};

interface DownlineInputMerchantAddressProps {
	setDownlineMerchantAddress: React.Dispatch<React.SetStateAction<string>>;
}

const DownlineInputMerchantAddress: React.FC<DownlineInputMerchantAddressProps> = ({
	setDownlineMerchantAddress
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white',
		input: 'bg-white text-gray-800',
		icon: 'lg:text-gray-500'
	}

	if (themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['icon'] = 'lg:text-gray-100';
	}

	return (
		<Fragment>
			<label htmlFor="merchant-address" className="font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide">Alamat Toko :</label>
			<div className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md mb-4`}>
				<div className="flex justify-center items-center py-3 px-5">
					<FontAwesomeIcon icon={faMapMarkerAlt} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl`}></FontAwesomeIcon>
				</div>
				<input onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setDownlineMerchantAddress(e.target.value)}
					type="string"
					className={`${defaultTheme.input} w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="merchant-address"
					placeholder="Masukan alamat toko"
					autoComplete="off"
				/>
			</div>
		</Fragment>
	);
};

interface DownlineDptPropincesType {
	id: string;
	nama: string;
	_id: string;
}

interface DownlineSelectProvincesProps {
	downlineDptProvinces: DownlineDptPropincesType[];
	setDownlineProvinceId: React.Dispatch<React.SetStateAction<string>>;
	setDownlineDptCity: React.Dispatch<React.SetStateAction<DownlineDptCityType[]>>;
}

const DownlineSelectProvinces: React.FC<DownlineSelectProvincesProps> = ({
	downlineDptProvinces,
	setDownlineProvinceId,
	setDownlineDptCity,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white',
		input: 'bg-white text-gray-800',
		icon: 'lg:text-gray-500'
	}

	if (themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['icon'] = 'lg:text-gray-100';
	}

	return (
		<Fragment>
			<label htmlFor="province" className="font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide">Provinsi :</label>
			<div className={`${defaultTheme.input} flex w-full pin-input-shadow rounded-md mb-4`}>
				<div className="flex justify-center items-center py-3 px-4">
					<FontAwesomeIcon icon={faMapMarkedAlt} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl`}></FontAwesomeIcon>
				</div>

				<select
					className={`${defaultTheme.input} w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="province"
					onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => downlineProvinces(e, setDownlineProvinceId, setDownlineDptCity)}>
					<option selected disabled>Pilih propinsi</option>
					{downlineDptProvinces.map(({ id: keyId, nama: name, _id: id }, index) => (
						<option key={index} value={keyId} data-id={id}>{name}</option>
					))}
				</select>
			</div>
		</Fragment>
	);
};

interface DownlineDptCitiesType {
	id: string;
	nama: string;
	_id: string;
}

interface DownlineSelectCitiesProps {
	downlineDptCities: DownlineDptCitiesType[];
	setDownlineCityId: React.Dispatch<React.SetStateAction<string>>;
	setDownlineDptSubdistrict: React.Dispatch<React.SetStateAction<DownlineDptCityType[]>>;
}

const DownlineSelectCities: React.FC<DownlineSelectCitiesProps> = ({
	downlineDptCities,
	setDownlineCityId,
	setDownlineDptSubdistrict,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white',
		input: 'bg-white text-gray-800',
		icon: 'lg:text-gray-500'
	}

	if (themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['icon'] = 'lg:text-gray-100';
	}

	return (
		<Fragment>
			<label htmlFor="kab" className="font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide">Kabupaten :</label>
			<div className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md mb-4`}>
				<div className="flex justify-center items-center py-3 px-4">
					<FontAwesomeIcon icon={faMapMarkedAlt} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl`}></FontAwesomeIcon>
				</div>
				<select
					className={`${defaultTheme.input} w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="kab"
					onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => downlineCities(e, setDownlineCityId, setDownlineDptSubdistrict)}>
					<option selected disabled>Pilih kabupaten</option>
					{downlineDptCities.map(({ id: keyId, nama: name, _id: id }, index) => (
						<option key={index} value={keyId} data-id={id}>{name}</option>
					))}
				</select>
			</div>
		</Fragment>
	);
};

interface DownlineDptSubdistrictType {
	id: string;
	nama: string;
	_id: string;
}

interface DownlineSelectSubdistrictProps {
	downlineDptSubdistrict: DownlineDptSubdistrictType[];
	setDownlineSubdistrictId: React.Dispatch<React.SetStateAction<string>>;
}

const DownlineSelectSubdistrict: React.FC<DownlineSelectSubdistrictProps> = ({
	downlineDptSubdistrict,
	setDownlineSubdistrictId,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white',
		input: 'bg-white text-gray-800',
		icon: 'lg:text-gray-500'
	}

	if (themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['icon'] = 'lg:text-gray-100';
	}

	function downlineSubdistrict(e: any, setDownlineSubdistrictId: React.Dispatch<React.SetStateAction<string>>): void {
		let subdistrictId: string = e.target.options[e.target.selectedIndex].dataset.id;
		console.log(subdistrictId);
		setDownlineSubdistrictId(subdistrictId);
	};

	return (
		<Fragment>
			<label htmlFor="kec" className="font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide">Kecamatan :</label>
			<div className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md mb-4`}>
				<div className="flex justify-center items-center py-3 px-4">
					<FontAwesomeIcon icon={faMapMarkedAlt} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl`}></FontAwesomeIcon>
				</div>
				<select
					className={`${defaultTheme.input} w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="kec"
					onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => downlineSubdistrict(e, setDownlineSubdistrictId)}>
					<option selected disabled>Pilih kecamatan</option>
					{downlineDptSubdistrict.map(({ id: keyId, nama: name, _id: id }, index) => (
						<option key={index} value={keyId} data-id={id}>{name}</option>
					))}
				</select>
			</div>
		</Fragment>
	);
};

interface DownlineInputAddressProps {
	setDownlineAddress: React.Dispatch<React.SetStateAction<string>>;
}

const DownlineInputAddress: React.FC<DownlineInputAddressProps> = ({
	setDownlineAddress,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white',
		input: 'bg-white text-gray-800',
		icon: 'lg:text-gray-500'
	}

	if (themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['icon'] = 'lg:text-gray-100';
	}

	return (
		<Fragment>
			<label htmlFor="address" className="font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide">Alamat :</label>
			<div className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md mb-4`}>
				<div className="flex justify-center items-center py-3 px-4">
					<FontAwesomeIcon icon={faMapMarkedAlt} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl`}></FontAwesomeIcon>
				</div>
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setDownlineAddress(e.target.value)}
					type="string"
					className={`${defaultTheme.input} w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="address"
					placeholder="Masukan alamat"
					autoComplete="off"
				/>
			</div>
		</Fragment>
	);
};

interface DownlineInputPinProps {
	downlinePin: number;
	setDownlinePin: React.Dispatch<React.SetStateAction<number>>;
}

const DownlineInputPin: React.FC<DownlineInputPinProps> = ({
	setDownlinePin,
	downlinePin,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white',
		input: 'bg-white text-gray-800',
		icon: 'lg:text-gray-500'
	}

	if (themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['icon'] = 'lg:text-gray-100';
	}

	return (
		<Fragment>
			<label htmlFor="pin" className="font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide">PIN Downline :</label>
			<div className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md mb-4`}>
				<div className="flex justify-center items-center py-3 px-5">
					<FontAwesomeIcon icon={faLock} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl`}></FontAwesomeIcon>
				</div>
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						const re = /^[0-9\b]+$/;
						if (e.target.value === "" || re.test(e.target.value)) setDownlinePin(parseInt(e.target.value));
					}}
					type="text"
					className={`${defaultTheme.input} password w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="pin"
					placeholder="Masukan PIN"
					autoComplete="off"
					maxLength={parseInt(process.env.REACT_APP_LOGIN_PIN_LENGTH ? process.env.REACT_APP_LOGIN_PIN_LENGTH : "")}
				/>
			</div>
		</Fragment>
	);
};

interface DownlineInputMarkupProps {
	setDownlineMarkup: React.Dispatch<React.SetStateAction<string>>;
}

const DownlineInputMarkup: React.FC<DownlineInputMarkupProps> = ({
	setDownlineMarkup,
}): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white',
		input: 'bg-white text-gray-800',
		icon: 'lg:text-gray-500'
	}

	if (themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['icon'] = 'lg:text-gray-100';
	}

	return (
		<Fragment>
			<label htmlFor="markup" className="font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide">Markup Downline :</label>
			<div className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md mb-4`}>
				<div className="flex justify-center items-center py-3 px-6">
					<FontAwesomeIcon icon={faDollarSign} className={`${defaultTheme.icon} font-black text-xl sm:text-2xl`}></FontAwesomeIcon>
				</div>
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setDownlineMarkup(e.target.value)}
					type="number"
					className={`${defaultTheme.input} w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="markup"
					placeholder="Masukan markup"
					autoComplete="off"
				/>
			</div>
		</Fragment>
	);
};

interface DownlineRecaptchaProps {
	downlineCaptchaVerification: boolean;
	setDownlineCaptchaVerification: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownlineRecaptcha: React.FC<DownlineRecaptchaProps> = ({
	downlineCaptchaVerification,
	setDownlineCaptchaVerification,
}): JSX.Element => {
	return (
		<Fragment>
			<label htmlFor="recaptcha" className="font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide">Verifikasi Captcha :</label>
			<div>
				<ReCAPTCHA
					sitekey="6LeDRwQcAAAAAKkj6gZwHK0ev33OKnvZDSnaOZyD"
					onChange={(value) => {
						if (!value) {
							setDownlineCaptchaVerification(false);
							return;
						}

						setDownlineCaptchaVerification(true);
					}}
				/>
				{!downlineCaptchaVerification ? (
					<p className="text-sm text-red-500">Silahkan Verifikasi Captcha Terlebih Dahulu</p>
				) : (
					<p className="text-sm text-green-600">Anda Sudah Terverifikasi</p>
				)}
			</div>
		</Fragment>
	);
};

const DownlineSubmitButton: React.FC = (props): JSX.Element => {
	const themeState = useRecoilValue<string | null>(themeAppStore);

	interface btnPrimaryThemeSchema {
		backgroundColor: string;
		color: string
	}

	const btnPrimaryTheme: btnPrimaryThemeSchema = {
		backgroundColor: process.env.REACT_APP_PRIMARY_COLOR ?? "#FFFFFF",
		color: process.env.REACT_APP_PRIMARY_COLOR ? "#FFFFFF" : "#000000",
	}

	if (themeState === 'dark') {
		btnPrimaryTheme['backgroundColor'] = "#1F283E";
		btnPrimaryTheme['color'] = "#FFFFFF";
	}

	return (
		<button
			className="inline-flex justify-center items-center gap-3 w-full mt-6 py-3 px-8 lg:px-20 text-sm sm:text-lg lg:text-base 2xl:text-lg rounded-md lg:rounded-md focus:outline-none tracking-wider"
			style={btnPrimaryTheme}>
			Tambah Downline
		</button>
	);
};

const DownlineAddPage: React.FC = (): JSX.Element => {
	const userInformation = useRecoilValue(userInfoStore);

	const { _id: id } = userInformation;

	const [downlineNameState, setDownlineNameState] = useState("");

	const [downlineNumberPhoneState, setDownlineNumberPhoneState] = useState<number>(0);

	const [downlineMerchantNameState, setDownlineMerchantNameState] = useState<string>("");

	const [downlineMerchantAddressState, setDownlineMerchantAddressState] = useState<string>("");

	interface DownlineDptPropincesSchemaType {
		id: string;
		nama: string;
		_id: string;
	}

	const downlineDptProvincesSchema = [
		{
			id: "",
			nama: "",
			_id: "",
		},
	];

	const [downlineDptProvincesState, setDownlineDptProvincesState] = useState<Array<DownlineDptPropincesSchemaType>>(downlineDptProvincesSchema);

	const [downlineProvinceIdState, setDownlineProvinceIdState] = useState<string>("");

	interface DownlineDptCitiesSchemaType {
		id: string;
		nama: string;
		_id: string;
	}

	const downlineDptCitiesSchema = [
		{
			id: "",
			nama: "",
			_id: "",
		},
	];

	const [downlineDptCitiesState, setDownlineDptCitiesState] = useState<Array<DownlineDptCitiesSchemaType>>(downlineDptCitiesSchema);

	const [downlineCityIdState, setDownlineCityIdState] = useState<string>("");

	interface DownlineDptSubdistrictSchemaType {
		id: string;
		nama: string;
		_id: string;
	}

	const downlineDptSubdistrictSchema = [
		{
			id: "",
			nama: "",
			_id: "",
		},
	];

	const [downlineDptSubdistrictState, setDownlineDptSubdistrictState] = useState<Array<DownlineDptSubdistrictSchemaType>>(downlineDptSubdistrictSchema);

	const [downlineSubdistrictIdState, setDownlineSubdistrictIdState] = useState<string>("");

	const [downlineAddressState, setDownlineAddressState] = useState<string>("");

	const [downlineMarkupState, setDownlineMarkupState] = useState<string>("");

	const [downlinePinState, setDownlinePinState] = useState<number>(0);

	const [downlineCaptchaVerificationState, setDownlineCaptchaVerificationState] = useState(false);

	const getDptProvinces = (): Promise<void> => {
		return new Promise((resolve, reject): void => {
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/propinsi/list`,
				method: "GET",
				validateStatus: (status: any): boolean => true,
			}).then((result: any): void => {
				// console.log('[DPT PROVINCI]', result)

				if (result.status === 200) {
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		getDptProvinces().then((result: any) => {
			const statusCode = result.data.status;
			const data = result.data.data;
			if (statusCode === 200) setDownlineDptProvincesState(data);
		});
	}, []);


	const createDownline = (
		downlineName: string,
		downlineNumberPhone: number,
		downlineMerchantName: string,
		downlineMerchantAddress: string,
		downlineMarkup: string,
		id: string,
		downlinePin: number,
		downlineProvinceId: string,
		downlineKabupatenId: string,
		downlineKecamatanId: string,
		downlineAddress: string
	): Promise<void> => {
		return new Promise((resolve, reject) => {
			const payload = {
				name: downlineName,
				phone: downlineNumberPhone,
				nama_toko: downlineMerchantName,
				alamat_toko: downlineMerchantAddress,
				markup: downlineMarkup,
				kode_upline: id,
				pin: downlinePin,
				id_propinsi: downlineProvinceId,
				id_kabupaten: downlineKabupatenId,
				id_kecamatan: downlineKecamatanId,
				alamat: downlineAddress,
			}

			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/register`,
				method: "POST",
				data: JSON.stringify(payload),
				headers: {
					"Content-Type": "application/json",
					merchantcode: `${process.env.REACT_APP_API_DATA_MERCHANT_CODE}`,
				},
				validateStatus: (status: any): boolean => true,
			}).then((result: any): void => {
				// console.log('[DOWNLINE ADD]', result)

				if (result.status === 200) {
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	const history = useHistory();

	function handleClickDownlineAdd(e: any) {
		e.preventDefault();
		if (downlineCaptchaVerificationState) {
			toast.promise(
				createDownline(
					downlineNameState,
					downlineNumberPhoneState,
					downlineMerchantNameState,
					downlineMerchantAddressState,
					downlineMarkupState,
					id,
					downlinePinState,
					downlineProvinceIdState,
					downlineCityIdState,
					downlineSubdistrictIdState,
					downlineAddressState
				),
				{
					loading: "Loading...",
					success: (result: any) => {
						setTimeout(() => {
							history.push("/dashboard/profile/downline");
						}, 2000);

						return "Data Downline Berhasil Di Buat";
					},
					error: (e) => "Harap Masukan Data Pada Form Input",
				},
				{
					style: {
						fontWeight: "bolder",
					},
				}
			);
		} else {
			toast.error("Silahkan Verifikasi Captcha Terlebih Dahulu", {
				style: {
					fontWeight: "bolder",
				},
			});
		}
	}

	return (
		<div>
			<Header title="Tambah Downline" />

			<div className="flex items-center mt-12">
				<form
					onSubmit={handleClickDownlineAdd}
					className="flex flex-col w-full">
					{/* DOWNLINE NAME */}
					<DownlineInputName setDownlineName={setDownlineNameState} />

					{/* DOWNLINE NUMBER PHONE */}
					<DownlineInputNumberPhone
						setDownlineNumberPhone={setDownlineNumberPhoneState}
					/>

					{/* DOWNLINE MERCHANT NAME */}
					<DownlineInputMerchantName
						setDownlineMerchantName={setDownlineMerchantNameState}
					/>

					{/* DOWNLINE MERCHANT ADDRESS */}
					<DownlineInputMerchantAddress
						setDownlineMerchantAddress={setDownlineMerchantAddressState}
					/>

					{/* DOWNLINE PROVINCES */}
					<DownlineSelectProvinces
						downlineDptProvinces={downlineDptProvincesState}
						setDownlineProvinceId={setDownlineProvinceIdState}
						setDownlineDptCity={setDownlineDptCitiesState}
					/>

					{/* DOWNLINE CITIES */}
					<DownlineSelectCities
						downlineDptCities={downlineDptCitiesState}
						setDownlineCityId={setDownlineCityIdState}
						setDownlineDptSubdistrict={setDownlineDptSubdistrictState}
					/>

					{/* DOWNLINE SUBDISTRICT */}
					<DownlineSelectSubdistrict
						downlineDptSubdistrict={downlineDptSubdistrictState}
						setDownlineSubdistrictId={setDownlineSubdistrictIdState}
					/>

					{/* DOWNLINE ADDRESS */}
					<DownlineInputAddress setDownlineAddress={setDownlineAddressState} />

					{/* DOWNLINE PIN */}
					<DownlineInputPin
						downlinePin={downlinePinState}
						setDownlinePin={setDownlinePinState}
					/>

					{/* DOWNLINE MARKUP */}
					<DownlineInputMarkup setDownlineMarkup={setDownlineMarkupState} />

					{/* DOWNLINE RECHAPTCHA */}
					<DownlineRecaptcha
						downlineCaptchaVerification={downlineCaptchaVerificationState}
						setDownlineCaptchaVerification={setDownlineCaptchaVerificationState}
					/>

					{/* DOWNLINE SUBMIT USER */}
					<DownlineSubmitButton />
				</form>
			</div>
		</div>
	);
};

export default DownlineAddPage;
