import React from "react";

import moment from "moment";

import toast from "react-hot-toast";

import NumberFormat from "react-number-format";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const idLocale = require('moment/locale/id')
moment.updateLocale('id', idLocale);

interface VirtaulAccountype {
	data: {
		channel: string;
		displayName: string;
		expired: string;
		fee: number;
		keterangan: string;
		nominal: number;
		totalBayar: number;
		va: string;
	};
}

const VirtaulAccount: React.FC<VirtaulAccountype> = ({ data }): JSX.Element => {
	const {
		displayName,
		expired: expiredAt,
		fee,
		keterangan: description,
		nominal,
		totalBayar: totalPrice,
		va,
	} = data;

	return (
		<div className="w-full">
			<div className="font-inter font-semibold text-lg sm:text-2xl text-gray-800 mt-1 mb-4">
				<span>Detail Top Up</span>
			</div>
			<div
				className="copy cursor-pointer mb-10"
				data-clipboard-text={totalPrice}
				onClick={(): void => {
					toast.success(`Nominal Berhasil Di Salin`, {
						style: {
							fontWeight: "bolder",
						},
					});
				}}>
				<NumberFormat
					value={totalPrice}
					className="font-inter font-extrabold text-lg lg:text-2xl 2xl:text-3xl text-gray-800 cursor-pointer"
					displayType={"text"}
					thousandSeparator={"."}
					decimalSeparator={","}
					prefix={"Rp "}
				/>
				<div>
					<span className="text-xs text-gray-500 font-dmsans tracking-wider">
						KETUK UNTUK MENYALIN NOMINAL
					</span>
				</div>
			</div>
			<div className="p-3 bg-white shadow-lg rounded-md">
				{/* TOP UP INFORMATION */}
				<div className="flex justify-between p-2">
					<div>
						<span className="text-sm lg:text-lg font-medium font-dmsans">Informasi Top Up</span>
					</div>
					<div>
						<FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
					</div>
				</div>
				<hr />

				{/* VA CODE */}
				<div className="flex flex-col px-2 py-1 lg:p-2 text-left">
					<div>
						<span className="text-xs sm:text-base text-gray-500 font-dmsans">Kode Pembayaran</span>
					</div>
					<div className="flex items-center">
						<div className="text-left">
							<span className="text-xs sm:text-base font-medium font-dmsans">{va}</span>
						</div>
						<button
							className="copy p-1.5 focus:outline-none inline-flex items-center gap-2"
							data-clipboard-text={va}
							onClick={(): void => {
								toast.success(`Kode Pembayaran Berhasil Di Salin`, {
									style: {
										fontWeight: "bolder",
									},
								});
							}}>
							<svg
								fill="gray"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="20"
								height="20">
								<path fill="none" d="M0 0h24v24H0z" />
								<path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
							</svg>
						</button>
					</div>
				</div>

				{/* VA NAME */}
				<div className="flex flex-col px-2 py-1 lg:p-2 text-left">
					<div>
						<span className="text-xs sm:text-base text-gray-500 font-dmsans">Nama Pengguna</span>
					</div>
					<div className="text-left">
						<span className="text-xs sm:text-base font-medium font-dmsans">{displayName}</span>
					</div>
				</div>

				{/* TOP UP NOMINAL */}
				<div className="flex flex-col px-2 py-1 lg:p-2 text-left">
					<div>
						<span className="text-xs sm:text-base text-gray-500 font-dmsans">Nominal Top Up</span>
					</div>
					<div className="flex gap-2">
						<NumberFormat
							value={nominal}
							className="text-xs sm:text-base font-medium font-dmsans"
							displayType={"text"}
							thousandSeparator={"."}
							decimalSeparator={","}
							prefix={"Rp "}
						/>
					</div>
				</div>

				{/* ADMIN PRICE */}
				<div className="flex flex-col px-2 py-1 lg:p-2 text-left">
					<div>
						<span className="text-xs sm:text-base text-gray-500 font-dmsans">Biaya Admin</span>
					</div>
					<div className="flex gap-2">
						<NumberFormat
							value={fee}
							className="text-xs sm:text-base font-medium font-dmsans"
							displayType={"text"}
							thousandSeparator={"."}
							decimalSeparator={","}
							prefix={"Rp "}
						/>
					</div>
				</div>

				{/* TOTAL PRICE */}
				<div className="flex flex-col px-2 py-1 lg:p-2 text-left">
					<div>
						<span className="text-xs sm:text-base text-gray-500 font-dmsans">Total Bayar</span>
					</div>
					<div className="flex gap-2">
						<NumberFormat
							value={totalPrice}
							className="text-xs sm:text-base font-medium font-dmsans"
							displayType={"text"}
							thousandSeparator={"."}
							decimalSeparator={","}
							prefix={"Rp "}
						/>
					</div>
				</div>

				{/* VA DESCRIPTION */}
				<div className="flex flex-col px-2 py-1 lg:p-2 text-left">
					<div>
						<span className="text-xs sm:text-base text-gray-500 font-dmsans">Deskripsi</span>
					</div>
					<div className="text-left">
						<span className="text-xs sm:text-base font-medium font-dmsans">{description}</span>
					</div>
				</div>

				{/* EXPIRED TIME */}
				<div className="flex flex-col px-2 py-1 lg:p-2 text-left">
					<div>
						<span className="text-xs sm:text-base text-gray-500 font-dmsans">Waktu Kadaluarsa</span>
					</div>
					<div className="text-left">
						<span className="text-xs sm:text-base font-medium font-dmsans">{moment.utc(expiredAt).format("DD MMMM YYYY HH:mm:ss")} WIB</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VirtaulAccount;
