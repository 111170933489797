import React from "react";

interface InfoListProps {
	keyId: string | number;
}

const LoadingInfoCardList: React.FC<InfoListProps> = (): JSX.Element => {
	return (
		<div className="animate-pulse bg-gray-300 rounded-md lg:rounded-lg">
			<div className="h-80 flex items-center gap-2 mr-2.5"></div>
		</div>
	);
};

export default LoadingInfoCardList;
