import React, { Fragment, useCallback, useEffect, useState } from "react";

import { axiosAdaptor } from "../../utils/axios-adaptor.util";

import { useHistory } from "react-router-dom";

import { useRecoilValue, useSetRecoilState } from "recoil";
import themeAppStore from "../../store/theme-app.store";
import favoriteNumberStore from "../../store/favorite-number.store";

import toast from "react-hot-toast";

import loadingCardLength from "./loading/loadingCardLength";

import LandscapeLoadingCardMenu from "./loading/HomeMenu";

import "./HomeMenu.css";

const HomeMenu: React.FC = (): JSX.Element => {
	const setFavoriteNumberState = useSetRecoilState(favoriteNumberStore);

	const resetFavoriteNumber = useCallback(function () {
		setFavoriteNumberState(0);
	}, [setFavoriteNumberState])

	interface HomeMenuSchemaType {
		active: boolean;
		bebas_nominal: boolean;
		category_id?: string;
		description: string;
		icon: string;
		jenis: number;
		kode_merchant: string;
		kode_produk: string;
		located: number;
		menu_input: string;
		name: string;
		order_number: string;
		parent_id?: string;
		type: number;
		_id: string;
	}

	const homeMenuSchema = [
		{
			active: false,
			bebas_nominal: false,
			category_id: "",
			description: "",
			icon: "",
			jenis: 0,
			kode_merchant: "",
			kode_produk: "",
			located: 0,
			menu_input: "",
			name: "",
			order_number: "",
			parent_id: "",
			type: 0,
			_id: "",
		},
	];

	const [homeMenuState, setHomeMenuState] = useState<Array<HomeMenuSchemaType>>(homeMenuSchema);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

	function getHomeMenu(): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			setIsLoadingState(true);
			axiosAdaptor({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/menu/1`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then(async (result: any) => {
				// console.log("[HOME MENU]:", result);

				// const cacheStore = await cache.store

				// console.log('Cache store:', cacheStore)

				if (result.status === 200) {
					setIsLoadingState(false);
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		resetFavoriteNumber();
		getHomeMenu().then((result: any): void => {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) setHomeMenuState(data);
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	}, [resetFavoriteNumber]);

	const history: any = useHistory();
	function handleMenuRedirect(icon: string, jenis: number, productCode: string, inputMenu: string, name: string, type: number, id: string, categoryId?: string,) {
		localStorage.setItem("menu_icon", icon);

		let productType: string = '';
		let pathName: string = '';

		if (jenis === 1) {
			productType = 'prepaid';
			pathName = '/dashboard/trx/pulsa';
		}
		if (jenis === 2) {
			if (categoryId && type === 1) {
				productType = 'prepaid';
				pathName = '/dashboard/trx/detail-denom';
			}
			if (productCode && type === 2) {
				productType = 'postpaid';
				pathName = '/dashboard/trx/detail-denom-postpaid';
			}
			if (!categoryId) {
				productType = '/dashboard/trx/list-submenu';
				pathName = '/dashboard/trx/list-submenu';
			}
		}
		if (jenis === 4) pathName = '/dashboard/trx/list-gridmenu';

		history.push({
			pathname: pathName,
			state: { id, name, inputMenu, productCode, productType, categoryId },
		});
	};

	const ScrollToTopOnMount = (): null => {
		useEffect((): void => {
			window.scrollTo(0, 0);
		}, []);

		return null;
	};

	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		menu: 'bg-white text-gray-400 lg:text-gray-800',
		helpText: 'text-gray-500',
		textDescription: 'text-gray-500'
	}

	if(themeState === 'dark') {
		defaultTheme['menu'] = 'bg-dark-menu lg:text-gray-200';
		defaultTheme['helpText'] = 'text-gray-400';
		defaultTheme['textDescription'] = 'text-gray-400';
	}

	return (
		<div className="w-full mb-8 lg:mb-12">
			<ScrollToTopOnMount />

			<div className="flex items-center sm:mt-6 lg:mt-0">
				<div className="flex flex-col lg:gap-1.5 mb-6 lg:mb-8">
					{/* MENU FEATURE TITLE */}
					<div title="Judul Menu">
						<span className="text-base sm:text-lg lg:text-xl font-medium lg:font-semibold tracking-widest">Menu Utama</span>
					</div>

					{/* MENU FEATURE HELP */}
					<div title="Deskripsi Menu">
						<span className={`${defaultTheme.helpText} text-xs sm:text-base lg:text-[0.805rem] xl:text-base`}>{process.env.REACT_APP_DASHBOARD_MAIN_MENU_DESCRIPTION}</span>
					</div>
				</div>
			</div>
			<div>
				<div className="grid gap-6 grid-cols-3 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3">
					{isLoadingState ? (
						<Fragment>
							{loadingCardLength.map((loading, index) => (
								<Fragment key={index}>
									<LandscapeLoadingCardMenu />
								</Fragment>
							))}
						</Fragment>
					) : (
						homeMenuState.map(
							({
								category_id: categoryId,
								description,
								icon,
								jenis,
								kode_produk: productCode,
								menu_input: menuInput,
								name,
								type,
								_id: id,
							}: {
								category_id?: string;
								description: string;
								icon: string;
								jenis: number;
								kode_produk: string;
								menu_input: string;
								name: string;
								type: number;
								_id: string;
							}, index): JSX.Element => (
								<Fragment key={index}>
									<div
										onClick={(): void => handleMenuRedirect(icon, jenis, productCode, menuInput, name, type, id, categoryId)}
										className={`${defaultTheme.menu} p-2 sm:p-4 shadow hover:shadow-xl duration-500 lg:ease-out pt-5 pb-3 lg:py-0 lg:px-2 flex items-center flex-col lg:flex-row gap-1 lg:gap-0 rounded-md lg:rounded-lg cursor-pointer noselect`}
										title={name}>
										<div className="lg:p-3 sm:mb-3 md:mb-0 rounded-sm rounded-r-none">
											<img src={icon} alt="icon" className="w-10 sm:w-14 lg:w-14" />
										</div>
										<div className="w-full pt-0 lg:pb-3 lg:py-3 text-center lg:text-left">
											<div>
												<span className="text-2xs sm:text-sm lg:text-sm font-medium tracking-wide">{name}</span>
											</div>
											<div className="hidden lg:block">
												<span className={`${defaultTheme.textDescription} text-2xs lg:text-[0.750rem] 2xl:text-[0.784rem] font-light tracking-wide`}>{description}</span>
											</div>
										</div>
									</div>
								</Fragment>
							)
						)
					)}
				</div>
			</div>
		</div>
	);
};

export default HomeMenu;
