import React, { Fragment } from "react";

import toast from "react-hot-toast";

import NumberFormat from "react-number-format";

interface BankEwalletype {
	data: {
		bank: Array<{
			is_gangguan: boolean;
			is_manual: boolean;
			nama_bank: string;
			nama_rekening: string;
			no_rekening: number;
			url_image: string;
			_id: string;
		}>;
		expired_at: string;
		nominal: number;
		nominal_transfer: number;
	};
}

const BankEwallet: React.FC<BankEwalletype> = ({ data }): JSX.Element => {
	const { bank, nominal_transfer: nominalTransfer } = data;

	return (
		<div>
			<div className="font-inter font-semibold text-lg sm:text-2xl text-gray-800 mt-1 mb-4">
				<span>Daftar Rekening</span>
			</div>
			<div
				className="copy cursor-pointer mb-10 w-auto"
				data-clipboard-text={nominalTransfer}
				onClick={(): void => {
					toast.success(`Nominal Berhasil Di Salin`, {
						style: {
							fontWeight: "bolder",
						},
					});
				}}>
				<NumberFormat
					value={nominalTransfer}
					className="font-inter font-extrabold text-lg sm:text-2xl 2xl:text-3xl text-gray-800 cursor-pointer"
					displayType={"text"}
					thousandSeparator={"."}
					decimalSeparator={","}
					prefix={"Rp "}
				/>
				<div>
					<span className="text-xs text-gray-500 font-dmsans tracking-wider">
						KETUK UNTUK MENYALIN NOMINAL
					</span>
				</div>
			</div>
			{bank.map(
				({
					is_gangguan: isProblem,
					nama_bank: bankName,
					nama_rekening: rekeningName,
					no_rekening: rekeningNumber,
				}, index): JSX.Element => (
					<Fragment key={index}>
						<div
							className="copy w-full flex items-center px-5 py-3 lg:py-4 lg:px-6 mb-4 content-list-shadow cursor-pointer lg:transition duration-500 lg:ease-out noselect focus:outline-none rounded-md lg:rounded-lg"
							data-clipboard-text={rekeningNumber}
							onClick={(): void => {
								toast.success(`Nomor Rekening Berhasil Di Salin`, {
									style: {
										fontWeight: "bolder",
									},
								});
							}}>
							<div className="w-full flex justify-between items-center gap-3 lg:gap-4">
								<div className="flex flex-1 flex-col">
									<div className="text-left">
										<span className="mb-1 text-xs sm:text-base font-medium font-dmsans tracking-wide">
											{bankName}
										</span>
									</div>
									<div className="text-left">
										<span className="text-xs sm:text-sm font-dmsans tracking-wide">
											{rekeningName}
										</span>
									</div>
								</div>
								<div className="flex flex-1 flex-col">
									<div className="text-right">
										<span className="mb-1 text-xs sm:text-base font-medium font-inter tracking-wide">
											{rekeningNumber}
										</span>
									</div>
									<div className="text-right">
										{isProblem ? (
											<span className="text-xs sm:text-sm text-red-500 font-medium font-dmsans tracking-wide ">
												GANGGUAN
											</span>
										) : (
											<span className="text-xs sm:text-sm text-green-500 font-medium font-dmsans tracking-wide ">
												TERSEDIA
											</span>
										)}
									</div>
								</div>
							</div>
						</div>
					</Fragment>
				)
			)}
		</div>
	);
};

export default BankEwallet;
