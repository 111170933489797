import React, { Suspense } from "react";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { RecoilRoot } from "recoil";

import App from "./App";

import Home from "./views/components/loading/Home";

import "./index.css";

ReactDOM.render(
	<React.StrictMode>
		<RecoilRoot>
			<Suspense fallback={<Home />}>
				<App />
			</Suspense>
		</RecoilRoot>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// IF YOU WANT TO START MEASURING PERFORMANCE IN YOUR APP, PASS A FUNCTION
// TO LOG RESULTS (FOR EXAMPLE: REPORTWEBVITALS(CONSOLE.LOG))
// OR SEND TO AN ANALYTICS ENDPOINT. LEARN MORE: HTTPS://BIT.LY/CRA-VITALS
reportWebVitals();
