import React, { Fragment } from "react";

import Routes from "./routes";
import { Toaster } from "react-hot-toast";

import { useRecoilValue } from "recoil";
import themeAppStore from "./store/theme-app.store";

import ClipboardJS from "clipboard";

import SettingInterface from "./views/components/SettingInterface";

import "animate.css";
import "./App.css";

const App: React.FC = (): JSX.Element => {
	new ClipboardJS(".copy");

	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme: string = themeState === "dark" ? "bg-gray-800 text-white" : "bg-white lg:bg-gray-100 text-black";

	return (
		<Fragment>
			<div className={`${defaultTheme} antialiased noselect font-inter`}>
				<SettingInterface />
				<Toaster position="top-center" />
				<Routes />
			</div>
		</Fragment>
	);
};

export default App;
