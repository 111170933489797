import React, { Fragment } from "react";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MutationLoadingDetails: React.FC = (props): JSX.Element => {
	return (
		<Fragment>
			<div className="mb-3">
				{/* TRX INFORMATION */}
				<div className="flex justify-center flex-col items-center mb-8">
					{/* MUTATION LENGTH */}
					<p className="h-7 w-40 mb-4 animate-pulse bg-gray-300 rounded" />

					{/* PAYMENT TYPE */}
					<p className="h-6 w-20 animate-pulse bg-gray-300 rounded" />
				</div>
				<div className="pt-1 px-3 pb-4 bg-white shadow-lg rounded-lg">
					{/* MUTATION INFO */}
					<div className="flex justify-between p-2">
						<div>
							<span className="text-sm sm:text-lg font-medium font-dmsans">Informasi Mutasi</span>
						</div>
						<div>
							<FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
						</div>
					</div>
					<hr />

					{/* MUATION ID */}
					<div className="flex flex-col gap-1 px-2 lg:pt-2 pb-2.5 lg:pb-2 text-left">
						<div>
							<span className="text-xs sm:text-base text-gray-500 font-dmsans">ID Mutasi</span>
						</div>
						<div>
							<p className="h-4 w-40 lg:w-60 animate-pulse bg-gray-300 rounded"></p>
						</div>
					</div>

					{/* MUTATION TIME */}
					<div className="flex flex-col gap-1 px-2 lg:pt-3 pb-2.5 lg:pb-2 text-left">
						<div>
							<span className="text-xs sm:text-base text-gray-500 font-dmsans">Waktu</span>
						</div>
						<div>
							<p className="h-4 w-40 animate-pulse bg-gray-300 rounded"></p>
						</div>
					</div>

					{/* FIRST BALLANCE */}
					<div className="flex flex-col gap-1 px-2 lg:pt-3 pb-2.5 lg:pb-2 text-left">
						<div>
							<span className="text-xs sm:text-base text-gray-500 font-dmsans">Saldo Awal</span>
						</div>
						<div>
							<p className="h-4 w-40 lg:w-32 animate-pulse bg-gray-300 rounded"></p>
						</div>
					</div>

					{/* LAST BALLANCE */}
					<div className="flex flex-col gap-1 px-2 lg:pt-3 pb-2.5 lg:pb-2 text-left">
						<div>
							<span className="text-xs sm:text-base text-gray-500 font-dmsans">Saldo Akhir</span>
						</div>
						<div>
							<p className="h-4 w-40 lg:w-32 animate-pulse bg-gray-300 rounded"></p>
						</div>
					</div>

					{/* DESCRIPTION */}
					<div className="flex flex-col gap-1 px-2 lg:pt-3 pb-2.5 lg:pb-2 text-left">
						<div>
							<span className="text-xs sm:text-base text-gray-500 font-dmsans">Keterangan</span>
						</div>
						<div>
							<p className="h-4 w-40 lg:w-48 animate-pulse bg-gray-300 rounded"></p>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default MutationLoadingDetails;
