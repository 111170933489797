import React, { Fragment, useEffect, useState } from "react";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../store/theme-app.store";

import { Detector } from "react-detect-offline";

const ToastOffline: React.FC = (props): JSX.Element => {
	const [toastHide, setToastHide] = useState<number>(4);

	const matchMedia: any = window.matchMedia("(max-width: 1024px)");
	useEffect((): void => {
		const mediaQuery = (matchMedia: any): void => {
			if (matchMedia.matches) {
				setTimeout((): void => {
					if (toastHide >= 1) setToastHide(toastHide - 1);
				}, 1000);
			}
		};

		mediaQuery(matchMedia);
		matchMedia.addListener(mediaQuery);
	}, [toastHide, matchMedia]);

	const themeState = useRecoilValue<string | null>(themeAppStore);

	const defaultTheme = {
		toastBgOfflineLoading: 'bg-yellow-400',
		toastOffline: 'bg-white text-gray-800'
	}
	
	if(themeState === 'dark') {
		defaultTheme['toastBgOfflineLoading'] = 'bg-gray-700';
		defaultTheme['toastOffline'] = 'bg-gray-800 text-white';
	}

	return (
		<Fragment>
			<Detector
				render={({ online }) =>
					!online ? (
						<div className="flex justify-center">
							<div
								className={`animate__animated animate__fadeIn ${defaultTheme.toastOffline} flex w-full max-w-sm fixed z-50 top-0 mx-auto overflow-hidden rounded-lg shadow-md mt-3 mr-3`}>
								<div
									className={`${defaultTheme.toastBgOfflineLoading} flex items-center justify-center w-12`}>
									<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} /><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" /></svg>
								</div>
								<div className="px-4 py-2 -mx-3">
									<div className="mx-3">
										<span className="font-semibold">Koneksi Terputus!</span>
										<p className="text-sm">Silahkan cek koneksi internet</p>
									</div>
								</div>
							</div>
						</div>
					) : (
						<></>
					)
				}
			/>
		</Fragment>
	);
};

export default ToastOffline;
