import axios from "axios";
import { setupCache } from 'axios-cache-adapter';

// Create `axios-cache-adapter` instance
const cache: any = setupCache({
  maxAge: 60 * 60 * 1000
})

// Create `axios` instance passing the newly created `cache.adapter`
const axiosAdaptor = axios.create({
  adapter: cache.adapter
});

export { cache, axiosAdaptor }