import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";

import { useSetRecoilState } from "recoil";
import favoriteNumberStore from "../../store/favorite-number.store";

import toast from "react-hot-toast";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Swal from "sweetalert2";

interface ModalFavoriteNumberProps {
	productType: string;
}

const ModalFavoriteNumber: React.FC<ModalFavoriteNumberProps> = ({
	productType,
}): JSX.Element => {
	const setFavoriteNumberStateManagement = useSetRecoilState(favoriteNumberStore);

	interface FavoriteNumberSchemaType {
		created_at: string;
		kode_merchant: string;
		nama: string;
		tujuan: number;
		type: string;
		user_id?: string;
		_id: string;
	}

	const favoriteNumberSchema = [
		{
			created_at: "",
			kode_merchant: "",
			nama: "",
			tujuan: 0,
			type: "",
			user_id: "",
			_id: "",
		},
	];

	// Digunakan untuk data pencarian
	const [tempFavoriteNumberState, setTempFavoriteNumberState] = useState<Array<FavoriteNumberSchemaType>>(favoriteNumberSchema);

	const [favoriteNumberState, setFavoriteNumberState] = useState<Array<FavoriteNumberSchemaType>>(favoriteNumberSchema);

	const [dataSearchState, setDataSearchState] = useState("");

	const [isLoadingState, setIsLoadingState] = useState(false);

	function getFavoriteNumber(): Promise<void> {
		return new Promise((resolve, reject): void => {
			setIsLoadingState(true);
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/favorite/get?type=${productType}`,
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log(`[FAVORITE NUMBER]`, result);

				if (result.status === 200) {
					setIsLoadingState(false);
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		getFavoriteNumber().then((result: any): void => {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) {
				setFavoriteNumberState(data);
				setTempFavoriteNumberState(data);
			}
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	}, [productType]);

	useEffect((): void => {
		const search: Array<FavoriteNumberSchemaType> = tempFavoriteNumberState.filter(({ nama: name }) => name.toLowerCase().includes(dataSearchState.toLowerCase()));
		
		setFavoriteNumberState(search);
	}, [dataSearchState]);

	function handleChangeSearchFavoriteNumber(e: any) {
		const searchInput = e.target.value;
		if (searchInput.length > 0) {
			setDataSearchState(searchInput); return;
		}

		setFavoriteNumberState(tempFavoriteNumberState);
	}

	function handleClickSelectFavoriteNumber(destination: number) {
		setFavoriteNumberStateManagement(destination);
		Swal.close();
	}

	return (
		<Fragment>
			<div className="flex search-input-shadow mt-2 mb-6 rounded-lg">
				{/* SEARCH ICON */}
				<div className="flex justify-center items-center pl-4 pr-4 py-2">
					<FontAwesomeIcon icon={faSearch} className="font-medium text-xl sm:text-2xl text-gray-800"></FontAwesomeIcon>
				</div>
				{/* INPUT SEARCH */}
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeSearchFavoriteNumber(e)}
					className="w-full bg-opacity-0 text-gray-500 pr-4 py-2.5 capitalize focus:outline-none rounded-lg"
					type="text"
					placeholder="Cari Nomor Favorit"
				/>
			</div>
			
			{isLoadingState ? (
				<div className="flex justify-center">
					<svg
						className="animate-spin -ml-1 mr-3 h-8 w-8 mb-6"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24">
						<circle className="opacity-25" cx={12} cy={12} r={10} stroke="#000000" strokeWidth={4} />
						<path className="opacity-75" fill="#000000" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
					</svg>
				</div>
			) : favoriteNumberState.length > 0 ? (
				<Fragment>
					{favoriteNumberState.map(({ nama: name, tujuan: destination, _id: id }, index): JSX.Element => (
						<Fragment key={index}>
							<label
								htmlFor="my-modal-2"
								onClick={(): void => handleClickSelectFavoriteNumber(destination)}
								key={id}
								className="w-full flex items-center px-5 py-3 lg:py-4 lg:px-6 mb-4 content-list-shadow cursor-pointer lg:transition duration-500 lg:ease-out noselect focus:outline-none rounded-lg">
								<div className="w-full flex justify-between items-center gap-3 lg:gap-4">
									<div className="flex items-center lg:flex-col lg:items-start lg:gap-3 xl:flex-row xl:items-center xl:gap-0">
										<div className="flex flex-col w-44 sm:w-auto lg:w-96 xl:w-auto text-left">
											<span className="mb-1 text-xs lg:text-base text-gray-800 font-medium tracking-wide">{name}</span>
											<span className="text-xs lg:text-sm text-gray-800 font-lato tracking-wide">{destination}</span>
										</div>
									</div>
								</div>
							</label>
						</Fragment>
					))}
				</Fragment>
			) : (
				<Fragment>
					<div className="text-center">
						<span className="text-red-500 text-xs lg:text-base font-lato tracking-wide">
							Nomor Favorit Tidak Di Temukan :(
						</span>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default ModalFavoriteNumber;
