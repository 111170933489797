import React, { Fragment, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import axios from "axios";
import { axiosAdaptor } from "../../../utils/axios-adaptor.util";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../../store/theme-app.store";

import NumberFormat from "react-number-format";

import toast from "react-hot-toast";

import loadingCardLength from "../../components/loading/loadingCardLength";
import { BoxLoadingCard } from "../../components/loading/LoadingCard";

import Header from "../../components/Header";

import ContentGrid from "../../components/ContentGrid";

import EmptyIcon from "../../components/icons/EmptyIcon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/free-solid-svg-icons";

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

const RewardGridPage: React.FC = (props): JSX.Element => {
	interface RewardGridSchemaType {
		created_at: string;
		description: string;
		kode_merchant: string;
		poin: number;
		poin_promo: string | number;
		stock: number;
		title: string;
		url_images: string;
		_id: string;
	}

	const rewardGridSchema = [
		{
			created_at: "",
			description: "",
			kode_merchant: "",
			poin: 0,
			poin_promo: "",
			stock: 0,
			title: "",
			url_images: "",
			_id: "",
		},
	];

	const [rewardGridState, setRewardGridState] = useState<Array<RewardGridSchemaType>>(rewardGridSchema);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

	function getRewardGrid(setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			setLoading(true);
			axiosAdaptor({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/reward/list`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then(async (result: any) => {
				// console.log('[REWARD GRID]', result)

				// const cacheStore = await cache.store

				// console.log('Cache store:', cacheStore)

				if (result.status === 200) {
					setLoading(false);
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		getRewardGrid(setIsLoadingState).then((result: any): void => {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) setRewardGridState(data);
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	}, []);

	function rewardExchange(id: string): Promise<unknown> {
		return new Promise((resolve, reject) => {
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/reward/tukar`,
				method: "POST",
				data: JSON.stringify({ id }),
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result) => {
				// console.log('[REWARD EXCHANGE]', result)

				if (result.status === 200) {
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error) => console.log("STATUS CODE", error));
		});
	};

	const ScrollToTopOnMount = (): null => {
		useEffect((): void => {
			window.scrollTo(0, 0);
		}, []);

		return null;
	};

	const themeState = useRecoilValue<string | null>(themeAppStore);

	return (
		<div>
			<Header title="Daftar Reward" option>
				<Link to="/dashboard/profile/history/reward">
					<button className="tracking-wide rounded-sm focus:outline-none">
						<FontAwesomeIcon icon={faHistory} className="font-black text-xl lg:text-2xl" style={{ color: themeState === "dark" ? "#FFFFFF" : "#000" }}></FontAwesomeIcon>
					</button>
				</Link>
			</Header>

			<ScrollToTopOnMount />

			{isLoadingState ? (
				<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 lg:gap-6">
					{loadingCardLength.slice(0, 12).map((loading, index) => (
						<Fragment key={index}>
							<BoxLoadingCard heightTw="h-60 sm:h-64 lg:h-64 2xl:h-96" />
						</Fragment>
					))}
				</div>
			) : (
				<Fragment>
					{!rewardGridState.length ? (
						<div className="mt-24 lg:mt-20 2xl:mt-40">
							<EmptyIcon title="Belum Ada Reward Yang Tersedia" />
						</div>
					) : (
						<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 lg:gap-6">
							{rewardGridState.map(({ title, description, poin: point, url_images: urlImages, _id: id, }, index) => (
								<Fragment key={index}>
									<ContentGrid
										onClick={(): void => {
											const getRewardDetails = async (): Promise<void> => {
												const { value: swalRewardConfirm } = await Swal.fire({
													title: "Detail Reward",
													padding: "1rem 0rem",
													showConfirmButton: true,
													confirmButtonText: "Tukarkan",
													showCancelButton: true,
													cancelButtonText: "Kembali",
													heightAuto: false,
													customClass: {
														title: "font-inter font-semibold text-2xl text-gray-800",
														cancelButton: "text-sm sm:text-base font-inter",
														confirmButton: "text-sm sm:text-base font-inter",
													},
													html: (
														<Fragment>
															<div className="p-3 bg-white shadow-lg rounded-lg mb-3">
																<div className="flex flex-col px-2 py-1 lg:p-2 text-left">
																	<div className="text-left">
																		<span className="text-sm sm:text-lg font-medium font-dmsans">Tukar Reward</span>
																	</div>
																</div>
																<hr />

																{/* REWARD NAME */}
																<div className="flex flex-col px-2 py-1 lg:p-2 text-left">
																	<div>
																		<span className="text-xs sm:text-base text-gray-500 font-dmsans">Nama</span>
																	</div>
																	<div className="text-left">
																		<span className="text-xs sm:text-base font-medium font-dmsans">{title}</span>
																	</div>
																</div>

																{/* REWARD DESCRIPTION */}
																<div className="flex flex-col px-2 py-1 lg:p-2 text-left">
																	<div>
																		<span className="text-xs sm:text-base text-gray-500 font-dmsans">Deskripsi</span>
																	</div>
																	<div className="text-left">
																		<span className="text-xs sm:text-base font-medium font-dmsans">{description}</span>
																	</div>
																</div>

																{/* POIN REQUIREMENT */}
																<div className="flex flex-col px-2 py-1 lg:p-2 text-left">
																	<div>
																		<span className="text-xs sm:text-base text-gray-500 font-dmsans">Poin Dibutuhkan</span>
																	</div>
																	<div className="flex gap-1 text-left">
																		<NumberFormat
																			value={point}
																			className="text-xs sm:text-base font-medium font-dmsans"
																			displayType={"text"}
																			thousandSeparator={"."}
																			decimalSeparator={","}
																		/>
																		<span className="text-xs sm:text-base font-medium font-dmsans">poin</span>
																	</div>
																</div>
															</div>
														</Fragment>
													),
												});

												if (swalRewardConfirm) {
													toast.promise(
														rewardExchange(id),
														{
															loading: "Loading...",
															success: (result: any) => {
																const statusCode: number = result.data.status;
																const message: any = result.data.message;
																if (statusCode === 200) return message;
															},
															error: (e: any): string => e.data.message,
														},
														{
															style: { fontWeight: "bolder" },
														}
													);
												}
											};

											getRewardDetails();
										}}
										optional>
										<div className="bg-white rounded-md lg:rounded-lg">
											<img src={urlImages} alt="img" className={`w-full flex justify-center items-end h-60 sm:h-64 lg:h-64 2xl:h-96 mb-0 lg:mb-0 text-white rounded-md lg:rounded-lg content-grid-shadow hover:shadow-xl lg:gap-4 cursor-pointer lg:transition duration-500 lg:ease-out noselect`} />
										</div>
									</ContentGrid>
								</Fragment>
							)
							)}
						</div>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default RewardGridPage;
