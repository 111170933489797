import React from "react";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../store/theme-app.store";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./SearchProduct.css";

interface SearchProductProps {
	onChange?: any;
}

const SearchProduct: React.FC<SearchProductProps> = ({ onChange }): JSX.Element => {
	const themeState: string | null = useRecoilValue(themeAppStore);

	const defaultTheme = {
		inputWrapper: 'bg-white text-gray-400 lg:text-gray-800',
		input: 'bg-white text-gray-400 lg:text-gray-800'
	}

	if(themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu lg:text-gray-200';
		defaultTheme['input'] = 'bg-dark-menu lg:text-gray-200';
	}

	interface iconThemeType {
		color: string | undefined;
	}

	let iconTheme: iconThemeType = {
		color: process.env.REACT_APP_PRIMARY_COLOR,
	};

	if (themeState === 'dark') {
		iconTheme.color = "#FFFFFF";
	}

	return (
		<div className={`${defaultTheme.inputWrapper} flex shadow rounded-md`}>
			{/* SEARCH ICON */}
			<div className="flex items-center pl-4 pr-4 py-2">
				<FontAwesomeIcon icon={faSearch} className="font-black text-xl" style={iconTheme}></FontAwesomeIcon>
			</div>

			{/* INPUT SEARCH */}
			<input
				onChange={onChange}
				className={`${defaultTheme.input} w-full bg-opacity-0 text-gray-500 capitalize pr-4 py-2.5 rounded-r-sm focus:outline-none`}
				type="text"
				placeholder="Search"
			/>
		</div>
	);
};

export default SearchProduct;
