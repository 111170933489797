import React from "react";

import swal from "sweetalert2";

interface ButtonPurchaseConfirmProps {
	loading: boolean;
	action: () => void;
}

const ButtonPurchaseConfirm: React.FC<ButtonPurchaseConfirmProps> = ({
	loading,
	action,
}) => {
	return (
		<div className="mt-8 mb-4 flex justify-center gap-2">
			{!loading && (
				<button
					onClick={(): void => action()}
					className="swal2-confirm swal2-styled text-sm lg:text-base font-inter">
					Bayar
				</button>
			)}
			<button
				onClick={(): void => swal.close()}
				className="swal2-cancel swal2-styled text-sm lg:text-base font-inter">
				Batal
			</button>
		</div>
	);
};

export default ButtonPurchaseConfirm;
