import axios from "axios";

import { selector } from "recoil";

const getUserInfoStore = selector<UserInfoType>({
	key: "getUserInfo",
	get: async (): Promise<any> => {
		const result: any = await axios({
			url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/info`,
			method: "GET",
			headers: {
				Authorization: localStorage.getItem("user_token"),
			},
		});
		if (result.status === 200) {
			const statusCode: number = result.data.status;
			const data: any = result.data.data;
			if (statusCode === 200) return data;
		}
	},
});

export default getUserInfoStore;
