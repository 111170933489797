import React, { Fragment, useEffect, useState } from "react";

import { axiosAdaptor } from "../../utils/axios-adaptor.util";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../store/theme-app.store";

import toast from "react-hot-toast";

import LoadingInfoCardList from "./loading/InfoList";

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "./InfoList.css";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

const InfoList: React.FC = (props): JSX.Element => {
	interface InfoListSchemaType {
		content: string;
		description: string;
		icon: string;
		judul: string;
		kode_merchant: string;
		type: number;
		_id: string;
	}

	const infoListSchema = [
		{
			content: "",
			description: "",
			icon: "",
			judul: "",
			kode_merchant: "",
			type: 0,
			_id: "",
		},
	];

	const [infoListState, seInfoListState] = useState<Array<InfoListSchemaType>>(infoListSchema);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

	function getInfoList(): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			setIsLoadingState(true);
			axiosAdaptor({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/info/list`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then(async (result: any) => {
				// console.log('[INFO LIST]', result)

				// const cacheStore = await cache.store

				// console.log('Cache store:', cacheStore)

				if (result.status === 200) {
					setIsLoadingState(false);
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		getInfoList().then((result: any) => {
			const statusCode = result.data.status;
			const data = result.data.data;
			if (statusCode === 200) seInfoListState(data);
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	}, []);

	const themeState = useRecoilValue<string | null>(themeAppStore);

	interface themeInfoSchema {
		infoWrapperListTheme: string;
		infoListTheme: string;
		iconTheme: string;
	};

	const themeInfo: themeInfoSchema = {
		infoWrapperListTheme: themeState === "dark" ? "bg-dark-menu text-gray-200" : "bg-white text-gray-800",
		infoListTheme: themeState === "dark" ? "border-gray-700 divide-gray-400" : "divide-gray-200",
		iconTheme: themeState === "dark" ? "lg:text-gray-100" : "lg:text-gray-500",
	};

	return (
		<section className="mt-8">
			<div className="flex flex-col lg:gap-1.5">
				{/* INFO TITLE LIST */}
				<div title="Judul Info">
					<span className="text-sm sm:text-lg lg:text-base font-medium tracking-widest">{process.env.REACT_APP_DASHBOARD_INFO_LIST_TITLE}</span>
				</div>

				{/* INFO DESCRIPTION LIST */}
				<div title="Deskripsi Info">
					<span className="text-xs sm:text-base lg:text-[0.805rem] text-gray-500 font-hind">{process.env.REACT_APP_DASHBOARD_INFO_LIST_DESCRIPTION}</span>
				</div>
			</div>

			{isLoadingState ? (
				<div className={`${themeInfo.infoWrapperListTheme} mt-6 rounded-2xl`}>
					<LoadingInfoCardList keyId={0} />
				</div>
			) : (
				<div
					className={`${themeInfo.infoWrapperListTheme} mt-6 divide-y shadow rounded-lg`}>
					{infoListState.map(({ icon, judul: title, description, content, }: { _id: string; icon: string; judul: string; description: string; content: string; }, index): JSX.Element => (
						<Fragment key={index}>
							<div
								onClick={(): void => {
									Swal.fire({
										padding: "1rem 0rem",
										showConfirmButton: false,
										showCloseButton: true,
										focusConfirm: true,
										heightAuto: false,
										html: (
											<div className="flex justify-start items-center flex-col mt-9">
												<img src={icon} alt="info" />
												<div className="text-center w-full mt-6 mb-3">
													<span className="text-black text-lg lg:text-2xl font-inter font-semibold">{title}</span>
												</div>
												<div className="text-black text-center w-full">
													<span className="text-black text-sm lg:text-base font-dmsans">{content.split('\n').map(str => <p>{str}<div className="mb-2"></div></p>)}</span>
												</div>
											</div>
										),
									});
								}}
								className={`${themeInfo.infoListTheme} px-2 py-3 sm:px-4 sm:py-4 lg:py-3 flex justify-between items-center cursor-pointer`}
								title={title}>
								<div className="flex items-center gap-3 sm:gap-5 mr-2.5">
									<img className="w-14 h-14 sm:w-28 sm:h-28 lg:h-16 xl:w-16 xl:h-16" src={icon} alt="icon" />
									<div>
										<div className="w-[15rem] sm:w-[32rem] lg:w-40 2xl:w-[13rem] truncate">
											<span className="text-xs sm:text-base lg:text-sm font-medium mb-1">{title}</span>
										</div>
										<div className="w-[15rem] sm:w-[32rem] lg:w-40 2xl:w-[13rem] truncate">
											<span className="text-2xs sm:text-sm lg:text-xs">{description}</span>
										</div>
									</div>
								</div>
								<i className={`${themeInfo.iconTheme} fas fa-chevron-right font-black text-base`}></i>
							</div>
						</Fragment>
					)
					)}
				</div>
			)}
		</section>
	);
};

export default InfoList;
